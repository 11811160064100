import React from "react";
import { StyleSheet, View, ScrollView, KeyboardAvoidingView } from "react-native";
import { Button, Text, Toggle } from "@ui-kitten/components";
import { certifiedMembership, skillCertificate, tradieBusinessNameSchema } from "../../../utils/schemas";
import LoadingIndicator from "../../Misc/LoadingIndicator";

import { firestoreUtils, getAppUtils, storageUtils, updateUserDetails } from "../../Firebase/firebase";

import { difference, intersectionWith, join, remove } from "lodash";
import BaseInput from "../../Inputs/BaseInput";

const ToggleSimpleUsageShowcase = ({ valueForChecked = false, functionToRun = () => {} }) => {
  const [checked, setChecked] = React.useState(valueForChecked);

  React.useEffect(() => {
    setChecked(valueForChecked);
  }, [valueForChecked]);

  return <Toggle checked={checked} onChange={functionToRun} />;
};

const EditSkills = ({ userDetails, setVisible }) => {
  const [processing, setProcessing] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [taskCategories, setTaskCategories] = React.useState([]);

  const [otherTradieSkills, setOtherTradieSkills] = React.useState("");
  const [showOtherSkillsInput, setShowOtherSkillsInput] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const taskCategoriesDoc = await firestoreUtils
        .firestore()
        .collection(`task_categories`)
        .where(`newCategory`, "!=", false)
        .orderBy(`newCategory`, "desc")
        .get();

      const taskCategoriesData = [];

      taskCategoriesDoc.forEach((doc) => {
        taskCategoriesData.push({ name: doc.data().name, index: doc.data().index, id: doc.data().id });
      });

      setCategories(taskCategoriesData);
    })();
  }, []);

  React.useEffect(() => {
    const skills = userDetails.tradieSkills || [];
    const categoryNames = categories.map((category) => category.name);
    const otherSkills = join(difference(skills, categoryNames));

    const formattedCategories = categories.map((category) => {
      if (category.index === 16 && otherSkills) {
        setShowOtherSkillsInput(true);
        return { ...category, checked: true };
      }
      return skills.includes(category.name) ? { ...category, checked: true } : { ...category, checked: false };
    });

    setTaskCategories(formattedCategories);
    setOtherTradieSkills(otherSkills);
  }, [categories, userDetails.tradieSkills]);

  const onPressEditSkills = async (taskCategories, otherTradieSkills) => {
    try {
      setProcessing(true);
      const categoryNames = taskCategories.filter((category) => category.checked && category.name).map((category) => category.name);
      const otherSkills = categoryNames.includes("Other")
        ? otherTradieSkills
            .split(",")
            .map((skill) => skill.trim())
            .filter((skill) => Boolean(skill))
        : []; // to discard when user deselects 'Other'

      const categoriesSelected = remove(categoryNames, (n) => n !== "Other"); //lodash method to remove Other from array when other skills are entered
      const tradieSkills = [...categoriesSelected, ...otherSkills];

      await updateUserDetails(userDetails?.userID, {
        tradieSkills,
        noOfTradieSkills: tradieSkills.length,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
      setVisible(false);
    }
  };

  const handleCheckboxChange = (index) => {
    const checkboxData = [...taskCategories];
    const newData = checkboxData.find((data) => data.index === index);
    newData.checked = !newData?.checked;

    const position = checkboxData.indexOf(newData);

    if (~position) {
      checkboxData[position] = newData;
    }
    if (index === 16) setShowOtherSkillsInput((status) => !status); // for Other

    setTaskCategories(checkboxData);
  };

  return (
    <KeyboardAvoidingView contentContainerStyle={[styles.container]} keyboardShouldPersistTaps="always">
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false} keyboardShouldPersistTaps="always">
        <Text category="h5" style={{ textAlign: "center", marginBottom: 5, fontWeight: "bold" }}>
          Edit Skills
        </Text>
        {taskCategories.map((cb) => {
          return (
            <View
              key={cb.id}
              style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: 5 }}
            >
              <Text style={{}}>{cb.name}</Text>
              <ToggleSimpleUsageShowcase
                valueForChecked={cb.checked}
                functionToRun={() => {
                  handleCheckboxChange(cb.index);
                }}
              />
            </View>
          );
        })}

        {showOtherSkillsInput && (
          <BaseInput
            maxLength={500}
            labelText="Enter skills, seperated by a comma"
            secLabelText="Max. 500 characters"
            placeholder="Electrical rewinding, canvas painting"
            value={otherTradieSkills}
            onChangeText={(text) => setOtherTradieSkills(text)}
            editable={true}
            multiline={true}
            textAlignVertical="top"
            numberOfLines={10}
            inputStyles={{ height: 100 }}
            inputWrapperStyles={{ borderColor: "gray", borderWidth: 1 }}
            wrapperStyles={{ marginVertical: 5 }}
            returnKeyType="done"
          />
        )}

        <Button
          disabled={processing}
          onPress={() => {
            onPressEditSkills(taskCategories, otherTradieSkills);
          }}
          style={{ marginVertical: 5 }}
          disabled={processing}
          accessoryLeft={processing ? LoadingIndicator : null}
          appearance={processing ? "outline" : "filled"}
        >
          Save Skills
        </Button>

        <Button
          onPress={() => {
            setVisible(false);
          }}
          style={{ marginVertical: 5 }}
          disabled={processing}
          status="warning"
          appearance={"outline"}
        >
          Close
        </Button>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default EditSkills;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
