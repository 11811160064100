import React from "react";
import { StyleSheet, View, ScrollView, Platform } from "react-native";
import GooglePlacesAutocomplete from "./GooglePlacesAutocomplete";

const GOOGLE_PLACES_API_KEY = "AIzaSyApgg0tkZwPMfxFhiuA41G_rKrV3vj3jVY"; // never save your real api key in a snack!
const requestURL = "https://cors-proxy.htmldriven.com/?url=https://maps.googleapis.com/maps/api";
// this in only required for use on the web. See https://git.io/JflFv more for details.

const SuburbSearch = ({ name, setFieldValue, setVisible = () => {} }) => {
  return (
    <GooglePlacesAutocomplete
      keyboardShouldPersistTaps="always"
      placeholder="Search location"
      onPress={(data) => {
        const { description } = data;
        // 'details' is provided when fetchDetails = true
        setFieldValue && setFieldValue(name, description);
        setVisible(false);
      }}
      query={{
        key: GOOGLE_PLACES_API_KEY,
        language: "en",
        region: "GH",
        components: "country:gh",
      }}
      onFail={(error) => console.error(error)}
      requestUrl={{
        url: requestURL,
        useOnPlatform: "web",
      }}
    />
  );
};

export default SuburbSearch;
