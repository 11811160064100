import React from "react";
import { StyleSheet } from "react-native";
import { Divider, Layout, Text, LayoutProps } from "@ui-kitten/components";

export const ProfileSetting = (props) => {
  const { style, hint, value, ...layoutProps } = props;

  return (
    <React.Fragment>
      <Layout level="1" {...layoutProps} style={[styles.container, style]}>
        <Text appearance="hint" category="s1" style={{ textAlign: "center" }}>
          {hint}
        </Text>
        <Text category="s1" style={{ textAlign: "left" }}>
          {value}
        </Text>
      </Layout>
      <Divider />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: "center",
  },
});
