import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Divider, Text, Button, Icon } from "@ui-kitten/components";

import Colors from "../utils/colors";

export default function AppButton({ title, onPress, ...props }) {
  return (
    <Button style={[styles.button]} onPress={onPress} {...props}>
      <Text style={styles.buttonText}>{title}</Text>
    </Button>
  );
}

const styles = StyleSheet.create({
  button: {
    marginVertical: 10,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
  },
  buttonText: {
    color: Colors.white,
    fontSize: 18,
    fontWeight: "600",
    textTransform: "uppercase",
  },
});
