import React from "react";
import { StyleSheet, ActivityIndicator, View } from "react-native";
import { Divider, TopNavigation, TopNavigationAction } from "@ui-kitten/components";
import { SafeAreaLayout } from "../components/safe-area-layout.component.jsx";
import { ArrowIosBackIcon } from "../components/icons";
import TradieAccountDetails from "../components/tradie_account";
import { getCurrentUser, getUserDetails } from "../components/Firebase/firebase.js";
import Constants from "expo-constants";
import { useFocusEffect } from "@react-navigation/native";

const TradieAccountScreen = ({ navigation, route }) => {
  const { userID } = route.params;
  const [userDetails, setUserDetails] = React.useState(null);

  React.useEffect(() => {
    let subscribe;
    (async () => {
      getUserDetails(userID, setUserDetails);
    })();

    return () => {
      setUserDetails(null);
      subscribe && subscribe();
    };
  }, [userID]);

  // useFocusEffect(
  //   React.useCallback(() => {
  //     return () => {
  //       navigation.reset({
  //         index: 0,
  //         routes: [{ name: "MoreScreenMain" }],
  //       });
  //     };
  //   }, [navigation])
  // );

  if (!userDetails) {
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <ActivityIndicator size="small" color={Constants.manifest.extra.colors.secondary.main} />
      </View>
    );
  }

  return (
    <SafeAreaLayout style={styles.container} insets="top">
      <TradieAccountDetails navigation={navigation} userDetails={userDetails} />
    </SafeAreaLayout>
  );
};

export default TradieAccountScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
