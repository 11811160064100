import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import ProfileScreen from "./ProfileScreen";
import { getCurrentUser, getUserDetails } from "../components/Firebase/firebase";
import PaymenHistoryScreen from "./PaymentHistoryScreen";
import NotificationsScreen from "./NotificationsScreen";
import PromoCodeScreen from "./PromoCodeScreen";
import ContactUsScreen from "./ContactUsScreen";
import ManageStoreScreen from "./ManageStoreScreen";
import EditProfileScreen from "./EditProfileScreen";
import NotificationsSettingsScreen from "./NotificationsSettingsScreen";
import TradieAccountScreen from "./TradieAccountScreen";
import ViewImageScreen from "./ViewImageScreen";

import { getFocusedRouteNameFromRoute } from "@react-navigation/native";
import StoreDetails from "../components/shop/StoreDetails";
import ViewProducts from "../components/shop/ViewProducts";
import AddProduct from "../components/shop/AddProduct";
import AddPersonnel from "../components/shop/AddPersonnel";
import EditStore from "../components/shop/EditStore";

const Stack = createStackNavigator();

function getHeaderTitleManageStore(route) {
  // If the focused route is not found, we need to assume it's the initial screen
  // This can happen during if there hasn't been any navigation inside the screen
  // In our case, it's "Feed" as that's the first screen inside the navigator
  const routeName = getFocusedRouteNameFromRoute(route) ?? "Store Details";

  switch (routeName) {
    case "Add New Product":
      return "Add New Product";
    case "View Products":
      return "";
    default:
      return "Other";
  }
}

function ExtraNav(props) {
  const user = getCurrentUser();
  const [userDetails, setUserDetails] = React.useState(null);

  React.useEffect(() => {
    let subscribe;
    (async () => {
      getUserDetails(user?.uid, setUserDetails);
    })();

    return () => {
      subscribe && subscribe();
    };
  }, [user]);

  if (!userDetails) return null;

  return (
    <Stack.Navigator
      screenOptions={({ route }) => ({
        cardStyle: {
          flex: 1,
        },
        cardStyle: {
          flex: 1,
        },
        headerTopInsetEnabled: false,
        headerTitleStyle: {
          fontFamily: "Signika_400Regular",
        },
      })}
    >
      <Stack.Screen
        name="Profile"
        children={(props) => <ProfileScreen userDetails={userDetails} {...props} />}
        options={({ route }) => ({
          headerBackTitleVisible: false,
        })}
      />

      <Stack.Screen
        name="Edit Profile"
        children={(props) => <EditProfileScreen userDetails={userDetails} {...props} />}
        options={({ route }) => ({
          headerBackTitleVisible: false,
        })}
      />
      <Stack.Screen
        name="PaymentHistory"
        children={(props) => <PaymenHistoryScreen user={user} {...props} />}
        options={({ route }) => ({
          title: "Payment History",
          headerBackTitleVisible: false,
        })}
      />
      <Stack.Screen
        name="Notifications"
        children={(props) => <NotificationsScreen user={user} {...props} />}
        options={({ route }) => ({
          headerBackTitleVisible: false,
        })}
      />
      <Stack.Screen
        name="Promos"
        children={(props) => <PromoCodeScreen userDetails={userDetails} {...props} />}
        options={({ route }) => ({
          headerBackTitleVisible: false,
        })}
      />
      <Stack.Screen
        name="Contact Us"
        children={(props) => <ContactUsScreen userDetails={userDetails} {...props} />}
        options={({ route }) => ({
          headerBackTitleVisible: false,
        })}
      />
      <Stack.Screen
        name="Manage Store"
        children={(props) => <ManageStoreScreen userDetails={userDetails} {...props} />}
        options={({ route }) => {
          const routeName = getHeaderTitleManageStore(route);
          return {
            headerShown: routeName === "Other" ? true : false,
            headerBackTitleVisible: false,
            title: route.params?.title || "Manage Store",
          };
        }}
      />

      <Stack.Screen
        name="Store Details"
        children={(props) => <StoreDetails {...props} userDetails={userDetails} />}
        options={({ route }) => {
          return {
            title: route.params.title,
            headerBackTitleVisible: false,
          };
        }}
      />

      <Stack.Screen
        name="View Products"
        children={(props) => {
          return <ViewProducts {...props} userDetails={userDetails} />;
        }}
        options={{ headerBackTitleVisible: false }}
      />

      <Stack.Screen
        name="Edit Store"
        children={(props) => {
          return <EditStore {...props} userDetails={userDetails} />;
        }}
        options={{ headerBackTitleVisible: false }}
      />

      <Stack.Screen
        name="Add New Product"
        children={(props) => {
          return <AddProduct {...props} userDetails={userDetails} />;
        }}
        options={({ route }) => ({ headerBackTitleVisible: false, title: route?.params?.title || "Add New Product" })}
      />

      <Stack.Screen
        name="Add Personnel"
        children={(props) => {
          return <AddPersonnel {...props} userDetails={userDetails} />;
        }}
        options={({ route }) => ({ headerBackTitleVisible: false })}
      />

      <Stack.Screen
        name="Notification Settings"
        children={(props) => <NotificationsSettingsScreen userDetails={userDetails} {...props} />}
        options={({ route }) => ({ headerBackTitleVisible: false })}
      />

      <Stack.Screen
        name="Tradie Account"
        // component={(props) => <TradieAccountScreen {...props} />}
        // component={(props) => <TradieAccountScreen {...props} />}
        children={(props) => <TradieAccountScreen {...props} />}
        options={({ route }) => ({
          headerBackTitleVisible: false,
        })}
      />

      <Stack.Screen
        name="View Image"
        children={(props) => <ViewImageScreen userDetails={userDetails} {...props} />}
        options={({ route }) => ({ headerBackTitleVisible: false })}
      />
    </Stack.Navigator>
  );
}

export default ExtraNav;
