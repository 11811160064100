import React from "react";
import { StyleSheet, View } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";

import CreateStore from "./CreateStore";
import ShowStores from "./ShowStores";
import { getCurrentUser, getUserDetails } from "../components/Firebase/firebase";

const Stack = createStackNavigator();

function DashboardScreen() {
  const user = getCurrentUser();
  const [userDetails, setUserDetails] = React.useState(null);

  React.useEffect(() => {
    let subscribe;
    (async () => {
      getUserDetails(user?.uid, setUserDetails);
    })();

    return () => {
      subscribe && subscribe();
    };
  }, [user]);

  return (
    <Stack.Navigator
      screenOptions={({ route }) => ({
        cardStyle: {
          flex: 1,
        },
        headerTopInsetEnabled: false,
        headerTitleStyle: {
          fontFamily: "Signika_400Regular",
        },
      })}
    >
      <Stack.Screen
        name="Show Stores"
        children={(props) => <ShowStores userDetails={userDetails} {...props} />}
        options={{ title: "Show Invoices", headerShown: false }}
      />

      <Stack.Screen
        name="Create Store"
        children={(props) => <CreateStore userDetails={userDetails} {...props} />}
        options={{ headerBackTitleVisible: false }}
      />
    </Stack.Navigator>
  );
}

export default DashboardScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15,
    height: "100%",
  },
});
