import React from "react";
import { StyleSheet, Text, SafeAreaView } from "react-native";
import { AuthUserProvider } from "../ctx/AuthUserProvider";
import Routes from "./Routes";
import { ApplicationProvider, IconRegistry } from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import * as eva from "@eva-design/eva";
import sandboxTheme from "../utils/sandbox-theme.json";
import prodTheme from "../utils/prod-theme.json";
import { default as mapping } from "../utils/mapping.json";
import NotificationProvider from "../ctx/NotificationCtx";
import { Platform } from "react-native";
import Constants from "expo-constants";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";

/**
 * Wrap all providers here
 */

export default function Providers() {
  const kittenTheme = Constants.manifest.extra.env === "sandbox" ? sandboxTheme : prodTheme;
  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      <ApplicationProvider {...eva} theme={{ ...eva.light, ...kittenTheme }} customMapping={mapping}>
        <NotificationProvider>
          <AuthUserProvider>
            <BottomSheetModalProvider>
              <Routes />
            </BottomSheetModalProvider>
          </AuthUserProvider>
        </NotificationProvider>
      </ApplicationProvider>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
