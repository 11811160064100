import React from "react";
import { useFormikContext } from "formik";

import BaseButton from "./BaseButton";

export default function WithFormikButton({ text, ...props }) {
  const { handleSubmit } = useFormikContext();

  return <BaseButton text={text} onPress={handleSubmit} {...props} />;
}
