import React from "react";
import { StyleSheet, View, Dimensions, ImageBackground, ScrollView } from "react-native";
import { Divider, Layout, Text, LayoutProps, Button } from "@ui-kitten/components";
import { EditIcon } from "./icons";

export const MemberShipBodies = (props) => {
  const { width } = Dimensions.get("window");
  const { style, hint, value, btnText, editAction, showEdit, ...layoutProps } = props;

  // console.log(value);

  return (
    <React.Fragment>
      <Layout level="1" {...layoutProps} style={[styles.container, style]}>
        {/* <View style={{ position: "absolute", top: 0, right: 0 }}>
          <Button onPress={editAction} style={styles.editAvatarButton} status="primary" accessoryLeft={EditIcon} />
        </View> */}
        <Text appearance="hint" category="s1">
          {hint}
        </Text>

        <View style={{ marginTop: 10, width: width - 30 }}>
          <Text category="s1" style={{ marginBottom: 10, textAlign: value ? "left" : "center" }}>
            {value || `N/A`}
          </Text>
          {showEdit ? (
            <Button status="primary" onPress={editAction}>
              {btnText}
            </Button>
          ) : (
            <></>
          )}
        </View>
      </Layout>
      <Divider />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
  },
  editAvatarButton: {
    aspectRatio: 1.0,
    height: 2,
  },

  avatar: {
    resizeMode: "contain",
    overflow: "hidden",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "lightgray",
    width: 100,
    marginRight: 5,
    // aspectRatio: 1.0,
    height: 100,
    // height: 100,
  },
});
