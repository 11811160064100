import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import WelcomeScreen from "../screens/WelcomeScreen";
import RegisterScreen from "../screens/RegisterScreen";
import LoginScreen from "../screens/LoginScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen";
import VerificationScreen from "../screens/VerificationScreen";

const Stack = createStackNavigator();

export default function AuthStack() {
  const [verificationId_G, setVerificationId_G] = React.useState(null);

  return (
    <Stack.Navigator
      initialRouteName="Welcome"
      headerMode="none"
      screenOptions={{
        cardStyle: {
          flex: 1,
        },
        headerTitleStyle: {
          fontFamily: "Signika_400Regular",
        },
      }}
    >
      <Stack.Screen
        name="Welcome"
        component={WelcomeScreen}
        options={{
          animationTypeForReplace: "pop",
        }}
      />
      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={{
          animationTypeForReplace: "pop",
        }}
      />
      <Stack.Screen
        name="Register"
        children={(props) => <RegisterScreen setVerificationId_G={setVerificationId_G} {...props} />}
        options={{
          animationTypeForReplace: "pop",
        }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPasswordScreen}
        options={{
          animationTypeForReplace: "pop",
        }}
      />
      <Stack.Screen
        name="Verification"
        children={(props) => <VerificationScreen verificationId_G={verificationId_G} {...props} />}
        options={{
          animationTypeForReplace: "pop",
        }}
      />
    </Stack.Navigator>
  );
}
