import React from "react";
import { StyleSheet, View, ScrollView, Image, KeyboardAvoidingView } from "react-native";
import {
  Button,
  Spinner,
  Divider,
  TopNavigation,
  TopNavigationAction,
  Datepicker,
  Layout,
  Text,
  Card,
  Modal,
  IndexPath,
  Select,
  SelectItem,
  Menu,
  MenuGroup,
  MenuItem,
} from "@ui-kitten/components";
import { certifiedMembership, skillCertificate, tradieBusinessNameSchema } from "../../../utils/schemas";
import LoadingIndicator from "../../Misc/LoadingIndicator";

import Form from "../../Forms/Form";
import FormErrorMessage from "../../Forms/FormErrorMessage";
import WithFormikInput from "../../Inputs/WithFormikInput";
import WithFormikButton from "../../Buttons/WithFormikButton";
import BaseText from "../../Typography/BaseText";
import ImagePIckerExample from "../../Misc/ImagePIckerExample";
import { firestoreUtils, getAppUtils, storageUtils, updateUserDetails } from "../../Firebase/firebase";
import { useFormikContext } from "formik";
import { intersectionWith } from "lodash";

const SelectOrgnization = ({ name, data }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));

  return (
    <>
      <Menu
        selectedIndex={selectedIndex}
        onSelect={(index) => {
          setSelectedIndex(index);
          setFieldValue(name, data[index?.row]?.value);
        }}
      >
        <MenuGroup title="Select certification from list">
          {data?.map((certifiedBody) => {
            return <MenuItem key={certifiedBody?.label} title={certifiedBody?.label} />;
          })}
        </MenuGroup>
      </Menu>
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
};

const EditSkillCertificate = ({ userDetails, setVisible }) => {
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [certificatesList, setCertificatesList] = React.useState({});
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    let observer1;

    const query = getAppUtils();
    const doc = query.doc(`certificates_list`);

    observer1 = doc.onSnapshot(
      (doc) => {
        setCertificatesList(doc.data());
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer1 && observer1();
    };
  }, []);

  const certifiedBodies = Object.values(certificatesList).map((body) => ({ label: body.name, value: body.name }));
  // console.log(userDetails?.userCertifications);

  React.useEffect(() => {
    let result = certifiedBodies.filter((o1) => !(userDetails?.userCertifications || []).some((o2) => o1.label === o2.certificateName));
    // console.log({ result });
    setData(
      !userDetails?.userCertifications || userDetails?.userCertifications?.length === 0
        ? certifiedBodies
        : userDetails?.userCertifications && userDetails?.userCertifications?.length > 0
        ? result
        : []
    );
  }, [userDetails, certifiedBodies?.length]);

  const formatImage = async (image, certificateName, userID, functionToRun) => {
    try {
      setProcessing(true);
      var storageRef = storageUtils.storage().ref();
      var metadata = {
        contentType: "image/jpeg",
      };

      const response = await fetch(image);
      const blob = await response.blob();

      const storagePath = `users/images/certifiedMemberImages`;
      var uploadTask = storageRef.child(`${storagePath}/${userID}_${certificateName}.jpg`).put(blob, metadata);
      uploadTask.on(
        "state_changed",
        () => {},
        (err) => {
          setProcessing(false);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await functionToRun(downloadURL, certificateName);
          });
        }
      );
    } catch (error) {
      console.log(error);
      setProcessing(false);
    }
  };

  const onPressSaveCertifiedMember = async (certifiedMemberImage, certificateName) => {
    try {
      setProcessing(true);
      const data = {
        certificateName,
        certifiedMemberImage,
      };

      await updateUserDetails(userDetails?.userID, {
        userCertifications: firestoreUtils.firestore.FieldValue.arrayUnion(data),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
      setVisible(false);
    }
  };

  return (
    <KeyboardAvoidingView contentContainerStyle={[styles.container]}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false} keyboardShouldPersistTaps="always">
        <Form
          initialValues={{
            certificateName: null,
            userCertificationImage: "",
          }}
          validationSchema={skillCertificate}
          onSubmit={async ({ certificateName, userCertificationImage }, formikBag) => {
            // console.log(values);
            await formatImage(userCertificationImage, certificateName, userDetails?.userID, onPressSaveCertifiedMember);
          }}
        >
          <BaseText text="Select certification from list" category="label" style={{ textAlign: "center" }} />
          <SelectOrgnization name="certificateName" data={data} user={userDetails} />

          <BaseText text="Certificate Image" category="label" style={{ textAlign: "center" }} />
          <ImagePIckerExample name="userCertificationImage" setLoading={setLoading} isOpenCamera={true} />

          <View style={{ marginTop: 10 }}>
            <WithFormikButton
              disabled={processing || loading}
              text={"Save Certificate"}
              accessoryLeft={processing ? LoadingIndicator : null}
              appearance={processing ? "outline" : "filled"}
            />

            <Button
              onPress={() => {
                setVisible(false);
              }}
              style={{ marginVertical: 5 }}
              disabled={processing || loading}
              status="warning"
              appearance={"outline"}
            >
              Close
            </Button>
          </View>
          {<FormErrorMessage error={loginError} visible={true} />}
        </Form>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default EditSkillCertificate;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
