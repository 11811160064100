import React from "react";
import { StyleSheet, View, FlatList, ScrollView, Linking, KeyboardAvoidingView } from "react-native";
import BaseButton from "../components/Buttons/BaseButton";
import { addDocToCollection, firestoreUtils, getAppUtils } from "../components/Firebase/firebase";
import {
  Button,
  Card,
  Layout,
  Text,
  Icon,
  Menu,
  MenuGroup,
  MenuItem,
  Spinner,
  Divider,
  TopNavigation,
  TopNavigationAction,
} from "@ui-kitten/components";
import BaseText from "../components/Typography/BaseText";
import WithFormikInput from "../components/Inputs/WithFormikInput";
import Form from "../components/Forms/Form";
import { submitIssueSchema } from "../utils/schemas";
import WithFormikButton from "../components/Buttons/WithFormikButton";
import FormErrorMessage from "../components/Forms/FormErrorMessage";
import { ArrowIosBackIcon } from "../components/icons";
import { useFormikContext } from "formik";
import { genRandomString } from "../utils";
import { useHeaderHeight } from "@react-navigation/stack";

const LoadingIndicator = (props) => (
  <View style={[props.style, styles.indicator]}>
    <Spinner size="small" />
  </View>
);

const Categories = ({ name = "category", contactIssues = {} }) => {
  const { setFieldTouched, setFieldValue, values, errors, touched } = useFormikContext();
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const issueCategories =
    contactIssues &&
    Object.keys(contactIssues).map((key) => {
      return {
        contactIssueID: key,
        contactIssueMessage: contactIssues[key],
      };
    });

  React.useEffect(() => {
    if (selectedIndex) {
      const { row } = selectedIndex;
      setFieldValue(`category`, issueCategories[row]?.contactIssueID);
    }
  }, [selectedIndex]);

  return (
    <React.Fragment>
      <Menu selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)}>
        <MenuGroup title="Select Category">
          {issueCategories?.map((issueCategory) => {
            return <MenuItem key={issueCategory?.contactIssueID} title={issueCategory?.contactIssueMessage} />;
          })}
        </MenuGroup>
      </Menu>
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
    </React.Fragment>
  );
};

const ContactUsScreen = ({ navigation, route, userDetails }) => {
  const [contactIssues, setContactIssues] = React.useState({});
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);

  //   console.log(issueCategories);

  React.useEffect(() => {
    let observer1;

    const query = getAppUtils();
    const doc = query.doc(`contact_issues`);

    observer1 = doc.onSnapshot(
      (doc) => {
        setContactIssues(doc.data());
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer1 && observer1();
    };
  }, []);

  const openWhatsapp = () => {
    try {
      let url = "whatsapp://send?text=" + "" + "&phone=233" + "595850838";
      Linking.openURL(url)
        .then((data) => {
          console.log("WhatsApp Opened successfully " + data);
          navigation.goBack();
        })
        .catch((error) => {
          console.log(error);
          alert("Make sure WhatsApp installed is on your device");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const submitIssue = async (values) => {
    try {
      const data = {
        contactIssueMessage: values?.details,
        contactIssueID: values?.category || "I43POKMNXGHU78SY7R",
        contactIssueName: contactIssues[values?.category] || "GENERAL FEEDBACK/COMPLAINTS",
        contactUserID: userDetails.userID,
        contactUserEmail: userDetails.email,
        contactUserPhone: userDetails.mobileNumber,
        dateCreated: firestoreUtils.serverTimestamp(),
        dateUpdated: firestoreUtils.serverTimestamp(),
        dateDeleted: null,
      };

      await addDocToCollection(`contactIssues`, genRandomString(20), data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <KeyboardAvoidingView style={[styles.container]}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false}>
          <BaseButton
            text="Contact us via Whatsapp"
            onPress={() => {
              openWhatsapp();
            }}
          />

          <Form
            initialValues={{
              details: "",
              category: "",
            }}
            validationSchema={submitIssueSchema}
            onSubmit={async (values) => {
              // console.log({ values });
              setProcessing(true);
              await submitIssue(values);
              setProcessing(false);
              navigation.goBack();
            }}
          >
            <View
              style={{
                marginTop: 30,
              }}
            >
              <Categories contactIssues={contactIssues} />

              <View style={{ marginTop: 5 }}>
                <BaseText text="Details" category="label" />
                <WithFormikInput
                  name="details"
                  placeholder="Enter some details"
                  autoCapitalize="none"
                  multiline={true}
                  textStyle={{ minHeight: 150 }}
                />
              </View>

              <View style={{ marginTop: 10 }}>
                <WithFormikButton
                  disabled={processing}
                  text={"Submit"}
                  accessoryLeft={processing ? LoadingIndicator : null}
                  appearance={processing ? "outline" : "filled"}
                />
              </View>
              {<FormErrorMessage error={loginError} visible={true} />}
            </View>
          </Form>
        </ScrollView>
      </KeyboardAvoidingView>
    </>
  );
};

export default ContactUsScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
  card: {
    flex: 1,
    marginVertical: 3,
  },
  footerControl: {
    marginHorizontal: 2,
  },
  indicator: {
    justifyContent: "center",
    alignItems: "center",
  },
});
