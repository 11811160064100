const prod = {
  apiKey: "AIzaSyApgg0tkZwPMfxFhiuA41G_rKrV3vj3jVY",
  authDomain: "handie-tradie-web.firebaseapp.com",
  databaseURL: "https://handie-tradie-web.firebaseio.com",
  projectId: "handie-tradie-web",
  storageBucket: "handie-tradie-web.appspot.com",
  messagingSenderId: "1053849050247",
  appId: "1:1053849050247:web:f31ae4ddeaefce5d912c22",
  measurementId: "G-E1X6YRZ85D",
  env: "prod",
};

const sandbox = {
  apiKey: "AIzaSyAt4IZ6GI8V6ru8BC0QuXNXhSfdBGkPqmo",
  authDomain: "handytradie-sandbox.firebaseapp.com",
  databaseURL: "https://handytradie-sandbox.firebaseio.com",
  projectId: "handytradie-sandbox",
  storageBucket: "handytradie-sandbox.appspot.com",
  messagingSenderId: "57513710831",
  appId: "1:57513710831:web:52d340f28a455906f5f71a",
  measurementId: "G-6RH5V27178",
  env: "sandbox",
};

module.exports = { prod, sandbox };
