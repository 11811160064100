import React from "react";
import { StyleSheet, View, ScrollView, Platform, Dimensions, Pressable, Image } from "react-native";
import { Text } from "@ui-kitten/components";

import FormErrorMessage from "../Forms/FormErrorMessage";

import { useFormikContext } from "formik";

import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";

const ImagePIckerExample = React.forwardRef(({ name, setLoading = () => {}, handleExpandPress }, ref) => {
  const { errors, values, touched, setFieldValue } = useFormikContext();

  React.useImperativeHandle(
    ref,
    () => {
      return {
        pickImage,
      };
    },
    []
  );

  React.useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status: cameraStatus } = await ImagePicker.requestCameraPermissionsAsync();
        if (cameraStatus !== "granted") {
          alert("Sorry, we need camera permissions to make this work!");
        }

        const { status: rollStatus } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (rollStatus !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);

  const pickImage = async (isOpenCamera = false) => {
    try {
      setLoading(true);
      let result;
      if (isOpenCamera) {
        result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
          allowsMultipleSelection: false,
        });
      } else {
        result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
      }

      if (!result?.cancelled) {
        setLoading(true);
        const file = await ImageManipulator.manipulateAsync(
          result?.uri,
          [],
          // [{ resize: { width: 1000 } }],
          {
            compress: 0.5,
            format: ImageManipulator.SaveFormat.JPEG,
            base64: true,
          }
        );

        // console.log(file);
        setFieldValue(name, file.uri);
        setFieldValue(`base64String`, file.base64);
        setLoading(false);
        //   setImage(result.uri);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Pressable style={{ alignItems: "center", justifyContent: "center" }} onPress={handleExpandPress}>
      {!values[name] ? (
        <View
          style={{
            width: 150,
            height: 150,
            borderColor: "gray",
            borderRadius: 5,
            borderWidth: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ textAlign: "center" }}>{`Tap to add an image`}</Text>
        </View>
      ) : (
        <></>
      )}

      {values[name] ? (
        <Image source={{ uri: values[name] }} style={{ width: 150, height: 150, borderRadius: 5, borderColor: "white", borderWidth: 1 }} />
      ) : (
        <></>
      )}

      <FormErrorMessage error={errors[name]} visible={touched[name]} />
    </Pressable>
  );
});

export default ImagePIckerExample;

const styles = StyleSheet.create({});
