import React from "react";
import Constants from "expo-constants";
import { Dimensions, StyleSheet, View, Pressable } from "react-native";
import Animated, { EasingNode, interpolateNode, useCode } from "react-native-reanimated";
import { delay, loop, timing, useValue } from "react-native-redash/lib/module/v1";
import { Button, Text } from "@ui-kitten/components";

export const ACTIONS = {
  SHOW_SNACK: "SHOW_SNACK",
  CLOSE_SNACK: "CLOSE_SNACK",
  SHOW_LOADING_BAR: "SHOW_LOADING_BAR",
  CLOSE_LOADING_BAR: "CLOSE_LOADING_BAR",
  SET_SELECTED_SUBURB: "SET_SELECTED_SUBURB",
  SET_SELECTED_DATE: "SET_SELECTED_DATE",
};

export const notifyState = {
  loadingBar: false,
  snack: false,
  snackMessage: "A message to show",
  selectedSuburb: "Select A Location",
};

const NotifyContext = React.createContext(notifyState);

const { set } = Animated;

function LoadingBar() {
  const { width } = Dimensions.get("window");

  const animatedValue = useValue(0);

  useCode(() => {
    return set(
      animatedValue,
      loop({
        duration: 2000,
        easing: EasingNode.linear,
      })
    );
  }, [animatedValue]);

  const _transX = interpolateNode(animatedValue, {
    inputRange: [0, 1],
    outputRange: [0, width],
  });

  const _width = interpolateNode(animatedValue, {
    inputRange: [0, 1],
    outputRange: [100, width * 0.5],
  });

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.bar, { width: _width, transform: [{ translateX: _transX }] }]} />
    </View>
  );
}

function NotificationBar({ message, onPressClose }) {
  const { width } = Dimensions.get("window");

  return (
    <Button
      appearance="ghost"
      onPress={onPressClose}
      style={[
        {
          backgroundColor: "#fff",
          width: "95%",
          padding: 2,
          position: "absolute",
          flexDirection: "row",
          justifyContent: "space-between",
          zIndex: 10,
          elevation: 1,
          boxShadow: `0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)`,
        },
        { left: 0.025 * width, top: 5 },
        // { left: 0.025 * width, top: Constants.statusBarHeight + 5 },
      ]}
    >
      <Text>{message}</Text>
      <Text category="label" style={{ color: "gray" }}>{`       Tap to close`}</Text>
    </Button>
  );
}

const NotificationProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer((prevState, action) => {
    switch (action.type) {
      case ACTIONS.SHOW_LOADING_BAR:
        return {
          ...prevState,
          loadingBar: true,
        };
      case ACTIONS.CLOSE_LOADING_BAR:
        return {
          ...prevState,
          loadingBar: false,
        };
      case ACTIONS.SHOW_SNACK:
        return {
          ...prevState,
          snack: true,
          snackMessage: action.message,
        };
      case ACTIONS.CLOSE_SNACK:
        return {
          ...prevState,
          snack: false,
          snackMessage: "",
        };
    }
  }, notifyState);

  const notificationContext = React.useMemo(
    () => ({
      showLoadingBar: () => dispatch({ type: ACTIONS.SHOW_LOADING_BAR }),
      closeLoadingBar: () => dispatch({ type: ACTIONS.CLOSE_LOADING_BAR }),
      closeSnack: () => dispatch({ type: ACTIONS.CLOSE_SNACK }),
      showSnack: async (message) => {
        dispatch({ type: ACTIONS.SHOW_SNACK, message });
      },
    }),
    []
  );

  return (
    <NotifyContext.Provider value={notificationContext}>
      {state.loadingBar && <LoadingBar />}
      {state.snack && <NotificationBar message={state.snackMessage} onPressClose={() => dispatch({ type: ACTIONS.CLOSE_SNACK })} />}
      {children}
    </NotifyContext.Provider>
  );
};

export const useNofitication = () => React.useContext(NotifyContext);

export default NotificationProvider;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    backgroundColor: "#ecf0f1",
    padding: 0,
    width: "100%",
  },
  bar: {
    height: 5,
    backgroundColor: Constants.manifest.extra.colors.primary.main,
  },
});
