import React from "react";
import { ListRenderItemInfo, StyleSheet, View } from "react-native";
import { Button, Card, List, Text } from "@ui-kitten/components";
import { ImageOverlay } from "./extra/image-overlay.component";
import { Product } from "./extra/data";

export default ({ products = [] }) => {
  const data = products?.map((product) => Product.getProductCard(product));

  const renderItemHeader = (info) => <ImageOverlay style={styles.itemHeader} source={{ uri: info.item.photo }}></ImageOverlay>;

  const renderItemFooter = () => (
    <View style={styles.itemFooter}>
      <View style={styles.itemReactionsContainer}>
        {/* <Button
          style={styles.iconButton}
          appearance="ghost"
          status="basic"
          accessoryLeft={ShareIcon}
        />
        <Button
          style={styles.iconButton}
          appearance="ghost"
          status="danger"
          accessoryLeft={HeartIcon}
        /> */}
      </View>
      {/* <Button
        style={styles.itemAddButton}
        appearance="ghost"
        // accessoryLeft={PlusIcon}
      >
        Edit Product
      </Button> */}
    </View>
  );

  const renderItem = (info) => (
    <Card style={styles.item} header={() => renderItemHeader(info)} footer={renderItemFooter}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <View>
          <Text style={styles.itemDescription} category="h4">
            {info.item.name}
          </Text>
          <Text style={styles.itemDescription} category="s1">
            {info.item.notes || "No notes entered"}
          </Text>
          <Text style={styles.itemDescription} category="p2">
            Added: {info.item.date}
          </Text>
        </View>

        <Text style={styles.itemDescription} category="s1">
          GHS{info.item.price}
        </Text>
      </View>
    </Card>
  );

  return (
    <>
      <List style={styles.list} contentContainerStyle={styles.listContent} data={data} renderItem={renderItem} />
    </>
  );
};

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  listContent: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  item: {
    marginVertical: 8,
  },
  itemHeader: {
    minHeight: 220,
  },
  itemTitle: {
    position: "absolute",
    left: 24,
    bottom: 24,
  },
  itemDescription: {
    marginHorizontal: -8,
  },
  itemFooter: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemReactionsContainer: {
    flexDirection: "row",
  },
  itemAddButton: {
    flexDirection: "row-reverse",
    paddingHorizontal: 0,
  },
  iconButton: {
    paddingHorizontal: 0,
  },
});
