import React, { useState } from "react";
import { StyleSheet, View, Image, Text, Dimensions, ScrollView, KeyboardAvoidingView } from "react-native";
import * as Yup from "yup";

import Colors from "../utils/colors";
import SafeView from "../components/SafeView";
import Form from "../components/Forms/Form";
import IconButton from "../components/IconButton";
import FormErrorMessage from "../components/Forms/FormErrorMessage";
import { firebaseUtils, firestoreUtils, getAppUtils, setUserDetails } from "../components/Firebase/firebase";
import { StatusBar } from "expo-status-bar";

import WithFormikButton from "../components/Buttons/WithFormikButton";
import LoadingIndicator from "../components/Misc/LoadingIndicator";
import AsyncStorage from "@react-native-async-storage/async-storage";

import WithFormikInput from "../components/Inputs/WithFormikInput";
import { genRandomString } from "../utils";
import dayjs from "dayjs";
import Logo from "../components/Misc/Logo";
import { useHeaderHeight } from "@react-navigation/stack";

const validationSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .matches(/\d{4,}/, "Verification code should be 4 or more digits")
    .required("Please enter verification code"),
});

export default function VerificationScreen({ navigation, verificationId_G }) {
  const { width } = Dimensions.get("window");
  const [accountDefaults, setAccountDefaults] = React.useState({});

  React.useEffect(() => {
    (async () => {
      const query = getAppUtils();
      const doc = await query.doc(`account_defaults`).get();
      const data = doc.data();
      setAccountDefaults(data);
    })();
  }, []);

  const [registerError, setRegisterError] = useState("");

  const [processing, setProcessing] = useState(false);

  const verifyCode = async ({ verificationCode }) => {
    try {
      setProcessing(true);
      let verificationId = verificationId_G;

      if (!verificationId) {
        try {
          verificationId = await AsyncStorage.getItem("@handytradie/verificationId");
        } catch (e) {
          console.log("Error occurred retrieving verificationId ", e);
        } finally {
          if (!verificationId) {
            console.log("Unable to retrieve Verification ID. Please Sign In again");
          }
        }
      }

      const credential = firebaseUtils.firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
      firebaseUtils.firebase
        .auth()
        .signInWithCredential(credential)
        .then(async (userData) => {
          const uid = userData?.user?.uid;

          const {
            additionalUserInfo: { isNewUser },
          } = userData;

          if (isNewUser) {
            await setUserDetails(uid, {
              ...accountDefaults,
              dateCreated: firestoreUtils.timestampNow(),
              dateString: dayjs(firestoreUtils.timestampNow().toDate()).format("DD/MM/YYYY"),
              expoNotificationToken: null,
              username: `User${genRandomString(5)}`,
              userID: uid,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setProcessing(false);
        });
    } catch (error) {
      setRegisterError(error.message);
      console.error(error);
      setProcessing(false);
    }
  };

  return (
    <KeyboardAvoidingView style={[styles.container]} keyboardVerticalOffset={useHeaderHeight()}>
      <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }} showsVerticalScrollIndicator={false}>
        <Logo />
        <Form
          initialValues={{
            verificationCode: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => verifyCode(values)}
        >
          <View style={{ marginTop: 10 }}>
            <WithFormikInput name="verificationCode" placeholder="Enter your code here" keyboardType="number-pad" size="large" />
          </View>

          <View style={{ marginTop: 10 }}>
            <WithFormikButton
              style={{ paddingHorizontal: 150 }}
              disabled={processing}
              text={"Verify"}
              accessoryLeft={processing ? LoadingIndicator : null}
              appearance={processing ? "outline" : "filled"}
            />
          </View>
          {<FormErrorMessage error={registerError} visible={true} />}
        </Form>
        <IconButton
          style={styles.backButton}
          iconName="keyboard-backspace"
          color={Colors.black}
          size={30}
          onPress={() => navigation.goBack()}
        />

        <StatusBar style="auto" />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: Colors.white,
  },
  backButton: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
  },
  logoContainer: {
    alignItems: "center",
  },
  logo: {
    width: 125,
    height: 125,
    borderRadius: 10,
  },

  subtitle: {
    fontSize: 24,
    fontWeight: "600",
    paddingTop: 20,
    paddingBottom: 0,
    color: `black`,
  },
});
