import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Constants from "expo-constants";
import React from "react";
import { Text, View, StyleSheet, Platform } from "react-native";
import DashboardScreen from "../screens/DashboardScreen";
import MoreScreen from "../screens/MoreScreen";
import { Icon } from "@ui-kitten/components";
import ShopScreen from "../screens/ShopScreen";
import ProsScreen from "../screens/ProsScreen";
import { createStackNavigator } from "@react-navigation/stack";
import ExtraNav from "../screens/ExtraNav";

const icons = {
  Dashboard: "home-outline",
  Pros: "star",
  Shop: "shopping-cart-outline",
  Messages: "message-square-outline",
  More: "list-outline",
};

const tabNavigatorConfig = {
  screenOptions: ({ route }) => ({
    // eslint-disable-next-line react/display-name
    tabBarIcon: ({ focused, color, size }) => {
      let iconSize = size;
      if (focused) {
        iconSize = size * 1.1;
      }
      return <Icon style={styles.icon} name={icons[route.name]} fill={color} />;
    },
  }),
  tabBarOptions: {
    activeTintColor: Constants.manifest.extra.colors.primary.light,
    inactiveTintColor: "#000",
    labelStyle: { fontSize: 12, fontFamily: "Signika_300Light" },
    style: {
      paddingBottom: 3,
      height: 54,
    },

    // keyboardHidesTabBar: Platform.OS !== "ios",
  },
};

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

function AppMain({ navigation, route }) {
  return (
    <Tab.Navigator
      {...{
        ...tabNavigatorConfig,
      }}
    >
      <Tab.Screen name="Dashboard" component={DashboardScreen} />
      <Tab.Screen name="More" component={MoreScreen} />
    </Tab.Navigator>
  );
}

export default function AppStack({ navigation, route }) {
  return (
    <Stack.Navigator
      headerMode="none"
      screenOptions={({ route }) => ({
        cardStyle: {
          flex: 1,
        },
        headerTitleStyle: {
          fontFamily: "Signika_400Regular",
        },
      })}
    >
      <Stack.Screen name="Main" component={AppMain} />
      <Stack.Screen name="ExtraNav" component={ExtraNav} />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  icon: {
    width: 25,
    height: 25,
  },
});
