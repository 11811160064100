import React from "react";
import { View, StyleSheet, Image } from "react-native";
import { Divider, Text, Button, Icon } from "@ui-kitten/components";

import AppButton from "../components/AppButton";
import Colors from "../utils/colors";
import useStatusBar from "../hooks/useStatusBar";
import { StatusBar } from "expo-status-bar";
import Logo from "../components/Misc/Logo";

export default function WelcomeScreen({ navigation }) {
  useStatusBar("light-content");

  return (
    <>
      <View style={styles.container}>
        <Logo />
        <View style={styles.buttonContainer}>
          <AppButton title="Login with Email" onPress={() => navigation.navigate("Login")} />
          <AppButton title="Login with Phone Number" status="info" onPress={() => navigation.navigate("Register")} />
        </View>
        <StatusBar style="auto" />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: Colors.white,
  },
  logoContainer: {
    position: "absolute",
    top: 60,
    alignItems: "center",
  },
  logo: {
    width: 125,
    height: 125,
    borderRadius: 10,
  },
  subtitle: {
    fontSize: 24,
    fontWeight: "600",
    paddingTop: 20,
    paddingBottom: 0,
    color: `black`,
  },
  buttonContainer: {
    padding: 20,
    paddingBottom: 60,
    width: "100%",
  },
});
