import React, { useContext, useEffect, useState } from "react";
import { Text } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { StatusBar } from "expo-status-bar";
import { auth } from "../components/Firebase/firebase";
import navigationTheme from "./navigationTheme";
import AuthStack from "./AuthStack";
import AppStack from "./AppStack";
import * as Linking from "expo-linking";
import { AuthUserContext } from "../ctx/AuthUserProvider";
import Spinner from "../components/Spinner";
import ShowStoreVariants from "../screens/ShowStoreVariants";

const prefix = Linking.createURL("/");

const linking = {
  prefixes: ["https://ht-shop.vercel.app", prefix],
  config: {
    screens: {
      MainStack: "/",
      ShowEnquiryDetails: "/enquire",
    },
  },
};

const Stack = createStackNavigator();

export default function Routes() {
  const { user, setUser } = useContext(AuthUserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (authUser) => {
      try {
        await (authUser ? setUser(authUser) : setUser(null));
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    });

    return unsubscribeAuth;
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <StatusBar style="default" />
      <NavigationContainer theme={navigationTheme} linking={linking} fallback={<Text>Loading...</Text>}>
        <Stack.Navigator
          screenOptions={({ route }) => ({
            cardStyle: {
              flex: 1,
            },
            headerTitleStyle: {
              fontFamily: "Signika_400Regular",
            },
          })}
        >
          <Stack.Screen name="MainStack" component={user ? AppStack : AuthStack} options={{ headerShown: false }} />
          <Stack.Screen name="ShowEnquiryDetails" component={ShowStoreVariants} options={{ headerShown: false }} />
          {/* {user ? <AppStack /> : <AuthStack />} */}
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
}
