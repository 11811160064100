import React from "react";
import { StyleSheet, View, FlatList, ScrollView } from "react-native";
import BaseButton from "../components/Buttons/BaseButton";
import { getAllNotifications } from "../components/Firebase/firebase";
import { Button, Card, Layout, Text, Divider, TopNavigation, TopNavigationAction } from "@ui-kitten/components";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import { SafeAreaLayout } from "../components/safe-area-layout.component.jsx";
import { ArrowIosBackIcon } from "../components/icons";

import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Header = ({ item, ...props }) => {
  //   console.log(item);
  return (
    <View {...props} style={{ paddingLeft: 10 }}>
      <Text category="h6">{item?.title}</Text>
      <Text category="label">{dayjs().to(dayjs(item?.timestamp?.toDate()))}</Text>
    </View>
  );
};

const Footer = ({ item, ...props }) => (
  <View {...props} style={[styles.footerContainer]}>
    <Text style={styles.footerControl} status={item.expired ? "danger" : "info"}>
      {item.expired ? "Expired" : `Valid until ${item.expiryDate}`}
    </Text>
  </View>
);

const renderItem = ({ item }) => {
  //   console.log(item);
  return (
    <Card style={styles.card} status={item?.used ? "info" : `success`} footer={() => <Footer item={item} />}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text category="h6" style={{ marginRight: 30 }}>{`GHS${item?.amount} off`}</Text>

        <View>
          {item?.voucherTrigger ? <Text>{`on tasks over GHS ${item?.voucherTrigger}`}</Text> : <></>}
          <Text category="label">{`Voucher Code`}</Text>
          <Text category="s1" size="big">{`${item?.code}`}</Text>
        </View>
      </View>
    </Card>
  );
};

const PromoCodeScreen = ({ navigation, route, userDetails }) => {
  return (
    <View
      style={{
        height: "100%",
      }}
    >
      <View
        style={{
          marginHorizontal: "auto",
          paddingHorizontal: 10,
          height: "100%",
        }}
      >
        <FlatList
          ListEmptyComponent={
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center", height: "100%" }}>
              <Text category="h6" style={{ textAlign: "center" }}>
                No Promos
              </Text>
            </View>
          }
          style={{
            height: "100%",
          }}
          keyExtractor={(item, index) => item?.code + index}
          showsVerticalScrollIndicator={false}
          data={userDetails?.promos || []}
          renderItem={renderItem}
        />
      </View>
    </View>
  );
};

export default PromoCodeScreen;

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  card: {
    margin: 2,
    marginBottom: 10,
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  footerControl: {
    marginVertical: 5,
    margin: 2,
  },
});
