import React from "react";
import { StyleSheet, Dimensions } from "react-native";
import { Button, Card, List, Text } from "@ui-kitten/components";
import { ImageOverlay } from "../view-products/extra/image-overlay.component";
import dayjs from "dayjs";
import { getAllStoreProducts } from "../Firebase/firebase";

const renderItemHeader = (info) => <ImageOverlay style={styles.itemHeader} source={{ uri: info.item.productImage }}></ImageOverlay>;

const renderItemFooter = (info, editProduct) => (
  <View style={styles.itemFooter}>
    <View style={[styles.itemReactionsContainer]}>
      {info?.item?.productOutOfStock ? (
        <Text category="label" status="danger">
          Product out of stock
        </Text>
      ) : (
        <></>
      )}
      {/* <Button
        style={styles.iconButton}
        appearance="ghost"
        status="basic"
        accessoryLeft={ShareIcon}
      />
      <Button
        style={styles.iconButton}
        appearance="ghost"
        status="danger"
        accessoryLeft={HeartIcon}
      /> */}
    </View>
    <Button
      style={styles.itemAddButton}
      appearance="ghost"
      onPress={() => {
        editProduct();
      }}
      // accessoryLeft={PlusIcon}
    >
      Edit Product
    </Button>
  </View>
);

const renderItem = (info, editProduct) => {
  const { width } = Dimensions.get("window");
  const dateToString = (date) => {
    return dayjs(date?.toDate()).format("ddd, DD MMM YYYY");
  };

  return (
    <Card style={styles.item} header={() => renderItemHeader(info)} footer={() => renderItemFooter(info, () => editProduct(info.item))}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <View>
          <Text style={styles.itemDescription} category="h4">
            {info.item.productName}
          </Text>
          <Text style={[styles.itemDescription, { maxWidth: width - 120 }]} category="s1">
            {info.item.productNotes || "No notes entered"}
          </Text>
          <Text style={[styles.itemDescription, { paddingTop: 10 }]} category="p2">
            Added: {dateToString(info.item.productAdded)}
          </Text>
        </View>
        {/* 
        <Text style={[styles.itemDescription, { maxWidth: 100 }]} category="s1">
          GHS{info.item.productPrice}
        </Text> */}
      </View>
    </Card>
  );
};

const ViewProducts = ({ navigation, route, userDetails }) => {
  const { storeDetails } = route.params;
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    let observer;

    const query = getAllStoreProducts(storeDetails?.storeID);

    observer = query?.onSnapshot(
      (querySnapshot) => {
        // console.log(`Received query snapshot of size ${querySnapshot.size}`);
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });

        setProducts(docs);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer && observer();
    };
  }, []);

  const editProduct = (item) => {
    try {
      navigation?.navigate(`Add New Product`, { storeDetails, item, title: "Edit Product", isEditing: true });
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(data);

  return (
    <>
      <List
        style={styles.list}
        contentContainerStyle={styles.listContent}
        data={products}
        renderItem={(info) => renderItem(info, editProduct)}
      />
    </>
  );
};

export default ViewProducts;

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  listContent: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  item: {
    marginVertical: 8,
  },
  itemHeader: {
    minHeight: 220,
  },
  itemTitle: {
    position: "absolute",
    left: 24,
    bottom: 24,
  },
  itemDescription: {
    marginHorizontal: -8,
  },
  itemFooter: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemReactionsContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20,
  },
  itemAddButton: {
    flexDirection: "row-reverse",
    paddingHorizontal: 0,
  },
  iconButton: {
    paddingHorizontal: 0,
  },
});
