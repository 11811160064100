import React from "react";
import { StyleSheet } from "react-native";
import { Text } from "@ui-kitten/components";

import Colors from "../../utils/colors";

export default function FormErrorMessage({ error, visible }) {
  if (!error || !visible) {
    return null;
  }

  return <Text style={styles.errorText}>{error}</Text>;
}

const styles = StyleSheet.create({
  errorText: {
    color: Colors.red,
    fontSize: 12,
    marginTop: 1,
    marginBottom: 5,
    fontWeight: "600",
  },
});
