import React, { useCallback, useMemo, useRef } from "react";
import { View, Text, StyleSheet, Dimensions } from "react-native";
import BottomSheet, { BottomSheetModal, BottomSheetScrollView } from "@gorhom/bottom-sheet";
import Animated, { interpolateColors, useAnimatedStyle } from "react-native-reanimated";
import Constants from "expo-constants";

const BottomSheetSnapper = React.forwardRef(({ snapPoints = ["50%", "80%"], children }, ref) => {
  const { width, height } = Dimensions.get("window");

  const CustomBackground = ({ style, animatedIndex }) => {
    const containerAnimatedStyle = useAnimatedStyle(() => ({
      backgroundColor: interpolateColors(animatedIndex.value, [0, 1], ["#fff", Constants.manifest.extra.colors.primary.main]),
    }));

    const containerStyle = useMemo(() => [style, containerAnimatedStyle], [style, containerAnimatedStyle]);
    return <Animated.View pointerEvents="none" style={[containerStyle, { opacity: 0.2 }]} />;
  };

  // callbacks
  const handleSheetChanges = useCallback((index) => {
    // console.log("handleSheetChanges", index);
  }, []);

  // renders
  return (
    <BottomSheetModal ref={ref} index={0} snapPoints={snapPoints} onChange={handleSheetChanges}>
      <BottomSheetScrollView contentContainerStyle={[styles.contentContainer]}>{children}</BottomSheetScrollView>
    </BottomSheetModal>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: 24,
  },
  contentContainer: {
    flex: 1,
    alignItems: "center",
  },
});

export default BottomSheetSnapper;
