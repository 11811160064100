import { ImageSourcePropType } from "react-native";
import dayjs from "dayjs";

export class Product {
  constructor({ productName, productAdded, productImage, productPrice, productNotes, productOutOfStock, productID }) {
    this.productName = productName;
    this.productAdded = productAdded;
    this.productImage = productImage;
    this.productPrice = productPrice;
    this.productID = productID;
    this.productNotes = productNotes;
    this.productOutOfStock = productOutOfStock;
  }

  static dateToString(date) {
    return dayjs(date?.toDate()).format("ddd, DD MMM YYYY");
  }

  static getProductCard(product) {
    const newProduct = new Product(product);
    const formattedDate = this.dateToString(newProduct?.productAdded);
    return {
      ...newProduct,
      productAdded: formattedDate,
    };
  }
}
