import React from "react";
import { StyleSheet } from "react-native";
import { Divider, TopNavigation, TopNavigationAction } from "@ui-kitten/components";
import { SafeAreaLayout } from "../components/safe-area-layout.component.jsx";
import { ArrowIosBackIcon } from "../components/icons";
import ProfileDetails from "../components/profile/ProfileDetails";

const Profile1Screen = ({ userDetails, navigation }) => {
  // console.log({ userDetails });

  return (
    <SafeAreaLayout style={styles.container} insets="top">
      <ProfileDetails navigation={navigation} userDetails={userDetails} />
    </SafeAreaLayout>
  );
};

export default Profile1Screen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
