import React from "react";
import { StyleSheet, View, FlatList, ScrollView, ActivityIndicator, ImageBackground, Dimensions } from "react-native";
import BaseButton from "../components/Buttons/BaseButton";
import { getAllNotifications, getTradies } from "../components/Firebase/firebase";
import { Button, Card, Layout, Text, Icon } from "@ui-kitten/components";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import dayjs from "dayjs";
import Constants from "expo-constants";

const IdentityVerified = () => (
  <View style={styles.editAvatarButton}>
    <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
      <Icon style={styles.icon} fill="#fff" name="checkmark-circle-outline" />
      <Text category="label" style={{ color: "white" }}>
        Identity Verified
      </Text>
    </View>
  </View>
);

const Footer = ({ item, ...props }) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        width: "100%",
        padding: 5,
      }}
      {...props}
    >
      {item?.userCertifications
        ?.filter((certification) => certification?.certifiedMemberVerified)
        .map((certification, index) => {
          //   console.log({ certification });
          return (
            <View
              key={certification?.certificateName + index}
              style={{
                // flex: 1,
                padding: 3,
                paddingHorizontal: 10,
                backgroundColor: Constants.manifest.extra.colors.secondary.main,
                borderRadius: 5,
                marginHorizontal: 1,
                marginVertical: 2,
              }}
            >
              <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <Icon style={{ width: 15, height: 15 }} fill="#fff" name="checkmark-circle-outline" />
                <Text key={certification?.certificateName + index} style={{ color: "#fff", marginLeft: 5, fontSize: 12 }}>
                  {certification?.certificateName}
                </Text>
              </View>
            </View>
          );
        })}
    </View>
  );
};

const renderTradie = ({ item, onPressGoToProfile }) => {
  const { width } = Dimensions.get("window");
  return (
    <Card
      onPress={() => {
        onPressGoToProfile(item?.userID);
      }}
      style={styles.card}
      footer={() => <Footer item={item} />}
    >
      <View style={{ flexDirection: "row" }}>
        <View style={styles.profileAvatar}>
          <ImageBackground source={{ uri: item.photoURL }} style={[styles.avatar]} />
          {item?.identityVerified && <IdentityVerified />}
        </View>

        <View style={{ flex: 1, marginLeft: 8 }}>
          <Text style={{ fontWeight: `bold`, fontFamily: `Signika_700Bold` }}>
            {item?.firstName} {item.lastName[0]}.
          </Text>
          <Text>{item?.suburb}</Text>
          <View
            style={{
              flex: 0.5, //height (according to its parent),
              marginTop: 5,
              //   flexDirection: "row",
            }}
          >
            {[...item?.tradieSkills].map((skill, i) => (
              <Text key={skill} style={{ flexWrap: "wrap", fontSize: 12 }} appearance="hint">
                {skill} {i !== item?.tradieSkills.length - 1 ? " " : " "}
              </Text>
            ))}
          </View>
        </View>
      </View>
    </Card>
  );
};

const RenderList = ({ docs, loading, onPressGoToProfile }) => {
  return (
    <View
      style={{
        marginHorizontal: "auto",
        paddingHorizontal: 10,
        height: "100%",
      }}
    >
      <FlatList
        ListEmptyComponent={
          <Text category="h6" style={{ textAlign: "center" }}>
            {loading ? "Loading..." : "No tradies found"}
          </Text>
        }
        style={{
          height: "100%",
        }}
        keyExtractor={(item, index) => item?.userID + index}
        showsVerticalScrollIndicator={false}
        data={docs}
        renderItem={({ item }) => renderTradie({ item, onPressGoToProfile })}
      />
    </View>
  );
};

const TopTab = createMaterialTopTabNavigator();

const ProsScreen = ({ navigation, route, user }) => {
  const [loading, setLoading] = React.useState(false);
  const [tradiesDocs, setTradiesDocs] = React.useState([]);

  //   console.log({ tradiesDocs });

  const onPressGoToProfile = (userID, firstName, lastName) => {
    navigation.navigate(`ExtraNav`, { screen: "Tradie Account", params: { userID } });
  };

  React.useEffect(() => {
    let observer1;

    const tradiesQuery = getTradies();

    observer1 = tradiesQuery.onSnapshot(
      (querySnapshot) => {
        setLoading(true);
        // console.log(`Received query snapshot of size ${querySnapshot.size}`);
        var docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });
        setTradiesDocs(docs);
        setLoading(false);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer1 && observer1();
    };
  }, []);

  return (
    <>
      <TopTab.Navigator
        tabBarOptions={{
          indicatorStyle: {
            backgroundColor: Constants.manifest.extra.colors.secondary.main,
          },
          labelStyle: { fontFamily: "Signika_400Regular" },
        }}
        lazy
        lazyPlaceholder={({ route }) => {
          return (
            <View style={{ flex: 1, justifyContent: "center" }}>
              <ActivityIndicator size="small" color={Constants.manifest.extra.colors.secondary.main} />
            </View>
          );
        }}
      >
        <TopTab.Screen
          name="Tradies"
          children={(props) => <RenderList loading={loading} docs={tradiesDocs} onPressGoToProfile={onPressGoToProfile} {...props} />}
        />
      </TopTab.Navigator>
    </>
  );
};

export default ProsScreen;

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  card: {
    margin: 2,
    marginBottom: 10,
    flexShrink: 1,
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  footerControl: {
    marginHorizontal: 2,
  },

  profileAvatar: {
    height: 124,
  },
  avatar: {
    aspectRatio: 1.0,
    height: 124,
    alignSelf: "center",
    borderColor: "lightgray",
    borderWidth: 1,
    resizeMode: "contain",
    overflow: "hidden",
    borderRadius: 10,
    width: 120,
  },

  editAvatarButton: {
    borderRadius: 5,
    position: "absolute",
    bottom: 5,
    right: 5,
    padding: 3,
    backgroundColor: Constants.manifest.extra.colors.primary.main,
  },

  icon: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
});
