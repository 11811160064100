import React from "react";
import { StyleSheet, View, Image, Platform, Pressable, ScrollView } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { Button, Divider, TopNavigation, TopNavigationAction, Text, Spinner, useStyleSheet, StyleService } from "@ui-kitten/components";
import Form from "../components/Forms/Form";
import BaseText from "../components/Typography/BaseText";
import WithFormikCheckbox from "../components/Checkboxes/WithFormikCheckbox";
import WithFormikInput from "../components/Inputs/WithFormikInput";
import WithFormikButton from "../components/Buttons/WithFormikButton";
import { addNewProductSchema, createStoreSchema } from "../utils/schemas";
import { ArrowIosBackIcon } from "../components/icons";
import FormErrorMessage from "../components/Forms/FormErrorMessage";
import * as ImagePicker from "expo-image-picker";
import { useFormikContext } from "formik";
import { addDocToCollection, createNewDocInCollection, firestoreUtils, storageUtils } from "../components/Firebase/firebase";
import { ProfileAvatar } from "../components/profile/extra/profile-avatar.component";
import { ProfileSetting } from "../components/profile/extra/profile-setting.component";
import ViewStoreProducts from "../components/view-products";
import * as ImageManipulator from "expo-image-manipulator";
import ImagePIckerExample from "../components/Misc/ImagePIckerExample";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

const LoadingIndicator = (props) => (
  <View style={[props.style, styles.indicator]}>
    <Spinner size="small" />
  </View>
);

const Stack = createStackNavigator();

const StoreDetails = ({ navigation, storeData, route }) => {
  const styles = useStyleSheet(themedStyle);
  const storeDetails = storeData;

  return (
    <ScrollView style={styles.storeDetailsContainer} contentContainerStyle={styles.contentContainer}>
      <ProfileAvatar
        style={styles.profileAvatar}
        source={{ uri: storeDetails?.storeLogoURL || null }}
        // editButton={renderPhotoButton}
      />
      <ProfileSetting style={[styles.profileSetting]} hint="Store Name" value={storeDetails?.storeName} />
      <ProfileSetting style={[styles.profileSetting]} hint="Phone Number" value={storeDetails?.storePhoneNumber || "N/A"} />
      <ProfileSetting style={[styles.profileSetting]} hint="Whatsapp Number" value={storeDetails?.storeWhatsappNumber || "N/A"} />

      <ProfileSetting
        style={[styles.profileSetting, styles.section]}
        hint="Products"
        value={`${storeDetails?.products?.length || 0} product(s) listed`}
      />

      <View
        style={{
          paddingHorizontal: 10,
          paddingTop: 10,
        }}
      >
        {storeDetails?.products?.length > 0 ? (
          <Button
            style={{
              marginVertical: 5,
            }}
            onPress={() => {
              navigation?.navigate(`View Products`, { products: storeDetails?.products || [] });
            }}
          >
            View Products
          </Button>
        ) : (
          <></>
        )}
        <Button
          style={{
            marginVertical: 5,
          }}
          onPress={() => {
            navigation?.navigate(`Add New Product`);
          }}
        >
          Add New Product
        </Button>
      </View>
    </ScrollView>
  );
};

const AddNewProductForm = ({ navigation, userDetails }) => {
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);

  const addNewProduct = async (values, { resetForm }) => {
    // console.log(values);
    try {
      setProcessing(true);
      var storageRef = storageUtils.storage().ref();
      var metadata = {
        contentType: "image/jpeg",
      };

      const response = await fetch(values?.image);
      const blob = await response.blob();

      var uploadTask = storageRef.child(`stores/products/images/${values?.name}_${new Date().toUTCString()}.jpg`).put(blob, metadata);

      uploadTask.on(
        "state_changed",
        () => {},
        (err) => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            try {
              const data = {
                productName: values?.name,
                productImage: downloadURL,
                productPrice: values?.price,
                productNotes: values?.notes,
                productAdded: firestoreUtils.firestore.Timestamp.now(),
              };

              // console.log(data);

              await firestoreUtils.firestore().collection(`stores`).doc(userDetails?.storeID).collection("products").add(data);
              resetForm();
            } catch (error) {
              console.log(error);
            } finally {
              setProcessing(false);
            }
          });
        }
      );
    } catch (error) {
      console.log(error);
      setProcessing(false);
    }
  };

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{ paddingBottom: 10 }}>
      <Form
        initialValues={{
          name: "",
          image: "",
          price: "",
          notes: "",
        }}
        validationSchema={addNewProductSchema}
        onSubmit={async (values, formikBag) => {
          // console.log(values);
          await addNewProduct(values, formikBag);
        }}
      >
        <BaseText text="Product Image" category="label" style={{ textAlign: "center", width: "100%" }} />
        <ImagePIckerExample name="image" />

        <BaseText text="Product Name" category="label" />
        <WithFormikInput name="name" placeholder="Enter product name" autoCorrect={false} />

        <BaseText text="Price" category="label" />
        <WithFormikInput name="price" placeholder="Enter product price" autoCorrect={false} keyboardType="number-pad" />

        <BaseText text="Notes" category="label" />
        <WithFormikInput
          name="notes"
          placeholder="Enter some notes about product"
          autoCorrect={false}
          multiline={true}
          textStyle={{ minHeight: 100 }}
        />

        <View style={{ marginTop: 10 }}>
          <WithFormikButton
            disabled={processing}
            text={"Add Product"}
            accessoryLeft={processing ? LoadingIndicator : null}
            appearance={processing ? "outline" : "filled"}
          />
        </View>
        {<FormErrorMessage error={loginError} visible={true} />}
      </Form>
    </ScrollView>
  );
};

const ViewProducts = ({ navigation, route, userDetails }) => {
  const { products } = route.params;
  return (
    <>
      <ViewStoreProducts navigation={navigation} products={products || []} />
    </>
  );
};

function ManageStoreScreen({ navigation, route, userDetails }) {
  return (
    <>
      <Stack.Navigator
        initialRouteName={"Store Details"}
        screenOptions={({ route }) => ({
          cardStyle: {
            flex: 1,
          },
          headerTopInsetEnabled: false,
          headerTitleStyle: {
            fontFamily: "Signika_400Regular",
          },
        })}
      ></Stack.Navigator>
    </>
  );
}

export default ManageStoreScreen;

const themedStyle = StyleService.create({
  storeDetailsContainer: {
    flex: 1,
    backgroundColor: "background-basic-color-2",
  },
  contentContainer: {
    paddingVertical: 24,
    flexGrow: 1,
  },
  profileAvatar: {
    aspectRatio: 1.0,
    height: 124,
    alignSelf: "center",
    marginBottom: 10,
  },
  editAvatarButton: {
    aspectRatio: 1.0,
    height: 48,
    borderRadius: 24,
  },
  profileSetting: {
    padding: 16,
  },
  section: {
    marginTop: 24,
  },
  doneButton: {
    marginHorizontal: 24,
    marginTop: 24,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15,
    height: "100%",
  },
  indicator: {
    justifyContent: "center",
    alignItems: "center",
  },
});
