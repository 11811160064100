import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "@ui-kitten/components";

const BaseText = ({
  containerStyles,
  text,
  category = "h1",
  textStyles,
  ...textProps
}) => (
  <View style={[styles.container, containerStyles]}>
    <Text style={[styles.text, textStyles]} category={category} {...textProps}>
      {text}
    </Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    margin: 2,
  },
});

export default BaseText;
