import React from "react";
import { StyleSheet, View, Dimensions, ImageBackground, ScrollView, Pressable, Alert, Platform } from "react-native";
import { Divider, Layout, Text, LayoutProps, Button, Spinner } from "@ui-kitten/components";
import { EditIcon, UploadingIcon } from "./icons";
import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import { firestoreUtils, storageUtils, updateUserDetails } from "../../Firebase/firebase";
import { URL, URLSearchParams } from "react-native-url-polyfill";

export const WorkSamples = (props) => {
  const { width } = Dimensions.get("window");
  const { hint, value, imageStyle, layoutStyle, showEdit, editAction, userID, navigation, ...layoutProps } = props;

  const [processing, setProcessing] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);

  const onPressDeleteWorkSample = async (imageURL, imagesList) => {
    try {
      const url = new URL(imageURL);
      const decoded = decodeURIComponent(url.pathname);
      const path = decoded.substring(decoded.indexOf("/tradies/images/"));
      await storageUtils.storage().ref().child(path).delete();

      const filtered = imagesList.filter((image) => image !== imageURL);

      await updateUserDetails(userID, {
        workSamples: filtered,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const formatImage = async (image) => {
    try {
      setProcessing(true);
      var storageRef = storageUtils.storage().ref();
      var metadata = {
        contentType: "image/jpeg",
      };

      const response = await fetch(image);
      const blob = await response.blob();

      const storagePath = `tradies/images`;
      var uploadTask = storageRef.child(`${storagePath}/${Date.now()}_${userID}.jpg`).put(blob, metadata);
      uploadTask.on(
        "state_changed",
        () => {},
        (err) => {
          setProcessing(false);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            setProcessing(true);
            await updateUserDetails(userID, {
              workSamples: firestoreUtils.firestore.FieldValue.arrayUnion(downloadURL),
            });
            setProcessing(false);
          });
        }
      );
    } catch (error) {
      console.log(error);
      setProcessing(false);
    }
  };

  const pickImage = async () => {
    try {
      setProcessing(true);
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
        allowsMultipleSelection: true,
      });

      if (!result?.cancelled) {
        const file = await ImageManipulator.manipulateAsync(
          result?.uri,
          [],
          // [{ resize: { width: 1000 } }],
          {
            compress: 0.1,
            format: ImageManipulator.SaveFormat.JPEG,
          }
        );

        await formatImage(file?.uri);
      } else {
        setProcessing(false);
      }
    } catch (error) {
      console.log(error);
      setProcessing(false);
    }
  };

  return (
    <React.Fragment>
      <Layout level="1" {...layoutProps} style={[styles.container, layoutStyle]}>
        {showEdit ? (
          <View style={{ position: "absolute", top: 0, right: 0 }}>
            {value?.length < 4 ? (
              <Button
                // disabled={processing}
                onPress={
                  processing
                    ? () => {}
                    : () => {
                        editAction();
                        pickImage();
                      }
                }
                style={styles.editAvatarButton}
                status={processing ? "info" : "primary"}
                accessoryLeft={processing ? UploadingIcon : EditIcon}
              />
            ) : (
              <Text status="danger" style={{ textAlign: "right", paddingTop: 5, paddingRight: 5 }}>
                Max. reached.
              </Text>
            )}
          </View>
        ) : (
          <></>
        )}
        <Text appearance="hint" category="s1">
          {hint}
        </Text>

        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          horizontal
          showsHorizontalScrollIndicator={false}
          style={{ flexDirection: "row", width: width - 20, marginTop: 10, paddingHorizontal: 10 }}
        >
          {value?.length > 0 ? (
            <>
              {value?.map((image) => {
                return (
                  <Pressable
                    key={image}
                    onPress={() => {
                      showEdit
                        ? Alert.alert(
                            "Delete Work Sample",
                            "Do you want to delete image?",
                            [
                              {
                                text: "No",
                                style: "cancel",
                              },
                              { text: "Yes", onPress: () => onPressDeleteWorkSample(image, value) },
                            ],
                            { cancelable: false }
                          )
                        : navigation.navigate("View Image", { image });
                    }}
                  >
                    <ImageBackground key={image} source={{ uri: image }} style={[styles.avatar]} />
                  </Pressable>
                );
              })}
            </>
          ) : (
            <Text
              category="p1"
              style={{
                width: width - 20,
                textAlign: "center",
              }}
            >
              N/A
            </Text>
          )}
        </ScrollView>
      </Layout>
      <Divider />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
    borderColor: "#eee",
    borderWidth: 1,
    // elevation: 1,
  },
  editAvatarButton: {
    aspectRatio: 1.0,
    height: 2,
  },

  avatar: {
    resizeMode: "contain",
    overflow: "hidden",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "lightgray",
    width: 100,
    marginRight: 5,
    // aspectRatio: 1.0,
    height: 100,
    // height: 100,
  },
});
