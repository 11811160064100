import React from "react";
import { StyleSheet, View, ScrollView, Image, KeyboardAvoidingView } from "react-native";
import {
  Button,
  Spinner,
  Divider,
  TopNavigation,
  TopNavigationAction,
  Datepicker,
  Layout,
  Text,
  Card,
  Modal,
  IndexPath,
  Select,
  SelectItem,
  Menu,
  MenuGroup,
  MenuItem,
} from "@ui-kitten/components";
import { tradieBusinessNameSchema, tradiePaymentMethodSchema } from "../../../utils/schemas";
import LoadingIndicator from "../../Misc/LoadingIndicator";

import Form from "../../Forms/Form";
import FormErrorMessage from "../../Forms/FormErrorMessage";
import WithFormikInput from "../../Inputs/WithFormikInput";
import WithFormikButton from "../../Buttons/WithFormikButton";
import BaseText from "../../Typography/BaseText";
import ImagePIckerExample from "../../Misc/ImagePIckerExample";
import { firebaseUtils, storageUtils, updateUserDetails } from "../../Firebase/firebase";
import { cachedNetworkCodes } from "../../../utils";
import { useFormikContext } from "formik";

const PaymentNetworkCodes = ({ name = "mobileMoneyAccountCode", networkCodes }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  //   console.log(values[name]);
  const found = networkCodes.find((networkCode) => networkCode?.value === values[name]);
  const indexOf = networkCodes.indexOf(found);
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(indexOf));

  const displayValue = networkCodes[selectedIndex.row]?.label || "Select Network Code";
  // console.log(displayValue);

  return (
    <View style={{ borderColor: "#eee", borderWidth: 1, borderRadius: 9 }}>
      <Menu
        selectedIndex={selectedIndex}
        value={displayValue}
        onSelect={(index) => {
          // console.log(index);
          setFieldValue(name, networkCodes[index?.row]?.value);
          setSelectedIndex(index);
        }}
      >
        {/* <MenuGroup title="Select Category"> */}
        <MenuGroup title={displayValue}>
          {networkCodes?.map((networkCode) => {
            return <MenuItem key={networkCode?.label} title={networkCode?.label} />;
          })}
        </MenuGroup>
      </Menu>
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
    </View>
  );
};

const EditPaymentMethod = ({ userDetails, setVisible }) => {
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [networkCodes, setNetworkCodes] = React.useState(cachedNetworkCodes);

  // console.log(networkCodes);

  React.useEffect(() => {
    const getCodes = async () => {
      const getKorbaNetworkCodes = firebaseUtils.firebase.functions().httpsCallable("getKorbaNetworkCodes");
      let { data } = await getKorbaNetworkCodes();

      const newData = data.map((item) => item.code !== "CRD" && { label: item.name, value: item.code }).filter((item) => Boolean(item));
      setNetworkCodes(newData?.length > 0 ? newData : cachedNetworkCodes);
    };

    getCodes();
  }, []);

  const onPressSavePaymentMethod = async (values) => {
    try {
      setProcessing(true);
      await updateUserDetails(userDetails?.userID, { paymentMethods: values });
      setProcessing(false);
      setVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <KeyboardAvoidingView contentContainerStyle={[styles.container]}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false} keyboardShouldPersistTaps="always">
        <Form
          initialValues={{
            paymentAccountName: userDetails?.paymentMethods?.paymentAccountName || "",
            mobileMoneyAccountCode: userDetails?.paymentMethods?.mobileMoneyAccountCode || "",
            mobileMoneyNumber: userDetails?.paymentMethods?.mobileMoneyNumber || "",
          }}
          validationSchema={tradiePaymentMethodSchema}
          onSubmit={async (values, formikBag) => {
            // console.log(values);
            await onPressSavePaymentMethod(values);
          }}
        >
          <BaseText text="Payment Name" category="label" />
          <WithFormikInput name="paymentAccountName" placeholder="Enter your payment name" autoCapitalize="none" autoCorrect={false} />

          <BaseText text="Payment Number" category="label" />
          <WithFormikInput
            name="mobileMoneyNumber"
            placeholder="Enter your payment number"
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="number-pad"
          />

          <BaseText text="Payment Network Code" category="label" />
          <PaymentNetworkCodes networkCodes={networkCodes} />

          <View style={{ marginTop: 10 }}>
            <WithFormikButton
              disabled={processing || loading}
              text={"Save"}
              accessoryLeft={processing ? LoadingIndicator : null}
              appearance={processing ? "outline" : "filled"}
            />

            <Button
              onPress={() => {
                setVisible(false);
              }}
              style={{ marginVertical: 5 }}
              disabled={processing || loading}
              status="warning"
              appearance={"outline"}
            >
              Close
            </Button>
          </View>
          {<FormErrorMessage error={loginError} visible={true} />}
        </Form>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default EditPaymentMethod;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
