import React from "react";
import { Dimensions, View, ScrollView, ImageBackground, TouchableOpacity, Pressable } from "react-native";
// import { firstHalf, secondHalf } from "./MoreScreenItems";
import { createStackNavigator } from "@react-navigation/stack";
import { Divider, Text, Button, Icon } from "@ui-kitten/components";

import { getCurrentUser, getUserDetails, logout } from "../components/Firebase/firebase";

import { checkForUpdates } from "../utils";
import { useNofitication } from "../ctx/NotificationCtx";
import Constants from "expo-constants";

const firstHalf = (user, navigation) => [
  {
    icon: "person-outline",
    label: "Profile Details",
    hidden: false,
    onPress: () => {
      if (!user?.profileCompletion || user?.profileCompletion !== 100) return navigation.navigate(`ExtraNav`, { screen: "Edit Profile" });
      navigation?.navigate(`ExtraNav`, { screen: "Profile" });
    },
  },
  {
    icon: "people-outline",
    label: "Tradie Account",
    hidden: !user?.isTradie,
    onPress: () => {
      if (!user?.profileCompletion || user?.profileCompletion !== 100) return navigation.navigate("Edit Profile");
      navigation?.navigate(`ExtraNav`, { screen: "Tradie Account", params: { userID: user?.userID } });
    },
  },
  // {
  //   icon: "home-outline",
  //   label: "Manage Store",
  //   hidden: !user?.profileCompletion || user?.profileCompletion !== 100,
  //   onPress: () => navigation?.navigate(`ExtraNav`, { screen: "Manage Store" }),
  // },
  {
    icon: "credit-card",
    label: "Payment History",
    hidden: false,
    onPress: () => navigation?.navigate(`ExtraNav`, { screen: "PaymentHistory" }),
  },
  {
    icon: "bell",
    label: "Notifications",
    hidden: false,
    onPress: () => navigation?.navigate(`ExtraNav`, { screen: "Notifications" }),
  },
  {
    icon: "settings",
    label: "Notification Settings",
    hidden: !user?.profileCompletion || user?.profileCompletion !== 100,
    onPress: () => navigation?.navigate(`ExtraNav`, { screen: "Notification Settings" }),
  },
  {
    icon: "award-outline",
    label: "Promos",
    hidden: false,
    onPress: () => navigation?.navigate(`ExtraNav`, { screen: "Promos" }),
  },
];

const secondHalf = (user, navigation, noticationActions) => [
  // {
  //   icon: "info",
  //   label: "FAQs",
  //   hidden: false,
  //   onPress: () => {},
  // },
  {
    icon: "headphones-outline",
    label: "Contact Us",
    hidden: false,
    onPress: () => navigation?.navigate(`ExtraNav`, { screen: "Contact Us" }),
  },
  {
    icon: "arrow-downward-outline",
    label: "Check for Updates",
    hidden: false,
    onPress: () => checkForUpdates(null, noticationActions, null, false),
  },
  {
    icon: "log-out",
    label: "Logout",
    hidden: false,
    onPress: logout,
  },
];

function ProfileHeader({ photoURL = null, firstName = "", lastName = "", email = "" }) {
  const { width: windowWidth } = Dimensions.get("window");
  return (
    <>
      {/* <TouchableOpacity pressable={false}>
        <View style={{ position: "relative" }}>
          <ImageBackground
            isBackground={true}
            style={[
              {
                resizeMode: "contain",
                overflow: "hidden",
                paddingBottom: 10,
                width: windowWidth,
                height: 150,
              },
            ]}
            source={{
              uri: `https://images.unsplash.com/photo-1627662168781-4345690f0bb3?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80`,
            }}
          />
        </View>
      </TouchableOpacity> */}

      <View
        style={{
          marginTop: 40,
          borderRadius: 30,
          width: "100%",
          marginBottom: 4,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // marginTop: -30,
          }}
        >
          <TouchableOpacity pressable={false}>
            <View style={{ position: "relative" }}>
              <ImageBackground
                style={[
                  {
                    resizeMode: "contain",
                    overflow: "hidden",
                    borderRadius: 16,
                    borderWidth: 3,
                    borderColor: "white",
                    width: 100,
                    height: 100,
                    padding: 50,
                  },
                ]}
                source={{
                  uri: photoURL,
                }}
              />
            </View>
          </TouchableOpacity>

          <Text
            style={{
              textAlign: "center",
              color: "#222B45",

              marginVertical: 0,
            }}
          >
            {firstName} {lastName}
          </Text>

          <Text
            style={{
              textAlign: "center",
              color: "#8F9BB3",
              marginVertical: 0,
            }}
          >
            {email}
          </Text>
        </View>
      </View>
    </>
  );
}

const MenuItem = ({ e, index, length }) => {
  const LeftIcon = (props) => (
    <View
      style={{
        width: 35,
        height: 35,
        padding: 10,
        borderRadius: 15,
        marginRight: 15,
        backgroundColor:
          e?.label === "Logout"
            ? "red"
            : e?.label.includes("Environment")
            ? Constants.manifest.extra.colors.secondary.main
            : Constants.manifest.extra.colors.primary.main,
      }}
    >
      <Icon {...props} name={e?.icon} fill="#fff" style={{ width: 15, height: 15 }} />
    </View>
  );
  const RightIcon = (props) => (
    <View>
      <Icon {...props} name={`chevron-right-outline`} fill="#000" style={{ width: 15, height: 15 }} />
    </View>
  );

  return (
    <Pressable key={e.label} onPress={e?.onPress}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: 20,
          paddingVertical: 10,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <LeftIcon />
          <Text
            style={{
              color: "#000",
            }}
          >
            {e.label}
          </Text>
        </View>
        <View style={{}}>
          <RightIcon />
        </View>
      </View>

      {index !== length - 1 && <Divider />}
    </Pressable>
  );
};

const MoreScreenMain = ({ userDetails, navigation }) => {
  const noticationActions = useNofitication();
  const itemsFirstHalf = firstHalf(userDetails, navigation, noticationActions);
  const itemsSecondHalf = secondHalf(userDetails, navigation, noticationActions);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{ paddingVertical: 0 }} showsVerticalScrollIndicator={false}>
      <ProfileHeader
        firstName={userDetails?.firstName || "New"}
        lastName={userDetails?.lastName || "User"}
        email={userDetails?.email || ""}
        photoURL={userDetails?.photoURL || null}
      />

      <View
        style={{
          padding: 4,
          paddingHorizontal: 5,
        }}
      >
        <View style={{ backgroundColor: "white", borderRadius: 15, elevation: 1 }}>
          {itemsFirstHalf
            .filter((e) => !e?.hidden)
            .map((e, index) => {
              return <MenuItem key={e.label} e={e} index={index} length={itemsFirstHalf?.length} />;
            })}
        </View>

        <View style={{ height: 20 }} />

        <View style={{ backgroundColor: "white", borderRadius: 15, elevation: 1 }}>
          {itemsSecondHalf
            .filter((e) => !e?.hidden)
            .map((e, index) => {
              return <MenuItem key={e.label} e={e} index={index} length={itemsSecondHalf?.length} />;
            })}
        </View>
      </View>
    </ScrollView>
  );
};

function MoreScreen(props) {
  const user = getCurrentUser();
  const [userDetails, setUserDetails] = React.useState(null);

  React.useEffect(() => {
    let subscribe;
    (async () => {
      getUserDetails(user?.uid, setUserDetails);
    })();

    return () => {
      subscribe && subscribe();
    };
  }, [user]);

  return <MoreScreenMain userDetails={userDetails} {...props} />;
}

export default MoreScreen;
