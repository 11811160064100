import React from "react";
import { Alert, LogBox, SafeAreaView } from "react-native";
import Providers from "./navigation";
import { requestTrackingPermissionsAsync } from "expo-tracking-transparency";
import Constants from "expo-constants";
import useCachedResources from "./utils/useCachedResources";

import AppLoading from "expo-app-loading";

// LogBox.ignoreLogs([
//   "Setting a timer",
//   "VirtualizedLists",
//   // "Exception",
//   // "currentlyFocusedField",
//   `Looks like you're passing an inline function for 'component' prop for the screen 'Tradie Account`,
//   "It appears that you are using old version of react-navigation library.",
// ]);

function LoadingApp({ children }) {
  const isLoadingComplete = useCachedResources();
  return isLoadingComplete ? children : <AppLoading />;
}

export default function App() {
  React.useEffect(() => {
    (async () => {
      const { status } = await requestTrackingPermissionsAsync();
      if (status === "granted") {
        // console.log('Yay! I have user permission to track data')
      }
    })();
  }, []);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "white", paddingTop: Constants.statusBarHeight }}>
      <LoadingApp>
        <Providers />
      </LoadingApp>
    </SafeAreaView>
  );
}
