import React from "react";
import { StyleSheet } from "react-native";
import { Button, Layout } from "@ui-kitten/components";

const BaseButton = ({
  text,
  containerStyles = {},
  containerProps = { level: "1" },
  ...buttonProps
}) => {
  return (
    <Layout style={(styles.container, containerStyles)} {...containerProps}>
      <Button {...buttonProps}>{text}</Button>
    </Layout>
  );
};

export default BaseButton;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    marginHorizontal: 8,
  },
});
