import React from "react";
import { StyleSheet, View, ScrollView, Image, KeyboardAvoidingView } from "react-native";
import {
  Button,
  Spinner,
  Divider,
  TopNavigation,
  TopNavigationAction,
  Datepicker,
  Layout,
  Text,
  Card,
  Modal,
  IndexPath,
  Select,
  SelectItem,
  Menu,
  MenuGroup,
  MenuItem,
} from "@ui-kitten/components";
import { tradieBusinessNameSchema } from "../../../utils/schemas";
import LoadingIndicator from "../../Misc/LoadingIndicator";

import Form from "../../Forms/Form";
import FormErrorMessage from "../../Forms/FormErrorMessage";
import WithFormikInput from "../../Inputs/WithFormikInput";
import WithFormikButton from "../../Buttons/WithFormikButton";
import BaseText from "../../Typography/BaseText";
import ImagePIckerExample from "../../Misc/ImagePIckerExample";
import { storageUtils, updateUserDetails } from "../../Firebase/firebase";

const EditBusinessNameAndImage = ({ userDetails, setVisible }) => {
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const formatImage = async (image, businessName, userID, onPressSaveBusinessName) => {
    try {
      setProcessing(true);
      if (image.includes("firebasestorage.googleapis.com") || !image) {
        await onPressSaveBusinessName(image, businessName);
        return setProcessing(false);
      }

      var storageRef = storageUtils.storage().ref();
      var metadata = {
        contentType: "image/jpeg",
      };

      const response = await fetch(image);
      const blob = await response.blob();

      const storagePath = `tradies/images`;
      var uploadTask = storageRef.child(`${storagePath}/${userID}.jpg`).put(blob, metadata);

      uploadTask.on(
        "state_changed",
        () => {},
        (err) => {
          setProcessing(false);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await onPressSaveBusinessName(downloadURL, businessName);
          });
        }
      );

      return await uploadTask.snapshot.ref.getDownloadURL();
    } catch (error) {
      console.log(error);
      setProcessing(false);
    }
  };

  const onPressSaveBusinessName = async (businessImage, businessName) => {
    try {
      setProcessing(true);
      const data = {
        businessName,
        businessImage,
      };

      await updateUserDetails(userDetails?.userID, data);
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
      setVisible(false);
    }
  };

  return (
    <KeyboardAvoidingView contentContainerStyle={[styles.container]}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false} keyboardShouldPersistTaps="always">
        <Form
          initialValues={{
            businessImage: userDetails?.businessImage ? userDetails?.businessImage[0] : null,
            businessName: userDetails?.businessName || null,
          }}
          validationSchema={tradieBusinessNameSchema}
          onSubmit={async ({ businessImage, businessName }, formikBag) => {
            await formatImage(businessImage, businessName, userDetails?.userID, onPressSaveBusinessName);
          }}
        >
          <BaseText text="Business Image" category="label" style={{ textAlign: "center" }} />
          <ImagePIckerExample name="businessImage" setLoading={setLoading} />

          <BaseText text="Business Name" category="label" />
          <WithFormikInput name="businessName" placeholder="Enter your business name" autoCapitalize="none" autoCorrect={false} />

          <View style={{ marginTop: 10 }}>
            <WithFormikButton
              disabled={processing || loading}
              text={"Save"}
              accessoryLeft={processing ? LoadingIndicator : null}
              appearance={processing ? "outline" : "filled"}
            />

            <Button
              onPress={() => {
                setVisible(false);
              }}
              style={{ marginVertical: 5 }}
              disabled={processing || loading}
              status="warning"
              appearance={"outline"}
            >
              Close
            </Button>
          </View>
          {<FormErrorMessage error={loginError} visible={true} />}
        </Form>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default EditBusinessNameAndImage;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
