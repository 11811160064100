import React from "react";
import { StyleSheet, View, ViewProps, ImageBackground } from "react-native";
import { Avatar, AvatarProps, ButtonElement, ButtonProps } from "@ui-kitten/components";

export const ProfileAvatar = (props) => {
  const renderEditButtonElement = () => {
    const buttonElement = props.editButton();

    return React.cloneElement(buttonElement, {
      style: [buttonElement.props.style, styles.editButton],
    });
  };

  const { style, editButton, showEdit, ...restProps } = props;

  return (
    <View style={style}>
      <ImageBackground {...restProps} style={[style, styles.avatar]} />
      {editButton && showEdit && renderEditButtonElement()}
    </View>
  );
};

const styles = StyleSheet.create({
  avatar: {
    alignSelf: "center",
    resizeMode: "contain",
    overflow: "hidden",
    borderRadius: 10,
    borderWidth: 3,
    borderColor: "white",
    width: 120,
    // height: 100,
  },
  editButton: {
    position: "absolute",
    alignSelf: "flex-end",
    bottom: 0,
  },
});
