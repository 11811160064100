import Constants from "expo-constants";
import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { Divider, Text, Button, Icon } from "@ui-kitten/components";

export default function Logo(props) {
  const sandbox = require("../../assets/icon_sandbox.png");
  const prod = require("../../assets/icon.png");

  return (
    <View style={styles.logoContainer}>
      <Image source={Constants.manifest.extra.env === "prod" ? prod : sandbox} style={styles.logo} />
      <Text style={styles.subtitle}>HT Shop</Text>
      <Text category="p1" appearance="hint">
        We Fix It!
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  logoContainer: {
    alignItems: "center",
  },
  logo: {
    width: 125,
    height: 125,
    borderRadius: 10,
  },
  subtitle: {
    fontSize: 24,
    fontWeight: "600",
    paddingTop: 20,
    paddingBottom: 0,
    color: `black`,
  },
});
