import React from "react";
import { StyleSheet, View } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";

import ShowStores from "../components/shop/ShowStores";
import ShowProducts from "../components/shop/ShowProducts";

const Stack = createStackNavigator();

function ShopScreen() {
  return (
    <Stack.Navigator
      screenOptions={({ route }) => ({
        cardStyle: {
          flex: 1,
        },
        headerTopInsetEnabled: false,
        headerTitleStyle: {
          fontFamily: "Signika_400Regular",
        },
      })}
    >
      <Stack.Screen name="ShowStores" component={ShowStores} options={{ headerShown: false }} />
      <Stack.Screen
        name="Show Products"
        component={ShowProducts}
        options={({ route }) => ({ title: route?.params?.title || "Show Products", headerBackTitleVisible: false })}
      />
    </Stack.Navigator>
  );
}

export default ShopScreen;

const styles = StyleSheet.create({});
