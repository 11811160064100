import { ImageSourcePropType } from "react-native";

export class Profile {
  constructor({
    firstName,
    lastName,
    photoURL,
    gender,
    dateOfBirth,
    email,
    bio,
    tradieSkills,
    address,
    businessName,
    businessImage,
    businessInfo,
    paymentMethods,
    certifications,
    workSamples,
    userCertifications,
    userID,
    identityVerified,
  }) {
    this.userID = userID;
    this.firstName = firstName || "New";
    this.lastName = lastName || "User";
    this.gender = gender;
    this.photo = photoURL;
    this.dateOfBirth = dateOfBirth.toDate();
    this.bio = bio;
    this.tradieSkills = tradieSkills || [];
    this.address = address;
    this.email = email;
    this.businessName = businessName;
    this.businessImage = businessImage ? businessImage[0] : this.photo || "";
    this.paymentNetwork = paymentMethods?.mobileMoneyAccountCode || "N/A";
    this.paymentNumber = paymentMethods?.mobileMoneyNumber || `N/A`;
    this.paymentName = paymentMethods?.paymentAccountName || "N/A";
    this.businessInfo = businessInfo;
    this.certifications = certifications || [];
    this.workSamples = workSamples || [];
    this.userCertifications = userCertifications || [];
    this.identityVerified = identityVerified || false;
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get tradieAccountName() {
    return `${this.businessName || this.fullName}`;
  }

  get mobilePaymentMethod() {
    const networks = {
      VOD: "VODAFONE",
      MTN: "MTN",
      AIR: "AIRTEL",
      TIG: "TIGO",
    };

    return {
      code: networks[this.paymentNetwork] || this.paymentNetwork,
      number: this.paymentNumber,
      name: this.paymentName,
    };
  }

  get genderName() {
    const genders = {
      1: "Male",
      2: "Female",
      3: "Unspecified",
    };

    return genders[this.gender];
  }

  get age() {
    var today = new Date();
    var birthDate = new Date(this.dateOfBirth);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  get allTradieSkills() {
    return !this.tradieSkills
      ? `No skills listed`
      : this.tradieSkills && this.tradieSkills.length > 0
      ? `${this.tradieSkills.join(" | ")}`
      : `No skills listed`;
  }

  get certifiedMemberOf() {
    const stringify = this.certifications
      .filter((certification) => certification?.certifiedMemberVerified)
      ?.map((certification, index) => {
        return `${certification?.certifiedMemberOf}`;
      });

    return stringify.join("\n");
  }

  get memberShips() {
    const stringify = this.certifications?.map((certification, index) => {
      return `${certification?.certifiedMemberOf} ${"\t\t\t"} ${
        certification?.certifiedMemberVerified
          ? "Approved"
          : !certification?.certifiedMemberVerified && certification?.certifiedMemberVerificationNote
          ? /*certification?.certifiedMemberVerificationNote */ "Pending"
          : "Pending"
      }`;
    });

    return stringify.join("\n");
  }

  get certificates() {
    const stringify = this.userCertifications?.map((certification, index) => {
      return `${certification?.certificateName}`;
    });

    return stringify.join("\n");
  }
}
