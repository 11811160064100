import React from "react";
import { StyleSheet, Text, View } from "react-native";
import BaseInput from "./BaseInput";

import { useFormikContext } from "formik";
import FormErrorMessage from "../Forms/FormErrorMessage";

const WithFormikInput = ({ containerStyles, name, ...inputProps }) => {
  const { setFieldTouched, setFieldValue, values, errors, touched } =
    useFormikContext();
  return (
    <View style={[styles.container, containerStyles]}>
      <BaseInput
        setValue={(text) => setFieldValue(name, text)}
        value={values[name]}
        {...inputProps}
      />
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
    </View>
  );
};

export default WithFormikInput;

const styles = StyleSheet.create({
  container: {
    marginVertical: 5,
  },
});
