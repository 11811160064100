export default {
  primary: "#f57c00",
  secondary: "#039be5",
  black: "#222222",
  white: "#ffffff",
  ghostWhite: "#f8f8ff",
  lightGrey: "#f9f9f9",
  mediumGrey: "#6e6869",
  red: "#fc5c65",
  loginBg: "#eee", 
};
