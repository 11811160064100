import React from "react";
import { StyleSheet, View, FlatList, ScrollView } from "react-native";
import BaseButton from "../../components/Buttons/BaseButton";
import { getAllStoreProducts, getAllStores } from "../../components/Firebase/firebase";
import { Button, Card, Layout, Text, Avatar } from "@ui-kitten/components";
import { SafeAreaLayout } from "../../components/safe-area-layout.component.jsx";

const RenderItem = ({ item, navigation }) => {
  // console.log(item?.products?.length);
  return (
    <Card
      style={{ ...styles.card }}
      onPress={() => {
        item?.products?.length > 0 && navigation.navigate(`Show Products`, { title: `${item?.storeName}`, storeData: item });
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <View style={{ marginRight: 5 }}>
          <Avatar source={{ uri: item?.storeLogoURL }} size="giant" />
        </View>
        <View style={{ flex: 1 }}>
          <Text category="h6">{item?.storeName}</Text>
          <View style={{ marginBottom: 10 }}>
            <Text category="label" style={{}}>
              {item?.storeDetails}
            </Text>
          </View>
          <Text category="label">Contact: {item?.storePhoneNumber || "N/A"}</Text>
          <Text category="label">Whatsapp: {item?.storeWhatsappNumber || "N/A"}</Text>
          <View style={{ marginTop: 10, flexDirection: "row", justifyContent: "flex-end" }}>
            <Text category="label" style={{}}>
              {`${item?.products?.length || 0} product(s) listed`}
            </Text>
          </View>
        </View>
      </View>
    </Card>
  );
};

const ShowStores = ({ navigation, route }) => {
  const [docs, setDocs] = React.useState([]);

  // console.log(docs);

  React.useEffect(() => {
    let observer;
    const query = getAllStores();

    observer = query.onSnapshot(
      (querySnapshot) => {
        // console.log(`Received query snapshot of size ${querySnapshot.size}`);
        const docs = [];
        querySnapshot.forEach((doc) => {
          const products = [];

          (async () => {
            const productSnapshot = await getAllStoreProducts(doc.data()?.storeID).get();
            productSnapshot?.forEach((prodDoc) => {
              products.push(prodDoc.data());
            });
            docs.push({ ...doc.data(), products });
            setDocs(docs);
          })();

          // docs.push({ ...doc.data(), products });
        });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer && observer();
    };
  }, []);

  return (
    <View style={styles.container} insets="top">
      <Text style={{ color: "black", textAlign: "center" }}>Stores</Text>
      <FlatList
        ListEmptyComponent={
          <View style={{ flex: 1, justifyContent: "center", alignItems: "center", height: "100%" }}>
            <Text category="h6" style={{ textAlign: "center" }}>
              No Stores Listed
            </Text>
          </View>
        }
        style={{
          height: "100%",
        }}
        showsVerticalScrollIndicator={false}
        data={docs}
        renderItem={({ item }) => <RenderItem item={item} navigation={navigation} />}
        keyExtractor={(item, index) => item.storeID + index}
      />
    </View>
  );
};

export default ShowStores;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
  card: {
    marginVertical: 5,
    elevation: 1,
  },
  footerControl: {
    marginHorizontal: 2,
  },
});
