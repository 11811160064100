import React from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { Divider, TopNavigation, TopNavigationAction, Layout, Text, Toggle } from "@ui-kitten/components";
import { SafeAreaLayout } from "../components/safe-area-layout.component.jsx";
import { ArrowIosBackIcon } from "../components/icons";
import { firestoreUtils } from "../components/Firebase/firebase";

const ToggleSimpleUsageShowcase = ({ valueForChecked = false, functionToRun = () => {} }) => {
  const [checked, setChecked] = React.useState(valueForChecked);

  React.useEffect(() => {
    setChecked(valueForChecked);
  }, [valueForChecked]);

  return <Toggle checked={checked} onChange={functionToRun} />;
};

const NotificationsSettingsScreen = ({ userDetails, navigation }) => {
  //   console.log(userDetails);
  const renderBackAction = () => <TopNavigationAction icon={ArrowIosBackIcon} onPress={navigation.goBack} />;

  const [categories, setCategories] = React.useState([]);

  const [nationalities, setNationalities] = React.useState([]);
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const taskCategoriesDoc = await firestoreUtils
        .firestore()
        .collection(`task_categories`)
        .where(`newCategory`, "!=", false)
        .orderBy(`newCategory`, "desc")
        .get();

      const taskCategoriesData = [];

      taskCategoriesDoc.forEach((doc) => {
        taskCategoriesData.push({ name: doc.data().name, index: doc.data().index, id: doc.data().id });
      });

      setCategories(taskCategoriesData);
    })();
  }, []);

  const muted = React.useMemo(() => userDetails?.accountSettings?.notifications?.muteTaskNotifications, [userDetails]);
  //   console.log(muted);

  const accountSettings = React.useMemo(
    () => ({
      notifications: {
        muteAllNotifications: false,
        muteTaskNotifications: [],
        receiveSMSNotifications: false,
      },
      ...(userDetails?.accountSettings || {}),
    }),
    [userDetails]
  );

  const handleMuteTaskNotification = async (categoryID = false) => {
    try {
      const muted = userDetails?.accountSettings?.notifications?.muteTaskNotifications || [];
      const accountSettings = userDetails?.accountSettings || {};
      if (muted.indexOf(categoryID) === -1) {
        accountSettings.notifications = {
          ...accountSettings?.notifications,
          muteTaskNotifications: [...muted, categoryID],
        };
      } else {
        const oldData = [...muted];
        const newData = [];

        oldData.forEach((item) => {
          if (item !== categoryID) newData.push(item);
        });

        accountSettings.notifications = {
          ...accountSettings?.notifications,
          muteTaskNotifications: newData,
        };
      }

      if (!accountSettings) throw accountSettings;

      await firestoreUtils.firestore().collection(`users`).doc(userDetails?.userID).update({
        accountSettings,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleReceiveSMSNotifications = async (status = true) => {
    try {
      const accountSettings = { ...userDetails?.accountSettings };
      accountSettings.notifications = {
        ...accountSettings?.notifications,
        receiveSMSNotifications: status,
      };

      if (!accountSettings) throw accountSettings;

      await firestoreUtils.firestore().collection(`users`).doc(userDetails?.userID).update({
        accountSettings,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleMuteNotifications = async (status = false) => {
    try {
      const accountSettings = { ...userDetails?.accountSettings };
      accountSettings.notifications = {
        ...accountSettings?.notifications,
        muteAllNotifications: status,
      };

      if (!accountSettings) throw accountSettings;

      await firestoreUtils.firestore().collection(`users`).doc(userDetails?.userID).update({
        accountSettings,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SafeAreaLayout style={styles.container} insets="top">
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false}>
        <Text category="s1" style={{ textAlign: "center", marginTop: 5 }}>
          Notification Options
        </Text>

        <View style={{ justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%", marginTop: 5 }}>
          <Text style={{}}>Receive SMS Notifications</Text>
          <ToggleSimpleUsageShowcase
            valueForChecked={accountSettings?.notifications?.receiveSMSNotifications}
            functionToRun={() => {
              handleReceiveSMSNotifications(!accountSettings?.notifications?.receiveSMSNotifications);
            }}
          />
        </View>

        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: 5 }}>
          <Text style={{}}>Mute ALL Notifications</Text>
          <ToggleSimpleUsageShowcase
            valueForChecked={accountSettings?.notifications?.muteAllNotifications}
            functionToRun={() => {
              handleMuteNotifications(!accountSettings?.notifications?.muteAllNotifications);
            }}
          />
        </View>

        <Text category="s1" style={{ textAlign: "center", marginTop: 20 }}>
          Task Notifications
        </Text>
        {categories.map((cb) => {
          return (
            <View
              key={cb.id}
              style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: 5 }}
            >
              <Text style={{}}>{cb.name}</Text>
              <ToggleSimpleUsageShowcase
                valueForChecked={muted.indexOf(cb.id) !== -1 || accountSettings?.notifications?.muteAllNotifications || false}
                functionToRun={() => {
                  handleMuteTaskNotification(cb.id);
                }}
              />
            </View>
          );
        })}
      </ScrollView>
    </SafeAreaLayout>
  );
};

export default NotificationsSettingsScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
});
