import React from "react";
import { StyleSheet, View, ScrollView, Image, KeyboardAvoidingView } from "react-native";
import {
  Button,
  Spinner,
  Divider,
  TopNavigation,
  TopNavigationAction,
  Datepicker,
  Layout,
  Text,
  Card,
  Modal,
  IndexPath,
  Select,
  SelectItem,
  Menu,
  MenuGroup,
  MenuItem,
} from "@ui-kitten/components";
import { certifiedMembership, tradieBusinessNameSchema } from "../../../utils/schemas";
import LoadingIndicator from "../../Misc/LoadingIndicator";

import Form from "../../Forms/Form";
import FormErrorMessage from "../../Forms/FormErrorMessage";
import WithFormikInput from "../../Inputs/WithFormikInput";
import WithFormikButton from "../../Buttons/WithFormikButton";
import BaseText from "../../Typography/BaseText";
import ImagePIckerExample from "../../Misc/ImagePIckerExample";
import { firestoreUtils, getAppUtils, storageUtils, updateUserDetails } from "../../Firebase/firebase";
import { useFormikContext } from "formik";
import { intersectionWith } from "lodash";

const SelectOrgnization = ({ name, certifiedBodies, data }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(null));
  const [otherSelected, setOtherSelected] = React.useState(``);

  return (
    <>
      <Menu
        selectedIndex={selectedIndex}
        onSelect={(index) => {
          setSelectedIndex(index);
          setOtherSelected(data[index?.row]?.label);
          setFieldValue(name, data[index?.row]?.value);
        }}
      >
        <MenuGroup title="Select Organization">
          {data?.map((certifiedBody) => {
            return <MenuItem key={certifiedBody?.label} title={certifiedBody?.label} />;
          })}
        </MenuGroup>
      </Menu>
      {/* <Select
        selectedIndex={selectedIndex}
        onSelect={(index) => {
          // console.log(index);
          setSelectedIndex(index);
          if (values[name] === `Other`) setFieldValue(name, `Other`);
          else setFieldValue(name, certifiedBodies[index?.row]?.value);
        }}
      >
        {data?.map((certifiedBody) => {
          return <SelectItem key={certifiedBody?.label} title={certifiedBody?.label} />;
        })}
      </Select> */}

      {otherSelected === "Other" ? (
        <>
          <BaseText text="Enter your organization name" category="label" />
          <WithFormikInput name={name} placeholder="Enter your organization name" autoCorrect={false} />
        </>
      ) : (
        <FormErrorMessage error={errors[name]} visible={touched[name]} />
      )}
    </>
  );
};

const EditMembership = ({ userDetails, setVisible }) => {
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [memberBodies, setMemberBodies] = React.useState({});
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    let observer1;

    const query = getAppUtils();
    const doc = query.doc(`member_bodies`);

    observer1 = doc.onSnapshot(
      (doc) => {
        setMemberBodies(doc.data());
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer1 && observer1();
    };
  }, []);

  // console.log(memberBodies);
  const certifiedBodies = Object.values(memberBodies)
    .map((body) => ({ label: body.name, value: body.name }))
    .concat({ label: "Other", value: "" });

  // React.useEffect(() => {
  //   const filteredCertifiedBodies = intersectionWith(
  //     certifiedBodies,
  //     userDetails?.certifications || [],
  //     (arrVal, othVal) => arrVal?.label !== othVal?.certifiedMemberOf
  //   );

  //   // console.log({ filteredCertifiedBodies });
  //   setData(
  //     userDetails?.certifications?.length === 0 && filteredCertifiedBodies.length === 0
  //       ? certifiedBodies
  //       : userDetails?.certifications?.length > 0 && filteredCertifiedBodies.length === 0
  //       ? []
  //       : filteredCertifiedBodies
  //   );
  // }, [userDetails, certifiedBodies?.length]);

  React.useEffect(() => {
    let result = certifiedBodies.filter((o1) => !(userDetails?.certifications || []).some((o2) => o1.label === o2.certifiedMemberOf));

    setData(
      !userDetails?.certifications || userDetails?.certifications?.length === 0
        ? certifiedBodies
        : userDetails?.certifications && userDetails?.certifications?.length > 0
        ? result
        : []
    );
  }, [userDetails, certifiedBodies?.length]);

  const formatImage = async (image, certifiedMemberOf, certifiedMembershipID, userID, functionToRun) => {
    try {
      setProcessing(true);
      var storageRef = storageUtils.storage().ref();
      var metadata = {
        contentType: "image/jpeg",
      };

      const response = await fetch(image);
      const blob = await response.blob();

      const storagePath = `users/images/certifiedMemberImages`;
      var uploadTask = storageRef.child(`${storagePath}/${userID}_${certifiedMemberOf}.jpg`).put(blob, metadata);
      uploadTask.on(
        "state_changed",
        () => {},
        (err) => {
          setProcessing(false);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await functionToRun(downloadURL, certifiedMembershipID, certifiedMemberOf);
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onPressSaveCertifiedMember = async (certifiedMemberImage, certifiedMembershipID, certifiedMemberOf) => {
    try {
      setProcessing(true);
      const data = {
        certifiedMembershipID,
        certifiedMemberOf,
        certifiedMemberImage,
      };

      await updateUserDetails(userDetails?.userID, {
        certifications: firestoreUtils.firestore.FieldValue.arrayUnion(data),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
      setVisible(false);
    }
  };

  return (
    <KeyboardAvoidingView contentContainerStyle={[styles.container]}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false} keyboardShouldPersistTaps="always">
        <Form
          initialValues={{
            certifiedMembershipID: "",
            certifiedMemberOf: null,
            certifiedMemberImage: "",
          }}
          validationSchema={certifiedMembership}
          onSubmit={async ({ certifiedMembershipID, certifiedMemberOf, certifiedMemberImage }, formikBag) => {
            // console.log(values);
            await formatImage(
              certifiedMemberImage,
              certifiedMemberOf,
              certifiedMembershipID,
              userDetails?.userID,
              onPressSaveCertifiedMember
            );
          }}
        >
          <BaseText text="Select Organization" category="label" style={{ textAlign: "center" }} />
          <SelectOrgnization name="certifiedMemberOf" certifiedBodies={certifiedBodies} data={data} />

          <BaseText text="Enter membership ID" category="label" />
          <WithFormikInput name="certifiedMembershipID" placeholder="Enter your ID number" autoCapitalize="none" autoCorrect={false} />

          <BaseText text="ID Card Image" category="label" style={{ textAlign: "center" }} />
          <ImagePIckerExample name="certifiedMemberImage" setLoading={setLoading} isOpenCamera={true} />

          <View style={{ marginTop: 10 }}>
            <WithFormikButton
              disabled={processing || loading}
              text={"Save"}
              accessoryLeft={processing ? LoadingIndicator : null}
              appearance={processing ? "outline" : "filled"}
            />

            <Button
              onPress={() => {
                setVisible(false);
              }}
              style={{ marginVertical: 5 }}
              disabled={processing || loading}
              status="warning"
              appearance={"outline"}
            >
              Close
            </Button>
          </View>
          {<FormErrorMessage error={loginError} visible={true} />}
        </Form>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default EditMembership;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
