import React, { useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { StatusBar, setStatusBarStyle } from "expo-status-bar";

export default function useStatusBar(style = "auto", animated = true) {
  useFocusEffect(
    useCallback(() => {
      setStatusBarStyle(style, animated);
    }, [])
  );
}
