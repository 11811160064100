import React, { useState } from "react";
import { StyleSheet, View, Image, Text, KeyboardAvoidingView, ScrollView } from "react-native";
import * as Yup from "yup";

import Colors from "../utils/colors";
import SafeView from "../components/SafeView";
import Form from "../components/Forms/Form";
import FormField from "../components/Forms/FormField";
import FormButton from "../components/Forms/FormButton";
import IconButton from "../components/IconButton";
import { passwordReset } from "../components/Firebase/firebase";
import FormErrorMessage from "../components/Forms/FormErrorMessage";
import useStatusBar from "../hooks/useStatusBar";
import { StatusBar } from "expo-status-bar";
import Logo from "../components/Misc/Logo";
import WithFormikButton from "../components/Buttons/WithFormikButton";
import LoadingIndicator from "../components/Misc/LoadingIndicator";

const validationSchema = Yup.object().shape({
  email: Yup.string().label("Email").email("Enter a valid email").required("Please enter a registered email"),
});

export default function ForgotPasswordScreen({ navigation }) {
  // useStatusBar("light-content");

  const [customError, setCustomError] = useState("");
  const [processing, setProcessing] = useState(false);

  async function handlePasswordReset(values) {
    const { email } = values;

    try {
      setProcessing(true);
      await passwordReset(email);
      setProcessing(false);
      navigation.navigate("Welcome");
    } catch (error) {
      setCustomError(error.message);
    }
  }

  return (
    <KeyboardAvoidingView style={[styles.container]}>
      <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }} showsVerticalScrollIndicator={false}>
        <Logo />
        <Form initialValues={{ email: "" }} validationSchema={validationSchema} onSubmit={(values) => handlePasswordReset(values)}>
          <FormField
            name="email"
            leftIcon="email"
            placeholder="Enter email"
            autoCapitalize="none"
            keyboardType="email-address"
            textContentType="emailAddress"
            autoFocus={true}
          />
          <WithFormikButton
            style={{ paddingHorizontal: 100 }}
            disabled={processing}
            text={"Forgot Password"}
            accessoryLeft={processing ? LoadingIndicator : null}
            appearance={processing ? "outline" : "filled"}
          />
          {/* <FormButton title="Forgot Password" /> */}
          {<FormErrorMessage error={customError} visible={true} />}
        </Form>
        <IconButton
          style={styles.backButton}
          iconName="keyboard-backspace"
          color={Colors.black}
          size={30}
          onPress={() => navigation.goBack()}
        />
        <StatusBar style="auto" />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15,
    backgroundColor: Colors.white,
  },
  backButton: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
  },
  logoContainer: {
    alignItems: "center",
  },
  logo: {
    width: 125,
    height: 125,
    borderRadius: 10,
  },
  footerButtonContainer: {
    marginVertical: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  subtitle: {
    fontSize: 24,
    fontWeight: "600",
    paddingTop: 20,
    paddingBottom: 0,
    color: `black`,
  },
});
