import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, Text, View, Image, ScrollView, KeyboardAvoidingView } from "react-native";
import * as Yup from "yup";
import { StatusBar } from "expo-status-bar";
import AsyncStorage from "@react-native-async-storage/async-storage";

import Colors from "../utils/colors";
import SafeView from "../components/SafeView";
import Form from "../components/Forms/Form";
import FormField from "../components/Forms/FormField";
import FormButton from "../components/Forms/FormButton";
import IconButton from "../components/IconButton";
import {
  auth,
  firebaseUtils,
  firestoreUtils,
  getAppUtils,
  loginWithEmail,
  registerWithEmail,
  setUserDetails,
  updateUserDetails,
} from "../components/Firebase/firebase";
import FormErrorMessage from "../components/Forms/FormErrorMessage";
import useStatusBar from "../hooks/useStatusBar";
import dayjs from "dayjs";
import { genRandomString } from "../utils";
import WithFormikButton from "../components/Buttons/WithFormikButton";
import LoadingIndicator from "../components/Misc/LoadingIndicator";
import Logo from "../components/Misc/Logo";
import { useHeaderHeight } from "@react-navigation/stack";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Please enter a registered email").email().label("Email"),
  password: Yup.string().required().min(6, "Password must have at least 6 characters").label("Password"),
});

export default function LoginScreen({ navigation }) {
  // useStatusBar("auto");

  const [accountDefaults, setAccountDefaults] = React.useState({});
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [rightIcon, setRightIcon] = useState("eye");
  const [loginError, setLoginError] = useState("");
  const [processing, setProcessing] = useState(false);

  // console.log(accountDefaults);

  React.useEffect(() => {
    (async () => {
      const query = getAppUtils();
      const doc = await query.doc(`account_defaults`).get();
      const data = doc.data();
      setAccountDefaults(data);
    })();
  }, []);

  function handlePasswordVisibility() {
    if (rightIcon === "eye") {
      setRightIcon("eye-off");
      setPasswordVisibility(!passwordVisibility);
    } else if (rightIcon === "eye-off") {
      setRightIcon("eye");
      setPasswordVisibility(!passwordVisibility);
    }
  }

  async function handleOnLogin(values) {
    const { email, password } = values;

    try {
      await loginWithEmail(email, password);
    } catch (error) {
      setLoginError(error.message);
    }
  }

  const signInEmailOrCreateUser = async ({ email, password }) => {
    try {
      setProcessing(true);
      await AsyncStorage.setItem("@handytradie/emailCreds", JSON.stringify({ email, password }));
      await loginWithEmail(email, password);
    } catch (error) {
      if (error.code === "auth/too-many-requests") {
        setLoginError(error.message);
        setProcessing(false);
      } else if (error.code === "auth/wrong-password") {
        setLoginError("Password is incorrect. Try again");
        setProcessing(false);
      } else if (error.code === "auth/user-not-found") {
        await registerWithEmail(email, password)
          .then(async (userData) => {
            const uid = userData?.user?.uid;
            await setUserDetails(uid, {
              ...accountDefaults,
              dateCreated: firestoreUtils.timestampNow(),
              dateString: dayjs(firestoreUtils.timestampNow().toDate()).format("DD/MM/YYYY"),
              expoNotificationToken: null,
              email,
              username: `User${genRandomString(5)}`,
              userID: uid,
            });
          })
          .then(() => {
            auth.onAuthStateChanged(async (user) => {
              if (user) await user.sendEmailVerification();
            });
          })
          .catch((error) => {
            console.log("signInEmailOrCreateUser ===> ", error);
            console.error(error);
          })
          .finally(() => {
            setProcessing(false);
          });
      } else {
        console.log(error);
        setLoginError(error.message);
        setProcessing(false);
      }
    }
  };

  return (
    <KeyboardAvoidingView style={[styles.container]}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
        showsVerticalScrollIndicator={false}
        // style={{ flex: 1 }}
        scrollEnabled
      >
        <Logo />
        <Form
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => signInEmailOrCreateUser(values)}
        >
          <FormField
            name="email"
            leftIcon="email"
            placeholder="Enter email"
            autoCapitalize="none"
            keyboardType="email-address"
            textContentType="emailAddress"
            autoFocus={true}
            autoCorrect={false}
          />
          <FormField
            name="password"
            leftIcon="lock"
            placeholder="Enter password"
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry={passwordVisibility}
            textContentType="password"
            rightIcon={rightIcon}
            handlePasswordVisibility={handlePasswordVisibility}
            autoCorrect={false}
          />
          <WithFormikButton
            style={{ width: "100%", textAlign: "center" }}
            disabled={processing}
            text={"Login"}
            accessoryLeft={processing ? LoadingIndicator : null}
            appearance={processing ? "outline" : "filled"}
          />
          {<FormErrorMessage error={loginError} visible={true} />}
        </Form>
        <View style={styles.footerButtonContainer}>
          <TouchableOpacity onPress={() => navigation.navigate("ForgotPassword")}>
            <Text style={styles.forgotPasswordButtonText}>Forgot Password?</Text>
          </TouchableOpacity>
        </View>
        <IconButton style={styles.backButton} iconName="keyboard-backspace" color="#000" size={30} onPress={() => navigation.goBack()} />
        <StatusBar style="auto" />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
    backgroundColor: Colors.white,
  },
  logoContainer: {
    alignItems: "center",
  },
  logo: {
    width: 125,
    height: 125,
    borderRadius: 10,
  },
  footerButtonContainer: {
    marginVertical: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  subtitle: {
    fontSize: 24,
    fontWeight: "600",
    paddingTop: 20,
    paddingBottom: 0,
    color: `black`,
  },
  forgotPasswordButtonText: {
    color: Colors.black,
    fontSize: 18,
    fontWeight: "600",
  },
  backButton: {
    justifyContent: "center",
    alignItems: "center",
  },
});
