import React from "react";
import { StyleSheet, View, FlatList, ScrollView } from "react-native";
import BaseButton from "../components/Buttons/BaseButton";
import { getAllNotifications } from "../components/Firebase/firebase";
import { Button, Card, Layout, Text, Divider, TopNavigation, TopNavigationAction } from "@ui-kitten/components";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import { SafeAreaLayout } from "../components/safe-area-layout.component.jsx";
import { ArrowIosBackIcon } from "../components/icons";

import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Header = ({ item, ...props }) => {
  //   console.log(item);
  return (
    <View {...props} style={{ paddingLeft: 10 }}>
      <Text category="h6">{item?.title}</Text>
      <Text category="label">{dayjs().to(dayjs(item?.timestamp?.toDate()))}</Text>
    </View>
  );
};

const Footer = ({ item, ...props }) => (
  <View {...props} style={[styles.footerContainer]}>
    <Text style={styles.footerControl} size="small" status="basic">
      CANCEL
    </Text>
  </View>
);

const renderItem = ({ item }) => {
  //   console.log(item);
  return (
    <Card
      style={styles.card}
      status={item?.status === "open" ? "success" : `primary`}
      header={() => <Header item={item} />}
      // footer={() => <Footer item={item} />}
    >
      <Text>{item?.message}</Text>
    </Card>
  );
};

const NotificationsScreen = ({ navigation, route, user }) => {
  const [notifDocs, setNotifDocs] = React.useState([]);
  //   console.log(notifDocs);

  React.useEffect(() => {
    let observer1;

    const query = getAllNotifications(user?.uid);

    observer1 = query.onSnapshot(
      (querySnapshot) => {
        // console.log(`Received query snapshot of size ${querySnapshot.size}`);
        var docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });
        setNotifDocs(docs);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer1 && observer1();
    };
  }, []);

  return (
    <View
      style={{
        height: "100%",
      }}
    >
      <View
        style={{
          marginHorizontal: "auto",
          paddingHorizontal: 10,
          height: "100%",
        }}
      >
        <FlatList
          ListEmptyComponent={
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center", height: "100%" }}>
              <Text category="h6" style={{ textAlign: "center" }}>
                No Notifications
              </Text>
            </View>
          }
          style={{
            height: "100%",
          }}
          keyExtractor={(item, index) => item?.title + index}
          showsVerticalScrollIndicator={false}
          data={notifDocs}
          renderItem={renderItem}
        />
      </View>
    </View>
  );
};

export default NotificationsScreen;

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  card: {
    margin: 2,
    marginBottom: 10,
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  footerControl: {
    marginHorizontal: 2,
  },
});
