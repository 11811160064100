import React from "react";
import { ScrollView } from "react-native";
import { Button, StyleService, useStyleSheet } from "@ui-kitten/components";
import { ProfileAvatar } from "./extra/profile-avatar.component";
import { ProfileSetting } from "./extra/profile-setting.component";
import { CameraIcon, EditIcon } from "./extra/icons";
import { Profile } from "./extra/data";

const ProfileDetails = ({ userDetails, navigation }) => {
  const profile = new Profile(userDetails);
  // console.log(profile);
  const styles = useStyleSheet(themedStyle);

  const onDoneButtonPress = () => {
    navigation && navigation.goBack();
  };

  const renderPhotoButton = () => (
    <Button
      style={styles.editAvatarButton}
      status="basic"
      accessoryLeft={EditIcon}
      onPress={() => {
        navigation.navigate("Edit Profile");
      }}
    />
  );

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
      <ProfileAvatar style={styles.profileAvatar} source={{ uri: profile.photo }} editButton={renderPhotoButton} />
      <ProfileSetting style={[styles.profileSetting, styles.section]} hint="First Name" value={profile.firstName} />
      <ProfileSetting style={styles.profileSetting} hint="Last Name" value={profile.lastName} />
      <ProfileSetting style={styles.profileSetting} hint="Email" value={profile.email} />
      <ProfileSetting style={styles.profileSetting} hint="Gender" value={profile.genderName} />
      <ProfileSetting style={styles.profileSetting} hint="Age" value={`${profile.age}`} />
      <ProfileSetting style={{ ...styles.profileSetting }} hint="Bio" value={profile.bio} />
      <ProfileSetting style={styles.profileSetting} hint="Address" value={`${profile.address}`} />
      <ProfileSetting
        style={{
          ...styles.profileSetting,
          ...styles.section,
          flexDirection: "column",
        }}
        hint="My Skills"
        value={profile.allTradieSkills}
      />
    </ScrollView>
  );
};

export default ProfileDetails;

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: "background-basic-color-2",
  },
  contentContainer: {
    paddingVertical: 24,
    flexGrow: 1,
  },
  profileAvatar: {
    aspectRatio: 1.0,
    height: 124,
    alignSelf: "center",
  },
  editAvatarButton: {
    aspectRatio: 1.0,
    height: 48,
    borderRadius: 24,
  },
  profileSetting: {
    padding: 16,
  },
  section: {
    marginTop: 24,
  },
  doneButton: {
    marginHorizontal: 24,
    marginTop: 24,
  },
});
