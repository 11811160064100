import React from "react";
import { StyleSheet, View, FlatList, ScrollView, Linking, Share } from "react-native";
import BaseButton from "../../components/Buttons/BaseButton";
import { Button, Card, Layout, Text, Avatar, List } from "@ui-kitten/components";
import { ImageOverlay } from "../../components/view-products/extra/image-overlay.component";
import * as Sharing from "expo-sharing";
import { getAllStoreProducts, getAllStores } from "../Firebase/firebase";
// import Share from "react-native-share";

const renderItemHeader = (item) => (
  <ImageOverlay style={{ ...styles.itemHeader, overlayColor: "rgba(0, 0, 0, 0)" }} source={{ uri: item?.productImage }} />
);

const openWhatsapp = (number, item) => {
  try {
    let url =
      "whatsapp://send?text=" + encodeURIComponent(`I need ${item?.productName}\n\n${item?.productImageShortUrl}`) + "&phone=233" + number;
    Linking.openURL(url)
      .then((data) => {
        console.log("WhatsApp Opened successfully " + data);
      })
      .catch((error) => {
        console.log(error);
        alert("Make sure WhatsApp is installed on your device");
      });
  } catch (error) {
    console.log(error);
  }
};

let openShareDialogAsync = async (uri) => {
  if (!(await Sharing.isAvailableAsync())) {
    alert(`Uh oh, sharing isn't available on your platform`);
    return;
  }

  await Sharing.shareAsync(uri);
};

const renderItem = ({ item, storeData }) => {
  // console.log({ item });

  //   return null;
  return (
    <Card style={styles.item} header={() => renderItemHeader(item)}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <View>
          <Text style={styles.itemDescription} category="h4">
            {item?.productName}
          </Text>
          <Text style={styles.itemDescription} category="s1">
            {item.productNotes || "No notes entered"}
          </Text>
          <Text style={styles.itemDescription} category="p2">
            Added: {item?.productAdded?.toDate()?.toDateString()}
          </Text>
        </View>

        <Text style={styles.itemDescription} category="s1">
          GHS{item?.productPrice}
        </Text>
      </View>

      {storeData?.storeWhatsappNumber ? (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <Button
            size="small"
            onPress={() => {
              const shareOptions = {
                title: item?.productName,
                message: item?.productNotes, // Note that according to the documentation at least one of "message" or "url" fields is required
                url: `data:image/jpeg;base64,${item?.productImageBase64String}`,
                // url: encodeURIComponent(item?.productImage),
                subject: "I need this product",
              };

              // Share.share(shareOptions);
              // openShareDialogAsync(`data:image/jpeg;base64,${item?.productImageBase64String}`);
              // Share.open(shareOptions);
              openWhatsapp(storeData?.storeWhatsappNumber || "", item);
            }}
          >
            Ask the Store
          </Button>
        </View>
      ) : (
        <></>
      )}
    </Card>
  );
};

const ShowProducts = ({ navigation, route }) => {
  const { storeData } = route.params;

  return (
    <>
      <List
        style={styles.list}
        contentContainerStyle={styles.listContent}
        data={storeData?.products}
        renderItem={({ item }) => renderItem({ item, storeData })}
      />
    </>
  );
};

export default ShowProducts;

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  listContent: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  item: {
    marginVertical: 8,
  },
  itemHeader: {
    minHeight: 420,
  },
  itemTitle: {
    position: "absolute",
    left: 24,
    bottom: 24,
  },
  itemDescription: {
    marginHorizontal: -8,
  },
  itemFooter: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemReactionsContainer: {
    flexDirection: "row",
  },
  itemAddButton: {
    flexDirection: "row-reverse",
    paddingHorizontal: 0,
  },
  iconButton: {
    paddingHorizontal: 0,
  },
});
