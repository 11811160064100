import * as Yup from "yup";

export const createInvoiceValidationSchema = Yup.object().shape({
  description: Yup.string()
    .required("Please enter a description")
    .min(20, "Description must be more than 20 characters")
    .label("Description"),
  amount: Yup.number().required().min(1, "Amount is required").label("Amount"),
  clientName: Yup.string().required("Please enter a name").label("Name"),
  clientContact: Yup.string()
    .required()
    .min(10, "Mobile number must have at least 10 characters")
    .max(10, "Mobile number must have 10 characters")
    .label("Mobile Number"),
  downPayment: Yup.boolean().oneOf([true], "Down payment is required"),
  downPaymentValue: Yup.number().required().min(1, "Down payment amount is required").label("Down Payment"),
});

export const submitIssueSchema = Yup.object().shape({
  details: Yup.string().required().label("Details"),
  category: Yup.string().required().label("Category"),
});

export const createStoreSchema = Yup.object().shape({
  storeLogo: Yup.string().required().label("Logo"),
  storeName: Yup.string().required().label("Store name"),
  storePhoneNumber: Yup.string().min(10).required().label("Phone number"),
  storeWhatsappNumber: Yup.string().min(10).required().label("Whatsapp number"),
  storeDetails: Yup.string(),
  storeLocation: Yup.string().required().label("Store location"),
});

export const addNewProductSchema = Yup.object().shape({
  image: Yup.string().required().label("Product image"),
  name: Yup.string().required().label("Product name"),
  notes: Yup.string(),
  variants: Yup.array().of(
    Yup.object().shape({
      variantName: Yup.string().required().label("Variant name"),
      variantPrice: Yup.number().required().label("Variant price"),
      variantOutOfStock: Yup.bool(),
    })
  ),
});

export const addPersonnelSchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  phoneNumber: Yup.string().required().label("Phone number/Email"),
  // phoneNumber: Yup.string().required().min(10).max(10).label("Phone number"),
  accessType: Yup.string().required().label("Access Type"),
});

export const editProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, ({ min }) => `Must be longer than ${min} characters`)
    .max(20, ({ max }) => `Name cannot be more than ${max} characters`)
    .required("First name is required")
    .nullable(),
  lastName: Yup.string()
    .min(3, ({ min }) => `Must be longer than ${min} characters`)
    .max(20, ({ max }) => `Name cannot be more than ${max} characters`)
    .required("Last name is required")
    .nullable(),
  email: Yup.string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email address is not valid")
    .required("Email is required")
    .nullable(),
  address: Yup.string().required("Address is required").nullable(),
  gender: Yup.string().required("Gender is required").nullable(),
  dateOfBirth: Yup.mixed().required("Date of birth is required").nullable(),
  suburb: Yup.string().required("Suburb is required").nullable(),
  nationality: Yup.string().required("Nationality is required").nullable(),
  mobileNumber: Yup.string().required("Phone number is required").label(`Mobile number`).nullable(),
  // mobileNumber: Yup.string().required("Phone number is required").min(9).label(`Mobile number`).nullable(),
  verificationIDNumber: Yup.string().min(4, "Please enter a valid ID number").required("ID card number is required").nullable(),
  verificationMethod: Yup.string().min(4, "Please select a valid ID type").required("ID card type is required").nullable(),
  photoURL: Yup.string().required("Profile picture is required").nullable(),
  verificationImage: Yup.string().required("ID card image is required").nullable(),
});

export const tradieBusinessNameSchema = Yup.object().shape({
  businessName: Yup.string().nullable(),
  businessImage: Yup.string().nullable(),
});

export const tradieBusinessInfoSchema = Yup.object().shape({
  businessInfo: Yup.string().max(500, ({ max }) => `Business info cannot be more than ${max} characters`),
});

export const tradiePaymentMethodSchema = Yup.object().shape({
  paymentAccountName: Yup.string().required("Payment name is required"),
  mobileMoneyAccountCode: Yup.string().required("MoMo network code is required"),
  mobileMoneyNumber: Yup.string()
    .max(10, ({ max }) => `MoMo number cannot be more than ${max} characters`)
    .required("MoMo number is required"),
});

export const certifiedMembership = Yup.object().shape({
  certifiedMembershipID: Yup.string().required("ID number is required"),
  certifiedMemberOf: Yup.string()
    .transform((value, originalvalue) => (originalvalue === null ? "" : value))
    .required("Organization is required"),
  certifiedMemberImage: Yup.string().required("Image is required"),
});

export const skillCertificate = Yup.object().shape({
  certificateName: Yup.string()
    .transform((value, originalvalue) => (originalvalue === null ? "" : value))
    .required("Details are required"),
  userCertificationImage: Yup.string().required("Image is required"),
});
