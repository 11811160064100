import React from "react";
import { StyleSheet, TouchableOpacity, Text, View, KPlatform, ScrollView, KeyboardAvoidingView, Dimensions } from "react-native";
import { Spinner, Divider, TopNavigation, TopNavigationAction, Icon } from "@ui-kitten/components";
import { useHeaderHeight } from "@react-navigation/stack";

import Form from "../components/Forms/Form";

import FormErrorMessage from "../components/Forms/FormErrorMessage";
import { createInvoiceValidationSchema, createStoreSchema } from "../utils/schemas";
import WithFormikCheckbox from "../components/Checkboxes/WithFormikCheckbox";
import WithFormikInput from "../components/Inputs/WithFormikInput";
import WithFormikButton from "../components/Buttons/WithFormikButton";
import BaseText from "../components/Typography/BaseText";
import BaseRadioGroup from "../components/Radios/BaseRadioGroup";
import BaseInput from "../components/Inputs/BaseInput";
import { useFormikContext } from "formik";
import {
  addDocToCollection,
  createNewDocInCollection,
  createNewInvoice,
  firestoreUtils,
  getCurrentUser,
  getUserDetails,
  storageUtils,
} from "../components/Firebase/firebase";

import { SafeAreaLayout } from "../components/safe-area-layout.component.jsx";
import { ArrowIosBackIcon } from "../components/icons";
import ImagePIckerExample from "../components/Misc/ImagePIckerExample";
import LoadingIndicator from "../components/Misc/LoadingIndicator";
import BottomSheetModal from "../components/Misc/BottomSheetModal";
import BaseButton from "../components/Buttons/BaseButton";
import SuburbSearch from "../components/Misc/SuburbSearch";
import { genRandomString } from "../utils";

const LocationComponent = ({ name = "storeLocation" }) => {
  const { width, height } = Dimensions.get("window");
  const { values, setFieldValue, touched, errors } = useFormikContext();
  //   console.log(values[name]);

  // ref
  const bottomSheetRef = React.useRef(null);
  const handleExpandPress = () => {
    bottomSheetRef.current.present();
  };
  const handleClosePress = () => {
    bottomSheetRef.current.dismiss();
  };

  return (
    <>
      <BaseText text="Location" category="label" />
      <BaseButton text={values[name] || "Add Location"} appearance="outline" onPress={handleExpandPress} />
      <FormErrorMessage error={errors[name]} visible={touched[name]} />

      <BottomSheetModal ref={bottomSheetRef} snapPoints={["80%"]}>
        <KeyboardAvoidingView
          style={[
            {
              flex: 1,
              width: "100%",
              backgroundColor: "#eee",
              padding: 15,
              zIndex: 1000,
              paddingBottom: 10,
              paddingHorizontal: 10,
              paddingTop: 10,
            },
          ]}
          // behavior={Platform.OS === "ios" ? "padding" : "height"}
          keyboardVerticalOffset={useHeaderHeight()}
        >
          <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{}} showsVerticalScrollIndicator={false}>
            <View style={{}}>
              <SuburbSearch name={name} setFieldValue={setFieldValue} setVisible={handleClosePress} />
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </BottomSheetModal>
    </>
  );
};

const AddAStoreForm = ({ navigation, userDetails }) => {
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);

  // ref
  const bottomSheetRef = React.useRef(null);
  const imagePickerRef = React.useRef(null);

  const handleExpandPress = () => {
    bottomSheetRef.current.present();
  };
  const handleClosePress = () => {
    bottomSheetRef.current.dismiss();
  };

  const createNewStore = async (values) => {
    try {
      if (!values?.storeLogo) return;
      setProcessing(true);
      const ref = firestoreUtils.firestore().collection("stores").doc();
      const storeID = ref?.id;
      // const storeID = genRandomString();
      var storageRef = storageUtils.storage().ref();
      var metadata = {
        contentType: "image/jpeg",
      };

      const response = await fetch(values?.storeLogo);
      const blob = await response.blob();

      var uploadTask = storageRef.child(`stores/logos/${storeID}.jpg`).put(blob, metadata);

      uploadTask.on(
        "state_changed",
        () => {},
        (err) => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            try {
              const data = {
                storeID,
                storeLogoURL: downloadURL,
                storeName: values?.storeName,
                storeDetails: values?.storeDetails,
                storePhoneNumber: values?.storePhoneNumber,
                storeWhatsappNumber: values?.storeWhatsappNumber,
                storeLocation: values?.storeLocation,
                storeCreated: firestoreUtils.firestore.FieldValue.serverTimestamp(),
                storeCreatedBy: userDetails?.userID,
                storeUpdated: firestoreUtils.firestore.FieldValue.serverTimestamp(),
                owner: firestoreUtils.firestore.FieldValue.arrayUnion(userDetails?.userID),
              };

              await addDocToCollection(`stores`, storeID, data);
              await firestoreUtils
                .firestore()
                .collection(`users`)
                .doc(userDetails?.userID)
                .update({
                  storeAdded: true,
                  linkedStores: firestoreUtils.firestore.FieldValue.arrayUnion(storeID),
                  noOfStoresAdded: firestoreUtils.firestore.FieldValue.increment(1),
                });

              return navigation.goBack();
            } catch (error) {
              console.log(error);
            } finally {
              setProcessing(false);
            }
          });
        }
      );
    } catch (error) {
      console.log(error);
      setProcessing(false);
    }
  };

  return (
    <KeyboardAvoidingView
      style={[styles.container]}
      // behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={useHeaderHeight()}
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
        <Form
          initialValues={{
            storeLogo: "",
            storeName: "",
            storePhoneNumber: "",
            storeWhatsappNumber: "",
            storeDetails: "",
            storeLocation: "",
          }}
          validationSchema={createStoreSchema}
          onSubmit={async (values) => {
            await createNewStore(values);
          }}
        >
          <BottomSheetModal ref={bottomSheetRef} snapPoints={["20%"]}>
            <View
              style={{
                flex: 1,
                width: "100%",
                backgroundColor: "#eee",
                padding: 15,
              }}
            >
              <BaseText text="Choose an option" category="label" style={{ textAlign: "center", width: "100%" }} />
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexDirection: "row",
                }}
              >
                <Icon
                  style={styles.icon}
                  fill="green"
                  name="folder-outline"
                  onPress={() => {
                    imagePickerRef.current.pickImage(false);
                    handleClosePress();
                  }}
                />
                <Icon
                  style={styles.icon}
                  fill="orange"
                  name="camera-outline"
                  onPress={() => {
                    imagePickerRef.current.pickImage(true);
                    handleClosePress();
                  }}
                />
              </View>
            </View>
          </BottomSheetModal>

          <BaseText text="Logo" category="label" style={{ textAlign: "center", width: "100%" }} />
          <ImagePIckerExample name="storeLogo" ref={imagePickerRef} handleExpandPress={handleExpandPress} />
          <BaseText text="Store Name" category="label" />
          <WithFormikInput name="storeName" placeholder="Enter store name" autoCorrect={false} />

          <BaseText text="Phone Number" category="label" />
          <WithFormikInput name="storePhoneNumber" placeholder="Enter phone number to call" autoCorrect={false} />

          <BaseText text="Whatsapp Number" category="label" />
          <WithFormikInput name="storeWhatsappNumber" placeholder="Enter whatsapp number to send messages to" autoCorrect={false} />

          <LocationComponent />

          <BaseText text="Details" category="label" />
          <WithFormikInput
            name="storeDetails"
            placeholder="Enter some details about store"
            multiline={true}
            textStyle={{ minHeight: 100 }}
          />

          <View style={{ marginTop: 10 }}>
            <WithFormikButton
              disabled={processing}
              text={"Create Store"}
              accessoryLeft={processing ? LoadingIndicator : null}
              appearance={processing ? "outline" : "filled"}
            />
          </View>
          {<FormErrorMessage error={loginError} visible={true} />}
        </Form>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
  indicator: {
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 50,
    height: 50,
  },
});

export default AddAStoreForm;
