import React from "react";
import { StyleSheet } from "react-native";
import { Radio, RadioGroup, Text } from "@ui-kitten/components";

const BaseRadioGroup = ({
  selectedIndex,
  setSelectedIndex,
  data = [],
  radioGroupStyles = {},
  radioGroupProps,
}) => {
  return (
    <RadioGroup
      style={[radioGroupStyles]}
      selectedIndex={selectedIndex}
      onChange={(index) => setSelectedIndex(index)}
      {...radioGroupProps}
    >
      {data.map((radio) => (
        <Radio key={radio}>{radio}</Radio>
      ))}
    </RadioGroup>
  );
};

export default BaseRadioGroup;

const styles = StyleSheet.create({});
