import React from "react";
import { StyleSheet, Text, View } from "react-native";

import { useFormikContext } from "formik";
import CheckBoxBase from "./CheckBoxBase";
import FormErrorMessage from "../Forms/FormErrorMessage";

const WithFormikCheckbox = ({ containerStyles, name, checkBoxText }) => {
  const { setFieldTouched, setFieldValue, values, errors, touched } =
    useFormikContext();

  return (
    <View style={[styles.container, containerStyles]}>
      <CheckBoxBase
        checkBoxText={checkBoxText}
        checked={values[name]}
        setChecked={(value) => setFieldValue(name, value)}
      />
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
    </View>
  );
};

export default WithFormikCheckbox;

const styles = StyleSheet.create({
  container: {
    marginVertical: 5,
  },
});
