import React from "react";
import { StyleSheet, View, ScrollView, Image, KeyboardAvoidingView } from "react-native";
import {
  Button,
  Spinner,
  Divider,
  TopNavigation,
  TopNavigationAction,
  Datepicker,
  Layout,
  Text,
  Card,
  Modal,
  IndexPath,
  Select,
  SelectItem,
  Menu,
  MenuGroup,
  MenuItem,
} from "@ui-kitten/components";
import { tradieBusinessInfoSchema, tradieBusinessNameSchema } from "../../../utils/schemas";
import LoadingIndicator from "../../Misc/LoadingIndicator";

import Form from "../../Forms/Form";
import FormErrorMessage from "../../Forms/FormErrorMessage";
import WithFormikInput from "../../Inputs/WithFormikInput";
import WithFormikButton from "../../Buttons/WithFormikButton";
import BaseText from "../../Typography/BaseText";
import ImagePIckerExample from "../../Misc/ImagePIckerExample";
import { storageUtils, updateUserDetails } from "../../Firebase/firebase";

const EditBusinessInfo = ({ userDetails, setVisible }) => {
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onPressSaveBusinessName = async ({ businessInfo }) => {
    try {
      await updateUserDetails(userDetails?.userID, { businessInfo });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <KeyboardAvoidingView contentContainerStyle={[styles.container]} s>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false} keyboardShouldPersistTaps="always">
        <Form
          initialValues={{
            businessInfo: userDetails?.businessInfo || "",
          }}
          validationSchema={tradieBusinessInfoSchema}
          onSubmit={async (values, formikBag) => {
            setProcessing(true);
            const newValues = { ...values };
            delete newValues?.promos;
            await onPressSaveBusinessName(newValues);
            setProcessing(false);
            setVisible(false);
          }}
        >
          <BaseText text="Business Info" category="label" />
          <WithFormikInput
            name="businessInfo"
            placeholder="Enter some info about your business"
            autoCapitalize="none"
            multiline={true}
            textStyle={{ minHeight: 100 }}
          />
          <View style={{ marginTop: 10 }}>
            <WithFormikButton
              disabled={processing || loading}
              text={"Save"}
              accessoryLeft={processing ? LoadingIndicator : null}
              appearance={processing ? "outline" : "filled"}
            />

            <Button
              onPress={() => {
                setVisible(false);
              }}
              style={{ marginVertical: 5 }}
              disabled={processing || loading}
              status="warning"
              appearance={"outline"}
            >
              Close
            </Button>
          </View>
          {<FormErrorMessage error={loginError} visible={true} />}
        </Form>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default EditBusinessInfo;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
