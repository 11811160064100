import { DefaultTheme } from "@react-navigation/native";
import Constants from "expo-constants";

import sandboxTheme from "../utils/sandbox-theme.json";
import prodTheme from "../utils/prod-theme.json";

import Colors from "../utils/colors";

const kittenTheme = Constants.manifest.extra.env === "sandbox" ? sandboxTheme : prodTheme;
const navigationTheme = {
  ...DefaultTheme,
  // override colors
  colors: {
    ...DefaultTheme.colors,
    primary: Constants.manifest.extra.colors.secondary.main,
    text: Constants.manifest.extra.colors.secondary.main,
    border: Colors.mediumGrey,
    background: Colors.ghostWhite,
  },
};

export default navigationTheme;
