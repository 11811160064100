import React from "react";
import { StyleSheet, View, FlatList, ScrollView, ActivityIndicator } from "react-native";
import BaseButton from "../components/Buttons/BaseButton";
import { getPaymentHistory } from "../components/Firebase/firebase";
import { Button, Card, Layout, Text, Divider, TopNavigation, TopNavigationAction } from "@ui-kitten/components";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import { SafeAreaLayout } from "../components/safe-area-layout.component.jsx";
import { ArrowIosBackIcon } from "../components/icons";

import dayjs from "dayjs";

const Header = ({ item, ...props }) => {
  //   console.log(item);
  return (
    <View {...props} style={{ paddingLeft: 10 }}>
      <Text category="label">{dayjs(item?.dateCreated?.toDate()).format("ddd, DD MMM YYYY")}</Text>
      <Text category="h6">{item?.taskTitle}</Text>
    </View>
  );
};

const Footer = ({ item, ...props }) => (
  <View {...props} style={[styles.footerContainer]}>
    <Text style={styles.footerControl} size="small" status="basic">
      CANCEL
    </Text>
  </View>
);

const renderItemEarned = ({ item }) => {
  //   console.log(item);
  return (
    <Card
      style={styles.card}
      status={item.status === "pending" ? "danger" : `success`}
      header={() => <Header item={item} />}
      // footer={() => <Footer item={item} />}
    >
      <Text>Assigned By: {item?.clientName}</Text>
      <Text>Amount: GHS{item?.amount}</Text>
    </Card>
  );
};

const renderItemOutGoing = ({ item }) => {
  //   console.log(item);
  return (
    <Card
      style={styles.card}
      status="primary"
      header={() => <Header item={item} />}
      // footer={() => <Footer item={item} />}
    >
      <Text>Assigned To: {item?.tradieName}</Text>
      <Text>Amount: GHS{item?.amount}</Text>
    </Card>
  );
};

const RenderList = ({ docs, isOutgoing = false }) => {
  return (
    <View
      style={{
        marginHorizontal: "auto",
        paddingHorizontal: 10,
        height: "100%",
      }}
    >
      <FlatList
        ListEmptyComponent={
          <Text category="h6" style={{ textAlign: "center" }}>
            No Payment History
          </Text>
        }
        style={{
          height: "100%",
        }}
        keyExtractor={(item, index) => item?.taskID + index}
        showsVerticalScrollIndicator={false}
        data={docs}
        renderItem={isOutgoing ? renderItemOutGoing : renderItemEarned}
      />
    </View>
  );
};

const TopTab = createMaterialTopTabNavigator();

const PaymenHistoryScreen = ({ navigation, route, user }) => {
  const [earnedDocs, setEarnedDocs] = React.useState([]);
  const [outgoingDocs, setOutgoingDocs] = React.useState([]);

  React.useEffect(() => {
    let observer1;
    let observer2;
    const { earnedQuery, outGoingQuery } = getPaymentHistory(user?.uid);

    observer1 = earnedQuery.onSnapshot(
      (querySnapshot) => {
        // console.log(`Received query snapshot of size ${querySnapshot.size}`);
        var invDocs = [];
        querySnapshot.forEach((doc) => {
          invDocs.push(doc.data());
        });
        setEarnedDocs(invDocs);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    observer2 = outGoingQuery.onSnapshot(
      (querySnapshot) => {
        // console.log(`Received query snapshot of size ${querySnapshot.size}`);
        var invDocs = [];
        querySnapshot.forEach((doc) => {
          invDocs.push(doc.data());
        });
        setOutgoingDocs(invDocs);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer1 && observer1();
      observer2 && observer2();
    };
  }, []);

  return (
    <>
      <TopTab.Navigator
        tabBarOptions={{
          indicatorStyle: {
            backgroundColor: "#f65100",
          },
          labelStyle: { fontFamily: "Signika_400Regular" },
        }}
        lazy
        lazyPlaceholder={({ route }) => {
          return (
            <View style={{ flex: 1, justifyContent: "center" }}>
              <ActivityIndicator size="small" color="#f65100" />
            </View>
          );
        }}
      >
        <TopTab.Screen name="Earned" children={(props) => <RenderList docs={earnedDocs} {...props} />} options={{ title: "Earned" }} />
        <TopTab.Screen
          name="Outgoing"
          children={(props) => <RenderList docs={outgoingDocs} isOutgoing={true} {...props} />}
          options={{ title: "Outgoing" }}
        />
      </TopTab.Navigator>
    </>
  );
};

export default PaymenHistoryScreen;

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  card: {
    margin: 2,
    marginBottom: 10,
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  footerControl: {
    marginHorizontal: 2,
  },
});
