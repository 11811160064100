import React from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import { Divider, Layout, Text, LayoutProps, Button } from "@ui-kitten/components";
import { EditIcon } from "./icons";

export const ProfileSetting = (props) => {
  const { width } = Dimensions.get("window");
  const { style, hint, value, editAction, ...layoutProps } = props;

  // console.log(value);

  return (
    <React.Fragment>
      <Layout level="1" {...layoutProps} style={[styles.container, style]}>
        <View style={{ position: "absolute", top: 0, right: 0 }}>
          <Button onPress={editAction} style={styles.editAvatarButton} status="primary" accessoryLeft={EditIcon} />
        </View>
        <Text appearance="hint" category="s1">
          {hint}
        </Text>
        <View style={{ marginTop: 10 }}>
          <View style={{ flexDirection: "row", justifyContent: "space-between", width: width - 20, paddingHorizontal: 5 }}>
            <Text category="s1">Name</Text>
            <Text category="s1">Network</Text>
            <Text category="s1">Number</Text>
          </View>
          <View style={{ flexDirection: "row", justifyContent: "space-between", width: width - 20, paddingHorizontal: 5 }}>
            <Text>{value?.name}</Text>
            <Text>{value?.code}</Text>
            <Text>{value?.number}</Text>
          </View>
        </View>
      </Layout>
      <Divider />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
  },
  editAvatarButton: {
    aspectRatio: 1.0,
    height: 2,
  },
});
