import React from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import { Divider, Layout, Text, LayoutProps, Button } from "@ui-kitten/components";
import { EditIcon } from "./icons";

export const ProfileSetting = (props) => {
  const { width } = Dimensions.get("window");
  const { style, hint, value, showEdit = true, textAlign = "left", editAction = () => {}, handleUserCanEdit, ...layoutProps } = props;

  // console.log({ value });

  return (
    <React.Fragment>
      <Layout level="1" {...layoutProps} style={[styles.container, style]}>
        {showEdit ? (
          <View style={{ position: "absolute", top: 0, right: 0 }}>
            <Button onPress={editAction} style={styles.editAvatarButton} status="primary" accessoryLeft={EditIcon} />
          </View>
        ) : (
          <></>
        )}
        <Text appearance="hint" category="s1">
          {hint}
        </Text>
        <View style={{ marginTop: 10, width: width - 30 }}>
          <Text category="s1" style={{ textAlign: value ? "left" : "center" }}>
            {value || "N/A"}
          </Text>
        </View>
      </Layout>
      <Divider />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
  },
  editAvatarButton: {
    aspectRatio: 1,
    height: 0,
  },
});
