import React from "react";
import { StyleSheet, View, ScrollView, KeyboardAvoidingView } from "react-native";
import { Text, Spinner, useStyleSheet, Icon } from "@ui-kitten/components";
import Form from "../Forms/Form";
import BaseText from "../Typography/BaseText";
import WithFormikInput from "../Inputs/WithFormikInput";
import WithFormikButton from "../Buttons/WithFormikButton";
import { addNewProductSchema, addPersonnelSchema } from "../../utils/schemas";
import FormErrorMessage from "../Forms/FormErrorMessage";
import BottomSheetModal from "../Misc/BottomSheetModal";
import { firebaseUtils, firestoreUtils, storageUtils } from "../Firebase/firebase";
import ImagePIckerExample from "../Misc/ImagePIckerExample";
import { genRandomString } from "../../utils";
import WithFormikCheckbox from "../Checkboxes/WithFormikCheckbox";
import { useHeaderHeight } from "@react-navigation/stack";

import { Picker } from "@react-native-picker/picker";
import { useFormikContext } from "formik";

const LoadingIndicator = (props) => (
  <View style={[props.style, styles.indicator]}>
    <Spinner size="small" />
  </View>
);

const PickerComponent = ({ name, storeDetails, userDetails }) => {
  const { setFieldTouched, setFieldValue, values, errors, touched } = useFormikContext();

  // console.log({ storeDetails });

  const isOwner = storeDetails?.owner?.includes(userDetails?.userID) ? ["owner", "manager", "assistant"] : [];
  const isManager = storeDetails?.manager?.includes(userDetails?.userID) ? ["manager", "assistant"] : [];
  const isAssistant = storeDetails?.assistant?.includes(userDetails?.userID) ? ["assistant"] : [];

  const userAccesses = [...isOwner, ...isManager, ...isAssistant];

  const pickerRef = React.useRef();

  function open() {
    pickerRef.current.focus();
  }

  function close() {
    pickerRef.current.blur();
  }

  const accessTypes = [
    {
      label: "Owner",
      value: "owner",
    },
    {
      label: "Manager",
      value: "manager",
    },
    {
      label: "Assistant",
      value: "assistant",
    },
  ];

  const filteredAccessTypes = accessTypes.filter((accessType) => userAccesses.includes(accessType?.value));
  // console.log(filteredAccessTypes);

  return (
    <>
      <Picker
        ref={pickerRef}
        selectedValue={values[name] || ""}
        onValueChange={(itemValue, itemIndex) => setFieldValue(name, itemValue)}
        mode="dropdown"
        style={{ fontFamily: `Signika_400Regular` }}
        itemStyle={{ fontFamily: `Signika_400Regular` }}
      >
        <Picker.Item label={`Select access type`} value={""} fontFamily="Signika_400Regular" />
        {filteredAccessTypes.map(({ label, value }, index) => {
          return <Picker.Item key={index} label={label} value={value} fontFamily="Signika_400Regular" />;
        })}
      </Picker>
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
};

const AddPersonnel = ({ navigation, route, userDetails }) => {
  const { storeDetails = {}, item, isEditing = false, title = "Add New Product" } = route.params;
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);

  const addPersonnel = async (values, { resetForm }) => {
    try {
      setProcessing(true);
      const addNewPersonnel = firebaseUtils.firebase.functions().httpsCallable("addNewPersonnel");
      await addNewPersonnel({
        userDetails: values,
        storeDetails,
      });
      navigation.goBack();
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <KeyboardAvoidingView style={[styles.container]}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false}>
        <Form
          initialValues={{
            name: "",
            phoneNumber: "",
            accessType: "",
          }}
          validationSchema={addPersonnelSchema}
          onSubmit={async (values, formikBag) => {
            // console.log(values);
            // return;
            await addPersonnel(values, formikBag);
          }}
        >
          <BaseText text="Name" category="label" />
          <WithFormikInput name="name" placeholder="Enter name" autoCorrect={false} />

          <BaseText text="Phone Number/Email" category="label" />
          <WithFormikInput name="phoneNumber" placeholder="Enter phone number/email" autoCorrect={false} autoCapitalize="none" />

          <BaseText text="Access Type" category="label" />
          <PickerComponent name="accessType" userDetails={userDetails} storeDetails={storeDetails} />

          <WithFormikButton
            style={{ marginTop: 10 }}
            disabled={processing}
            text={"Add Personnel"}
            accessoryLeft={processing ? LoadingIndicator : null}
            appearance={processing ? "outline" : "filled"}
          />

          {<FormErrorMessage error={loginError} visible={true} />}
        </Form>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default AddPersonnel;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
  indicator: {
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 50,
    height: 50,
  },
});
