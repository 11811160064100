import React from "react";
import { ScrollView, StyleSheet, View, Pressable, FlatList } from "react-native";
import { Menu, MenuItem, Text } from "@ui-kitten/components";

const RenderItem = ({ name, item, setFieldValue, setVisible }) => {
  return (
    <Pressable
      style={{
        marginVertical: 10,
      }}
      key={item?.countryName}
      onPress={() => {
        setFieldValue(name, item?.nationality);
        setVisible(false);
      }}
    >
      <Text category="s2">{item?.nationality}</Text>
    </Pressable>
  );
};

const Nationalities = ({ name, data = [], setFieldValue, setVisible }) => {
  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      data={data}
      renderItem={({ item }) => <RenderItem name={name} item={item} setFieldValue={setFieldValue} setVisible={setVisible} />}
      keyExtractor={(item) => item.countryName}
    />
  );

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      showsVerticalScrollIndicator={false}
      style={{
        flex: 1,
        paddingBottom: 25,
      }}
    ></ScrollView>
  );
};

export default Nationalities;

const styles = StyleSheet.create({});
