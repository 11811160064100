import React from "react";
import { ScrollView, View, Dimensions } from "react-native";
import { Button, StyleService, useStyleSheet, Text, Modal, Icon } from "@ui-kitten/components";
import { ProfileAvatar } from "./extra/profile-avatar.component";
import { ProfileSetting } from "./extra/profile-setting.component";
import { ProfileSetting as ProfileSettingMultiple } from "./extra/profile-setting-multiple.component";
import { CameraIcon, EditIcon } from "./extra/icons";
import { Profile } from "./extra/data";
import { WorkSamples } from "./extra/tradie-work-samples";
import { MemberShipBodies } from "./extra/membership-bodies";
import EditBusinessNameAndImage from "./extra/edit-business-name";
import EditBusinessInfo from "./extra/edit-business-info";
import EditPaymentMethod from "./extra/edit-payment-method";
import EditMembership from "./extra/edit-membership";
import EditSkillCertificate from "./extra/edit-skill-certificate";
import EditSkills from "./extra/edit-skills";
import { getCurrentUser } from "../Firebase/firebase";
import Constants from "expo-constants";

const TradieAccountDetails = ({ userDetails, navigation }) => {
  const user = getCurrentUser();
  const { width, height } = Dimensions.get("window");
  const profile = new Profile(userDetails);
  const styles = useStyleSheet(themedStyle);

  const [visible, setVisible] = React.useState(false);
  const [componentToRender, setComponentToRender] = React.useState(false);

  const renderPhotoButton = () => (
    <Button
      onPress={() => {
        setComponentToRender("businessName");
        setVisible(true);
      }}
      style={styles.editAvatarButton}
      status="primary"
      accessoryLeft={EditIcon}
    />
  );

  const handleUserCanEdit = user?.uid === userDetails?.userID ? true : false;

  const IdentityVerified = () => (
    <View
      style={{
        // width: "100%",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: Constants.manifest.extra.colors.primary.main,
          padding: 3,
          borderRadius: 5,
        }}
      >
        <Icon style={styles.icon} fill="#fff" name="checkmark-circle-outline" />
        <Text category="label" style={{ color: "white" }}>
          Identity Verified
        </Text>
      </View>
    </View>
  );

  return (
    <>
      <Modal visible={visible} backdropStyle={styles.backdrop} onBackdropPress={() => setVisible(false)}>
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
          disabled={true}
          style={{
            flex: 1,
            maxHeight: 450,
            maxWidth: width - 20,
            height: height,
            width: width,
            backgroundColor: "#eee",
            overflow: "hidden",
            padding: 15,
            marginVertical: 100,
          }}
        >
          {componentToRender === "businessName" ? <EditBusinessNameAndImage userDetails={userDetails} setVisible={setVisible} /> : <></>}
          {componentToRender === "businessInfo" ? <EditBusinessInfo userDetails={userDetails} setVisible={setVisible} /> : <></>}
          {componentToRender === "paymentMethod" ? <EditPaymentMethod userDetails={userDetails} setVisible={setVisible} /> : <></>}
          {componentToRender === "membership" ? <EditMembership userDetails={userDetails} setVisible={setVisible} /> : <></>}
          {componentToRender === "skillCertificate" ? <EditSkillCertificate userDetails={userDetails} setVisible={setVisible} /> : <></>}
          {componentToRender === "editSkills" ? <EditSkills userDetails={userDetails} setVisible={setVisible} /> : <></>}
        </ScrollView>
      </Modal>

      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <ProfileAvatar
          style={styles.profileAvatar}
          source={{ uri: profile.businessImage }}
          showEdit={handleUserCanEdit}
          editButton={renderPhotoButton}
        />
        <Text
          category="h6"
          style={{
            textAlign: "center",
          }}
        >
          {profile.tradieAccountName}
        </Text>
        <Text
          category="c2"
          style={{
            textAlign: "center",
          }}
        >
          {profile.address}
        </Text>

        <View>{profile?.identityVerified && <IdentityVerified />}</View>

        <View style={{ flexWrap: "wrap", justifyContent: "center" }}>
          {profile?.userCertifications
            ?.filter((certification) => certification?.certifiedMemberVerified)
            .map((certification, index) => {
              return (
                <View
                  key={certification?.certificateName + index}
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      backgroundColor: Constants.manifest.extra.colors.secondary.main,
                      // flex: 0,
                      padding: 3,
                      paddingHorizontal: 10,
                      borderRadius: 5,
                      marginHorizontal: 1,
                      marginVertical: 2,
                    }}
                  >
                    <Icon style={styles.icon} fill="#fff" name="checkmark-circle-outline" />
                    <Text category="label" style={{ color: "white", marginLeft: 5, fontSize: 12 }}>
                      {certification?.certificateName}
                    </Text>
                  </View>
                </View>
              );
            })}
        </View>

        <ProfileSetting
          showEdit={handleUserCanEdit}
          editAction={() => {
            setComponentToRender("businessInfo");
            setVisible(true);
          }}
          style={{
            ...styles.profileSetting,
            ...styles.section,
            flexDirection: "column",
          }}
          hint="Business Info"
          value={profile.businessInfo}
        />

        <ProfileSetting
          showEdit={false}
          style={{
            ...styles.profileSetting,
            ...styles.section,
            flexDirection: "column",
          }}
          hint="Certified Member Of"
          value={profile.certifiedMemberOf}
        />

        <WorkSamples
          showEdit={handleUserCanEdit}
          navigation={navigation}
          editAction={() => {}}
          layoutStyle={{
            ...styles.profileSetting,
            ...styles.section,
            flexDirection: "column",
          }}
          userID={profile?.userID}
          hint="Work Samples"
          value={profile.workSamples}
        />

        {handleUserCanEdit ? (
          <ProfileSettingMultiple
            editAction={() => {
              setComponentToRender("paymentMethod");
              setVisible(true);
            }}
            style={{
              ...styles.profileSetting,
              ...styles.section,
              flexDirection: "column",
            }}
            hint="Payment Method"
            value={profile.mobilePaymentMethod}
          />
        ) : (
          <></>
        )}

        <MemberShipBodies
          showEdit={handleUserCanEdit}
          editAction={() => {
            setComponentToRender("membership");
            setVisible(true);
          }}
          // textAlign="left"
          btnText="Add New MemberShip ID"
          style={{
            ...styles.profileSetting,
            ...styles.section,
            flexDirection: "column",
          }}
          hint="Member of a certified body"
          value={profile.memberShips}
        />

        <MemberShipBodies
          editAction={() => {
            setComponentToRender("skillCertificate");
            setVisible(true);
          }}
          btnText="Add New Certification"
          showEdit={handleUserCanEdit}
          style={{
            ...styles.profileSetting,
            ...styles.section,
            flexDirection: "column",
          }}
          hint="Skill Certificate"
          value={profile.certificates}
        />

        <ProfileSetting
          showEdit={handleUserCanEdit}
          editAction={() => {
            setComponentToRender("editSkills");
            setVisible(true);
          }}
          style={{
            ...styles.profileSetting,
            ...styles.section,
            flexDirection: "column",
          }}
          hint="My Skills"
          value={profile.allTradieSkills}
        />
      </ScrollView>
    </>
  );
};

export default TradieAccountDetails;

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: "background-basic-color-2",
    paddingHorizontal: 10,
  },
  contentContainer: {
    paddingVertical: 24,
    flexGrow: 1,
  },
  profileAvatar: {
    aspectRatio: 1.0,
    height: 124,
    alignSelf: "center",
  },
  editAvatarButton: {
    aspectRatio: 1.0,
    height: 24,
    borderRadius: 24,
    backgroundColor: Constants.manifest.extra.colors.primary.main,
  },
  profileSetting: {
    padding: 16,
  },
  section: {
    marginTop: 24,
  },
  doneButton: {
    marginHorizontal: 24,
    marginTop: 24,
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  // editAvatarButton: {
  //   borderRadius: 5,
  //   position: "absolute",
  //   bottom: 5,
  //   right: 5,
  //   padding: 3,

  // },

  icon: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
});
