import React from "react";
import { StyleSheet, View, Image, Platform, Pressable, ScrollView, ActivityIndicator } from "react-native";
import { Button, Divider, TopNavigation, TopNavigationAction, Text, Spinner, useStyleSheet, StyleService } from "@ui-kitten/components";
import { ProfileAvatar } from "../../components/profile/extra/profile-avatar.component";
import { ProfileSetting } from "../../components/profile/extra/profile-setting.component";
import { firestoreUtils, getAllStoreProducts } from "../Firebase/firebase";
import Constants from "expo-constants";

const StoreDetails = ({ navigation, route }) => {
  const styles = useStyleSheet(themedStyle);
  const { storeID } = route.params;

  const [storeDetails, setStoreDetails] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [products, setProducts] = React.useState([]);

  //   console.log({ storeDetails });

  React.useEffect(() => {
    let observer;

    const query = firestoreUtils.firestore().collection("stores").doc(storeID);
    observer = query?.onSnapshot(
      (querySnapshot) => {
        setStoreDetails(querySnapshot.data());
        setLoading(false);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer && observer();
    };
  }, []);

  React.useEffect(() => {
    let observer;

    const query = getAllStoreProducts(storeID);

    observer = query?.onSnapshot(
      (querySnapshot) => {
        // console.log(`Received query snapshot of size ${querySnapshot.size}`);
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });

        setProducts(docs);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer && observer();
    };
  }, []);

  if (loading) {
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <ActivityIndicator size="small" color={Constants.manifest.extra.colors.primary.main} />
      </View>
    );
  }

  return (
    <ScrollView style={styles.storeDetailsContainer} contentContainerStyle={styles.contentContainer}>
      <ProfileAvatar
        style={styles.profileAvatar}
        source={{ uri: storeDetails?.storeLogoURL || null }}
        // editButton={renderPhotoButton}
      />
      <ProfileSetting style={[styles.profileSetting]} hint="Store Name" value={storeDetails?.storeName} />
      <ProfileSetting style={[styles.profileSetting]} hint="Phone Number" value={storeDetails?.storePhoneNumber || "N/A"} />
      <ProfileSetting style={[styles.profileSetting]} hint="Whatsapp Number" value={storeDetails?.storeWhatsappNumber || "N/A"} />
      <ProfileSetting style={[styles.profileSetting]} hint="Location" value={storeDetails?.storeLocation || "N/A"} />

      <ProfileSetting
        style={[styles.profileSetting, styles.section]}
        hint="Products"
        value={`${products?.length || 0} product(s) listed`}
      />

      <View
        style={{
          paddingHorizontal: 10,
          paddingTop: 10,
        }}
      >
        <Button
          appearance="outline"
          // status="warning"
          style={{
            marginVertical: 5,
          }}
          onPress={() => {
            navigation?.navigate(`Edit Store`, { storeDetails: { ...storeDetails, products } });
          }}
        >
          Edit Store Details
        </Button>

        {products?.length > 0 ? (
          <Button
            style={{
              marginVertical: 5,
            }}
            onPress={() => {
              navigation?.navigate(`View Products`, { storeDetails: { ...storeDetails, products } });
            }}
          >
            View Products
          </Button>
        ) : (
          <></>
        )}

        <Button
          style={{
            marginVertical: 5,
          }}
          onPress={() => {
            navigation?.navigate(`Add New Product`, { storeDetails: { ...storeDetails, products }, title: "Add New Product" });
          }}
        >
          Add New Product
        </Button>

        <Button
          style={{
            marginVertical: 5,
          }}
          onPress={() => {
            navigation?.navigate(`Add Personnel`, { storeDetails: { ...storeDetails, products } });
          }}
        >
          Add Personnel
        </Button>
      </View>
    </ScrollView>
  );
};

export default StoreDetails;

const themedStyle = StyleService.create({
  storeDetailsContainer: {
    flex: 1,
    backgroundColor: "background-basic-color-2",
  },
  contentContainer: {
    paddingVertical: 24,
  },
  profileAvatar: {
    aspectRatio: 1.0,
    height: 150,
    alignSelf: "center",
    marginBottom: 10,
  },
  editAvatarButton: {
    aspectRatio: 1.0,
    height: 48,
    borderRadius: 24,
  },
  profileSetting: {
    padding: 16,
  },
  section: {
    marginTop: 24,
  },
  doneButton: {
    marginHorizontal: 24,
    marginTop: 24,
  },
});
