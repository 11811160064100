import React from "react";
import { StyleSheet, View, Image, Platform, Pressable, KeyboardAvoidingView, ScrollView } from "react-native";
import { Text, Spinner, useStyleSheet, Icon, Button } from "@ui-kitten/components";
import Form from "../Forms/Form";
import BaseText from "../Typography/BaseText";
import WithFormikInput from "../Inputs/WithFormikInput";
import WithFormikButton from "../Buttons/WithFormikButton";
import { addNewProductSchema } from "../../utils/schemas";
import FormErrorMessage from "../Forms/FormErrorMessage";
import BottomSheetModal from "../Misc/BottomSheetModal";
import { firestoreUtils, storageUtils } from "../Firebase/firebase";
import ImagePIckerExample from "../Misc/ImagePIckerExample";
import WithFormikCheckbox from "../Checkboxes/WithFormikCheckbox";
import { useHeaderHeight } from "@react-navigation/stack";

import { FieldArray, useFormikContext } from "formik";
import { pick } from "lodash";
import BaseInput from "../Inputs/BaseInput";

const LoadingIndicator = (props) => (
  <View style={[props.style, styles.indicator]}>
    <Spinner size="small" />
  </View>
);

const AddProductVariants = ({ name }) => {
  const { setFieldTouched, setFieldValue, values, errors, touched } = useFormikContext();

  // console.log(errors);
  return (
    <FieldArray name={name}>
      {(fieldArrayProps) => {
        // console.log(fieldArrayProps);
        const { push, remove, form } = fieldArrayProps;
        const { values } = form;
        const { variants } = values;

        return (
          <View>
            <BaseText text="Variants" category="label" />
            {variants.map((variant, index) => {
              // console.log(variant);
              return (
                <View key={index} style={{ marginVertical: 5 }}>
                  <View
                    style={{
                      marginVertical: 5,
                    }}
                  >
                    <BaseText text="Variant Name" category="label" />
                    <BaseInput
                      value={variant?.variantName}
                      setValue={(value) => setFieldValue(`variants[${index}].variantName`, value)}
                      placeholder="Enter variant name"
                      autoCorrect={false}
                    />
                    <FormErrorMessage
                      error={errors[`variants`] ? errors[`variants`][0]?.variantName : ""}
                      visible={touched[`variants`] ? touched[`variants`][0]?.variantName : ""}
                    />
                  </View>

                  <View>
                    <BaseText text="Varaint Price" category="label" />
                    <BaseInput
                      value={variant?.variantPrice}
                      setValue={(value) => setFieldValue(`variants[${index}].variantPrice`, value)}
                      placeholder="Enter variant price"
                      keyboardType="number-pad"
                      autoCorrect={false}
                    />
                    <FormErrorMessage
                      error={errors[`variants`] ? errors[`variants`][0]?.variantPrice : ""}
                      visible={touched[`variants`] ? touched[`variants`][0]?.variantPrice : ""}
                    />
                  </View>

                  <View>
                    <WithFormikCheckbox
                      name={`variants[${index}].variantOutOfStock`}
                      checked={variant?.variantOutOfStock}
                      checkBoxText="Set variant out of stock"
                    />
                  </View>
                  {index > 0 ? (
                    <Button
                      size="small"
                      status="danger"
                      style={{ paddingVertical: 0 }}
                      onPress={() => {
                        remove(index);
                      }}
                    >
                      Delete Variant
                    </Button>
                  ) : (
                    <></>
                  )}
                </View>
              );
            })}
            <Button
              size="small"
              status="info"
              style={{ marginTop: 10 }}
              onPress={() => {
                push({
                  variantName: "",
                  variantPrice: "",
                  variantOutOfStock: false,
                });
              }}
            >
              Add New Variant
            </Button>
          </View>
        );
      }}
    </FieldArray>
  );
};

const AddProduct = ({ navigation, route, userDetails }) => {
  const { storeDetails = {}, item, isEditing = false, title } = route.params;
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);
  // console.log(item);

  // ref
  const bottomSheetRef = React.useRef(null);
  const imagePickerRef = React.useRef(null);

  const handleExpandPress = () => {
    bottomSheetRef.current.present();
  };
  const handleClosePress = () => {
    bottomSheetRef.current.dismiss();
  };

  const addNewProduct = async (values, { resetForm }) => {
    try {
      if (!values?.image) return;

      // console.log(pick(values, ["name", "variants", "notes"]));
      // return;

      const ref = firestoreUtils.firestore().collection(`stores`).doc(storeDetails?.storeID).collection(`products`).doc();
      const pushDataToDB = async (downloadURL) => {
        try {
          const data = {
            productName: values?.name,
            productImage: downloadURL,
            productNotes: values?.notes,
            productAdded: firestoreUtils.firestore.Timestamp.now(),
            productAddedBy: userDetails?.userID,
            productUpdated: firestoreUtils.firestore.Timestamp.now(),
            productVariants: values?.variants.map((variant, index) => {
              return {
                variantName: variant?.variantName,
                variantPrice: variant?.variantPrice,
                variantOutOfStock: variant?.variantOutOfStock,
              };
            }),
          };

          // console.log(data);

          await ref.set({
            productID: ref.id,
            ...data,
          });
          resetForm();
        } catch (error) {
          console.log(error);
        } finally {
          setProcessing(false);
        }
      };

      setProcessing(true);

      const storageRef = storageUtils.storage().ref();
      const metadata = {
        contentType: "image/jpeg",
      };

      const response = await fetch(values?.image);
      const blob = await response.blob();

      const uploadTask = storageRef.child(`stores/products/images/${ref.id}.jpg`).put(blob, metadata);

      uploadTask.on(
        "state_changed",
        () => {},
        (err) => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            return pushDataToDB(downloadURL);
          });
        }
      );
    } catch (error) {
      console.log(error);
      setProcessing(false);
    }
  };

  const editProduct = async (values, { resetForm }) => {
    try {
      if (!values?.image) return;

      // console.log(pick(values, ["name", "variants", "notes"]));
      // return;

      const pushDataToDB = async (downloadURL) => {
        try {
          const data = {
            ...item,
            productName: values?.name,
            productImage: downloadURL,
            productNotes: values?.notes,
            productUpdated: firestoreUtils.firestore.Timestamp.now(),
            productUpdatedBy: userDetails?.userID,
            productVariants: values?.variants.map((variant, index) => {
              return {
                variantName: variant?.variantName,
                variantPrice: variant?.variantPrice,
                variantOutOfStock: variant?.variantOutOfStock,
              };
            }),
          };

          // console.log(data);
          // return setProcessing(false);

          await firestoreUtils
            .firestore()
            .collection(`stores`)
            .doc(storeDetails?.storeID)
            .collection(`products`)
            .doc(item?.productID)
            .update(data);
          navigation.goBack();
        } catch (error) {
          console.log(error);
        } finally {
          setProcessing(false);
        }
      };

      setProcessing(true);
      if (values?.image?.includes("firebasestorage.googleapis.com")) {
        return pushDataToDB(values?.image);
      }

      setProcessing(true);
      var storageRef = storageUtils.storage().ref();
      var metadata = {
        contentType: "image/jpeg",
      };

      const response = await fetch(values?.image);
      const blob = await response.blob();
      const uploadTask = storageRef.child(`stores/products/images/${item?.productID}.jpg`).put(blob, metadata);

      uploadTask.on(
        "state_changed",
        () => {},
        (err) => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await pushDataToDB(downloadURL);
          });
        }
      );
    } catch (error) {
      console.log(error);
      setProcessing(false);
    }
  };

  return (
    <KeyboardAvoidingView
      style={[styles.container, { paddingBottom: 10, paddingHorizontal: 10, paddingTop: 10 }]}
      // behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={useHeaderHeight()}
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
        <Form
          initialValues={{
            name: item?.productName || "",
            image: item?.productImage || "",
            notes: item?.productNotes || "",
            // price: item?.productPrice || "",
            // productOutOfStock: item?.productOutOfStock || false,
            variants: item?.productVariants || [
              {
                variantName: "",
                variantPrice: "",
                variantOutOfStock: false,
              },
            ],
          }}
          validationSchema={addNewProductSchema}
          onSubmit={async (values, formikBag) => {
            // console.log(values);
            if (isEditing) {
              await editProduct(values, formikBag);
            } else {
              await addNewProduct(values, formikBag);
            }
          }}
        >
          <BaseText text="Product Image" category="label" style={{ textAlign: "center", width: "100%" }} />
          <ImagePIckerExample
            name="image"
            ref={imagePickerRef}
            handleExpandPress={handleExpandPress}
            setLoading={(value) => setProcessing(value)}
          />

          <BaseText text="Name" category="label" />
          <WithFormikInput name="name" placeholder="Enter product name" autoCorrect={false} />

          {/* <BaseText text="Price" category="label" />
          <WithFormikInput name="price" placeholder="Enter product price" autoCorrect={false} keyboardType="number-pad" /> */}

          <BaseText text="Notes" category="label" />
          <WithFormikInput
            name="notes"
            placeholder="Enter some notes about product"
            autoCorrect={false}
            multiline={true}
            textStyle={{ minHeight: 100 }}
          />

          {/* <WithFormikCheckbox name="productOutOfStock" checkBoxText="Set product out of stock" /> */}

          <AddProductVariants name="variants" />

          <View style={{ marginTop: 50 }}>
            <WithFormikButton
              disabled={processing}
              text={title}
              accessoryLeft={processing ? LoadingIndicator : null}
              appearance={processing ? "outline" : "filled"}
            />

            {<FormErrorMessage error={loginError} visible={true} />}
          </View>

          <BottomSheetModal ref={bottomSheetRef} snapPoints={["20%"]}>
            <View
              style={{
                flex: 1,
                width: "100%",
                backgroundColor: "#eee",
                padding: 15,
              }}
            >
              <BaseText text="Choose an option" category="label" style={{ textAlign: "center", width: "100%" }} />
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexDirection: "row",
                }}
              >
                <Icon
                  style={styles.icon}
                  fill="green"
                  name="folder-outline"
                  onPress={() => {
                    imagePickerRef.current.pickImage(false);
                    handleClosePress();
                  }}
                />
                <Icon
                  style={styles.icon}
                  fill="orange"
                  name="camera-outline"
                  onPress={() => {
                    imagePickerRef.current.pickImage(true);
                    handleClosePress();
                  }}
                />
              </View>
            </View>
          </BottomSheetModal>
        </Form>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default AddProduct;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
  indicator: {
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 50,
    height: 50,
  },
});
