import React from "react";
import {} from "react-native";
import { Button, Card, List, Text } from "@ui-kitten/components";
import { ImageBackground, ImageBackgroundProps, StyleProp, StyleSheet, View, ViewStyle, Dimensions, Linking } from "react-native";
import { decode, encode } from "base-64";

const DEFAULT_OVERLAY_COLOR = "rgba(0, 0, 0, 0)";

const ImageOverlay = (props) => {
  const { style, children, ...imageBackgroundProps } = props;
  const { overlayColor, ...imageBackgroundStyle } = StyleSheet.flatten(style);

  return (
    <ImageBackground {...imageBackgroundProps} style={imageBackgroundStyle}>
      <View style={[StyleSheet.absoluteFill, { backgroundColor: overlayColor || DEFAULT_OVERLAY_COLOR }]} />
      {children}
    </ImageBackground>
  );
};

const renderItemHeader = (productImage) => <ImageOverlay style={styles.itemHeader} source={{ uri: productImage }}></ImageOverlay>;

const renderItemFooter = (replyTo) => (
  <View style={styles.itemFooter}>
    <View style={[styles.itemReactionsContainer]}></View>
    <Button
      style={styles.itemAddButton}
      appearance="ghost"
      onPress={() => {
        replyTo();
      }}
      // accessoryLeft={PlusIcon}
    >
      Reply Customer
    </Button>
  </View>
);

const ShowStoreVariants = ({ route }) => {
  const { params } = route;
  const { width } = Dimensions.get("window");

  //   "eyJzdG9yZU5hbWUiOiJBbm90aGVyIFN0b3JlIiwicHJvZHVjdEltYWdlIjoiaHR0cHM6Ly9maXJlYmFzZXN0b3JhZ2UuZ29vZ2xlYXBpcy5jb20vdjAvYi9oYW5keXRyYWRpZS1zYW5kYm94LmFwcHNwb3QuY29tL28vc3RvcmVzJTJGcHJvZHVjdHMlMkZpbWFnZXMlMkY4b1ZNYlBQWEdDdEZTU3pDYXZ3ZS5qcGc/YWx0PW1lZGlhJnRva2VuPTBiZjllMTUzLWY1ODUtNDBjMi1iYzMyLWVhNDIyZGM1NWQ3NiIsInByb2R1Y3ROYW1lIjoiUGlwZXMiLCJuYW1lU3RyaW5nIjoiIDQgaW5jaCAtIEdIUzEyLCAxMiBpbmNoIC0gR0hTNTAiLCJjdXN0b21lck51bWJlciI6IjIzMzU0NjI3NzQxNiJ9"
  const userRequest = params?.details ? JSON.parse(decode(params?.details)) : null;

  if (!params?.details)
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Text style={{}} category="s1">
          An error occurred
        </Text>
      </View>
    );

  return (
    <Card
      style={styles.item}
      header={() => renderItemHeader(userRequest?.productImage)}
      footer={() =>
        renderItemFooter(() => {
          let url = "whatsapp://send?text=''&phone=" + userRequest?.customerNumber;

          Linking.openURL(url)
            .then((data) => {
              console.log("WhatsApp Opened successfully " + data);
            })
            .catch((error) => {
              console.log(error);
              alert("Make sure WhatsApp is installed on your device");
            });
        })
      }
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <View
          style={{
            width: "100%",
          }}
        >
          <Text style={{ textAlign: "center" }} category="h4">
            {userRequest?.productName}
          </Text>

          <View>
            <Text style={[styles.itemDescription, { maxWidth: width - 120 }]} category="s1">
              Items Enquiry from {userRequest?.storeName}
            </Text>
            {userRequest?.nameString.split(",").map((item, index) => {
              return (
                <Text key={index} style={[styles.itemDescription, { maxWidth: width - 120 }]} category="s1">
                  {item}
                </Text>
              );
            })}
          </View>
        </View>
      </View>
    </Card>
  );
};

export default ShowStoreVariants;

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  listContent: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  item: {
    marginVertical: 8,
  },
  itemHeader: {
    minHeight: 350,
  },
  itemTitle: {
    position: "absolute",
    left: 24,
    bottom: 24,
  },
  itemDescription: {
    marginHorizontal: -8,
  },
  itemFooter: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemReactionsContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20,
  },
  itemAddButton: {
    flexDirection: "row-reverse",
    paddingHorizontal: 0,
  },
  iconButton: {
    paddingHorizontal: 0,
  },
});
