import { ImageSourcePropType } from "react-native";

export class Profile {
  constructor({ firstName, lastName, photoURL, gender, dateOfBirth, email, bio, tradieSkills, address }) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.gender = gender;
    this.photo = photoURL;
    this.dateOfBirth = dateOfBirth.toDate();
    this.bio = bio || "N/A";
    this.tradieSkills = tradieSkills || [];
    this.address = address;
    this.email = email;
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get genderName() {
    const genders = {
      1: "Male",
      2: "Female",
      3: "Unspecified",
    };

    return genders[this.gender];
  }

  get age() {
    var today = new Date();
    var birthDate = new Date(this.dateOfBirth);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  get allTradieSkills() {
    return !this.tradieSkills
      ? `No skills listed`
      : this.tradieSkills && this.tradieSkills.length > 0
      ? `${this.tradieSkills.join(" | ")}`
      : `No skills listed`;
  }
}
