import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

import Constants from "expo-constants";

import { prod, sandbox } from "../Firebase/firebaseConfig";
import { orderBy } from "lodash";

// Initialize Firebase App

if (!firebase.apps.length) {
  firebase.initializeApp(Constants.manifest.extra.firebaseconfig);
  // firebase.firestore().enablePersistence()
  firebase.functions();
}

export const auth = firebase.auth();

export const loginWithEmail = (email, password) => auth.signInWithEmailAndPassword(email, password);

export const registerWithEmail = (email, password) => auth.createUserWithEmailAndPassword(email, password);

export const logout = () => auth.signOut();

export const passwordReset = (email) => auth.sendPasswordResetEmail(email);

export const createNewInvoice = async (values, userDetails, setLoginError, goBack) => {
  let { description, amount, clientName, clientContact, downPaymentType, downPaymentValue } = values;
  try {
    const getDownPayment = () => {
      if (downPaymentType === 0) {
        return (parseFloat(amount) * parseFloat(downPaymentValue)) / 100;
      }

      if (downPaymentType === 1) {
        return parseFloat(downPaymentValue).toFixed(2);
      }
    };

    // console.log({
    //   description,
    //   amount,
    //   clientName,
    //   clientContact,
    //   downPaymentType,
    //   downPaymentValue,
    //   downPaymentValueNew: getDownPayment(),
    // });
    // return;
    const db = firebase.firestore();
    const ref = db.collection("invoices").doc(userDetails?.userID).collection(`invoices`).doc();
    await ref.set(
      {
        id: ref.id,
        description,
        amount: Number(parseFloat(amount).toFixed(2)),
        downPaymentType: downPaymentType === 0 ? ` percentage` : `fixed`,
        downPaymentAmount: Number(parseFloat(getDownPayment()).toFixed(2)),
        name: clientName,
        phoneNumber: clientContact,
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
      },
      {
        merge: true,
      }
    );
    goBack();
  } catch (error) {
    console.log(error);
    setLoginError(error);
  }
};

export const getAllInvoices = (uid) => {
  try {
    const db = firebase.firestore();
    const query = db.collection("invoices").doc(uid).collection(`invoices`).orderBy("dateCreated", "desc");
    return query;
  } catch (error) {
    console.log(error);
  }
};

export const getAllStores = (linkedStores = []) => {
  try {
    const db = firebase.firestore();
    const query = db.collection("stores").where(`storeID`, "in", linkedStores).orderBy("storeCreated", "desc");

    return query;
  } catch (error) {
    console.log(error);
  }
};

export const getAllUserStores = (linkedStores = []) => {
  try {
    const db = firebase.firestore();
    const query = db.collection("stores").where(`storeID`, "in", linkedStores).orderBy("storeCreated", "desc");
    return query;
  } catch (error) {
    console.log(error);
  }
};

export const getAllStoreProducts = (storeID) => {
  try {
    const db = firebase.firestore();
    const query = db.collection("stores").doc(storeID).collection("products").orderBy("productAdded", "desc");
    return query;
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentUser = () => {
  try {
    const user = firebase.auth().currentUser;
    return user;
  } catch (error) {
    console.log(error);
  }
};

export const getUserDetails = async (uid, storeData) => {
  try {
    const db = firebase.firestore();
    const query = db.collection("users").doc(uid);
    const subscribe = query.onSnapshot((doc) => storeData(doc.data()));
    return subscribe;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserDetails = async (uid, data) => {
  try {
    const db = firebase.firestore();
    return await db.collection("users").doc(uid).update(data);
  } catch (error) {
    console.log(error);
  }
};

export const setUserDetails = async (uid, data) => {
  try {
    const db = firebase.firestore();
    return await db.collection("users").doc(uid).set(data, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const getPaymentHistory = (uid) => {
  try {
    const db = firebase.firestore();
    const earnedQuery = db
      .collection("transactions")
      .where("tradieID", "==", uid)
      .where("type", "==", "settlement")
      .where("verified", "==", true)
      .orderBy("dateCreated", "desc")
      .limit(20);

    const outGoingQuery = db
      .collection("transactions")
      .where("userID", "==", uid)
      .where("type", "==", "settlement")
      .where("verified", "==", true)
      .orderBy("dateCreated", "desc")
      .limit(20);

    return { earnedQuery, outGoingQuery };
  } catch (error) {
    console.log(error);
  }
};

export const getTradies = () => {
  try {
    const db = firebase.firestore();
    const query = db
      .collection("users")
      .where("noOfTradieSkills", "!=", 0)
      .where("identityVerified", "==", true)
      .where("profileCompletion", "==", 100)
      .where("showInClassifieds", "==", true)

      .orderBy("noOfTradieSkills", "desc")
      .orderBy("dateCreated", "desc");

    return query;
  } catch (error) {
    console.log(error);
  }
};

export const getAllNotifications = (uid) => {
  try {
    const db = firebase.firestore();
    const query = db
      .collection("notifications")
      .doc(uid)
      .collection("userNotifications")
      .where("status", "in", ["read", "open"])
      .orderBy("timestamp", "desc")
      .limit(10);

    return query;
  } catch (error) {
    console.log(error);
  }
};

export const getAllPromos = (uid) => {
  try {
    const db = firebase.firestore();
    const query = db
      .collection("notifications")
      .doc(uid)
      .collection("userNotifications")
      .where("status", "in", ["read", "open"])
      .orderBy("timestamp", "desc")
      .limit(10);

    return query;
  } catch (error) {
    console.log(error);
  }
};

export const getAppUtils = (uid) => {
  try {
    const db = firebase.firestore();
    const query = db.collection("app_utils");

    return query;
  } catch (error) {
    console.log(error);
  }
};

export const firestoreUtils = {
  firestore: firebase.firestore,
  serverTimestamp: firebase.firestore.FieldValue.serverTimestamp,
  timestampNow: firebase.firestore.Timestamp.now,
};

export const storageUtils = {
  storage: firebase.storage,
};

export const firebaseUtils = {
  firebase,
};

export const addDocToCollection = async (coll, doc, data) => {
  try {
    const db = firebase.firestore();
    return await db.collection(coll).doc(doc).set(data, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const createNewDocInCollection = async (coll, data) => {
  try {
    const db = firebase.firestore();
    return await db.collection(coll).add(data);
  } catch (error) {
    console.log(error);
  }
};
