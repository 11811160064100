import React, { useState } from "react";
import { StyleSheet, View, Image, Text, ScrollView, KeyboardAvoidingView } from "react-native";
import * as Yup from "yup";

import Colors from "../utils/colors";
import SafeView from "../components/SafeView";
import Form from "../components/Forms/Form";
import FormField from "../components/Forms/FormField";
import FormButton from "../components/Forms/FormButton";
import IconButton from "../components/IconButton";
import FormErrorMessage from "../components/Forms/FormErrorMessage";
import { firebaseUtils, registerWithEmail } from "../components/Firebase/firebase";
import useStatusBar from "../hooks/useStatusBar";
import { StatusBar } from "expo-status-bar";

import { useFormikContext } from "formik";
import PhoneInput, { isValidNumber } from "react-native-phone-number-input";
import WithFormikButton from "../components/Buttons/WithFormikButton";
import LoadingIndicator from "../components/Misc/LoadingIndicator";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { FirebaseRecaptchaVerifierModal } from "expo-firebase-recaptcha";
import Constants from "expo-constants";
import Logo from "../components/Misc/Logo";
import { useHeaderHeight } from "@react-navigation/stack";

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    // .matches(/([+]\d{1,3}|0)\d{9}/, "Please enter a valid phone number")
    .required("Phone is required"),
  // name: Yup.string().required().label("Name"),
  // email: Yup.string().required("Please enter a valid email").email().label("Email"),
  // password: Yup.string().required().min(6, "Password must have at least 6 characters").label("Password"),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password")], "Confirm Password must match Password")
  //   .required("Confirm Password is required"),
});

const PhoneInputWithFlag = ({ name = "phoneNumber", isValid, setIsValid }) => {
  const { values, errors, setFieldValue, setFieldError, touched } = useFormikContext();
  const phoneInput = React.useRef(null);

  const [value, setValue] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("GH");

  React.useEffect(() => {
    const currentCallingCode = phoneInput.current?.getCallingCode();
    const currentCountryCode = phoneInput.current?.getCountryCode();

    setFieldValue("mobileCallingCode", currentCallingCode || values[`mobileCallingCode`]);
    setFieldValue("mobileCountryCode", currentCountryCode || values[`mobileCountryCode`]);
  }, [countryCode]);

  // console.log(values);
  // console.log(errors);

  return (
    <>
      <PhoneInput
        ref={phoneInput}
        containerStyle={[
          { borderColor: "#eee", borderWidth: 1, borderRadius: 5, height: 55, width: "100%", fontFamily: "Signika_400Regular" },
        ]}
        defaultValue={value}
        defaultCode={countryCode}
        layout="first"
        onChangeCountry={(country) => {
          setCountryCode(country);
        }}
        onChangeText={(value) => {
          setValue(value);
          const isValid = isValidNumber(value, countryCode);
          setIsValid(isValid);
          setFieldValue(name, value);
          setTimeout(() => {
            if (!isValid) setFieldError(name, "Please enter a valid phone number");
          }, 0);
        }}
        keyboardType="phone-pad"
        textContentType="telephoneNumber"
        codeTextStyle={[{ height: 20, fontFamily: "Signika_400Regular" }]}
        textInputStyle={[{ height: 20, fontFamily: "Signika_400Regular" }]}
        textContainerStyle={{
          backgroundColor: "#fff",
          borderRadius: 5,
          fontFamily: "Signika_400Regular",
        }}
      />

      {!isValid && <FormErrorMessage error={errors[name]} visible={true} />}
    </>
  );
};

export default function RegisterScreen({ navigation, setVerificationId_G }) {
  // useStatusBar("light-content");
  const recaptchaVerifier = React.useRef(null);

  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [rightIcon, setRightIcon] = useState("eye");
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState("eye");
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(true);
  const [registerError, setRegisterError] = useState("");
  const [isValid, setIsValid] = React.useState(false);

  const [accountDefaults, setAccountDefaults] = React.useState({});
  const [processing, setProcessing] = useState(false);

  function handlePasswordVisibility() {
    if (rightIcon === "eye") {
      setRightIcon("eye-off");
      setPasswordVisibility(!passwordVisibility);
    } else if (rightIcon === "eye-off") {
      setRightIcon("eye");
      setPasswordVisibility(!passwordVisibility);
    }
  }

  function handleConfirmPasswordVisibility() {
    if (confirmPasswordIcon === "eye") {
      setConfirmPasswordIcon("eye-off");
      setConfirmPasswordVisibility(!confirmPasswordVisibility);
    } else if (confirmPasswordIcon === "eye-off") {
      setConfirmPasswordIcon("eye");
      setConfirmPasswordVisibility(!confirmPasswordVisibility);
    }
  }

  async function handleOnSignUp(values, actions) {
    const { email, password } = values;
    try {
      await registerWithEmail(email, password);
    } catch (error) {
      setRegisterError(error.message);
    }
  }

  const onPressPhoneLogin = async ({ mobileCallingCode, mobileCountryCode, phoneNumber }) => {
    try {
      setProcessing(true);
      const number = `+${mobileCallingCode}${phoneNumber}`;
      // console.log({ mobileCallingCode, mobileCountryCode, phoneNumber });
      // return
      try {
        await AsyncStorage.setItem("@handytradie/phoneCreds", JSON.stringify({ mobileCallingCode, mobileCountryCode, phoneNumber }));
        const phoneProvider = new firebaseUtils.firebase.auth.PhoneAuthProvider();
        const verificationId = await phoneProvider.verifyPhoneNumber(number, recaptchaVerifier.current);

        await AsyncStorage.setItem("@handytradie/verificationId", verificationId);

        setVerificationId_G(verificationId);
        navigation.navigate("Verification", { mobileCallingCode, mobileCountryCode, phoneNumber });
        setProcessing(false);
      } catch (error) {
        console.log("error occurred verifying your account", error);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <KeyboardAvoidingView style={[styles.container]}>
      <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }} showsVerticalScrollIndicator={false}>
        <Logo />
        <Form
          initialValues={{
            phoneNumber: "",
            mobileCallingCode: "233",
            mobileCountryCode: "GH",
          }}
          validationSchema={validationSchema}
          // onSubmit={(values) => console.log(values)}
          onSubmit={(values) => onPressPhoneLogin(values)}
        >
          <View style={{ marginTop: 10 }}>
            <PhoneInputWithFlag setIsValid={setIsValid} isValid={isValid} />
          </View>

          <View style={{ marginTop: 10 }}>
            <WithFormikButton
              style={{}}
              disabled={processing || !isValid}
              text={"Register"}
              accessoryLeft={processing ? LoadingIndicator : null}
              appearance={processing ? "outline" : "filled"}
            />
          </View>

          {<FormErrorMessage error={registerError} visible={true} />}
        </Form>
        <IconButton
          style={styles.backButton}
          iconName="keyboard-backspace"
          color={Colors.black}
          size={30}
          onPress={() => navigation.goBack()}
        />

        <FirebaseRecaptchaVerifierModal
          ref={recaptchaVerifier}
          firebaseConfig={Constants.manifest.extra.firebaseconfig}
          attemptInvisibleVerification={false}
        />
        <StatusBar style="auto" />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15,
    backgroundColor: Colors.white,
  },
  backButton: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
  },
  logoContainer: {
    alignItems: "center",
  },
  logo: {
    width: 125,
    height: 125,
    borderRadius: 10,
  },

  subtitle: {
    fontSize: 24,
    fontWeight: "600",
    paddingTop: 20,
    paddingBottom: 0,
    color: `black`,
  },
});
