import * as Updates from "expo-updates";
import { Alert } from "react-native";

export const genRandomString = (length = 10) => {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charLen = parseInt(length);

  for (var i = 0; i < charLen; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text.toUpperCase();
};

export const verificationMethodPicks = [
  { label: "National ID (Ghana Card)", value: "ghana_card" },
  { label: "Ghana National Health Insurance", value: "nhis" },
  { label: "Driver's License", value: "drivers_license" },
  { label: "Voter's ID", value: "voters" },
  { label: "Passport", value: "passport" },
];

export const ALL_USER_DEFAULTS = {
  isTradie: null,
  isClient: null,
  userID: null,
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  gender: null,
  photoURL: null,
  mobileNumber: null,
};

export const CLIENT_USER_DEFAULTS = {
  dateOfBirth: null,
  address: null,
  suburb: null,
  nationality: null,
};

export const TRADIE_USER_DEFAULTS = {
  dateOfBirth: null,
  address: null,
  suburb: null,
  nationality: null,
  verificationMethod: null,
  verificationIDNumber: null,
  verificationImage: null,
};

export const getProfileCompletion = (details) => {
  try {
    if (!details) throw new Error("Error");

    let userAuthorization = {
      asBasicUser: true,
      asClient: true,
      asTradie: true,
    };

    const requiredBasic = { ...ALL_USER_DEFAULTS, ...details };
    const requiredClient = { ...CLIENT_USER_DEFAULTS, ...details };
    const requiredTradie = { ...TRADIE_USER_DEFAULTS, ...details };

    let allTotal = 0;
    let completedTotal = 0;

    let missingData = {};

    Object.keys(requiredBasic).forEach((value) => {
      allTotal += 1;
      if (value in ALL_USER_DEFAULTS && (requiredBasic[value] === null || typeof !requiredBasic[value] === undefined)) {
        missingData[value] = requiredBasic[value];
        userAuthorization.asBasicUser = false;
      } else completedTotal += 1;
    });

    Object.keys(requiredClient).forEach((value) => {
      allTotal += 1;

      if (value in CLIENT_USER_DEFAULTS && (requiredClient[value] === null || typeof !requiredClient[value] === undefined)) {
        missingData[value] = requiredClient[value];
        userAuthorization.asClient = false;
      } else completedTotal += 1;
    });

    Object.keys(requiredTradie).forEach((value) => {
      allTotal += 1;
      if (value in TRADIE_USER_DEFAULTS && (requiredTradie[value] === null || typeof !requiredTradie[value] === undefined)) {
        missingData[value] = requiredTradie[value];
        userAuthorization.asTradie = false;
      } else completedTotal += 1;
    });

    const completionRate = (completedTotal / allTotal) * 100;

    return {
      userAuthorization,
      completionRate,
    };
  } catch (error) {
    console.error(error);
    return {
      userAuthorization: {
        asBasicUser: false,
        asClient: false,
        asTradie: false,
      },
      completionRate: 0,
    };
  }
};

export const cachedNetworkCodes = [
  { label: "AIRTEL", value: "AIR" },
  { label: "MTN", value: "MTN" },
  { label: "TIGO", value: "TIG" },
  { label: "VODAFONE", value: "VOD" },
];

export const checkForUpdates = async (
  firestore,
  { showLoadingBar, closeLoadingBar, showSnack, closeSnack },
  userID = null,
  auto = false
) => {
  try {
    if (process.env.NODE_ENV !== "production") {
      showSnack(`Not available in dev`);
      if (userID) {
      }

      return false;
    }

    showLoadingBar();
    const update = await Updates.checkForUpdateAsync();
    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();
      closeLoadingBar();
      Alert.alert("New Update Available", "A new version of HandyTradie is ready to install.", [
        // {
        //   text: "Cancel",
        // style: "cancel"
        // },
        {
          text: "Install",
          onPress: async () => await Updates.reloadAsync(),
        },
      ]);
    } else {
      closeLoadingBar();
      if (auto) {
        if (userID) {
        }
        return false;
      }

      Alert.alert("No update available", "You are using the latest version of the app", [
        {
          text: "Okay",
        },
      ]);
    }
  } catch (e) {
    // handle or log error
    console.error("checkForUpdates", e);
  }
};

export const genderPicks = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
  { label: "Unspecified", value: 3 },
];
