import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { CheckBox } from "@ui-kitten/components";

const CheckBoxBase = ({ checked, setChecked, checkBoxText }) => {
  return (
    <CheckBox
      checked={checked}
      onChange={(nextChecked) => setChecked(nextChecked)}
    >
      {checkBoxText}
    </CheckBox>
  );
};

export default CheckBoxBase;

const styles = StyleSheet.create({});
