import React from "react";
import { StyleSheet, View, ScrollView, Platform, Dimensions, Pressable, Image, KeyboardAvoidingView } from "react-native";
import {
  Button,
  Spinner,
  Divider,
  TopNavigation,
  TopNavigationAction,
  Datepicker,
  Layout,
  Text,
  Card,
  Modal,
  IndexPath,
  Select,
  SelectItem,
  Menu,
  MenuGroup,
  MenuItem,
} from "@ui-kitten/components";
import { SafeAreaLayout } from "../components/safe-area-layout.component.jsx";
import { ArrowIosBackIcon } from "../components/icons";
import Form from "../components/Forms/Form";
import FormErrorMessage from "../components/Forms/FormErrorMessage";
import { editProfileSchema } from "../utils/schemas";
import WithFormikCheckbox from "../components/Checkboxes/WithFormikCheckbox";
import WithFormikInput from "../components/Inputs/WithFormikInput";
import WithFormikButton from "../components/Buttons/WithFormikButton";
import BaseText from "../components/Typography/BaseText";
import BaseRadioGroup from "../components/Radios/BaseRadioGroup";
import BaseInput from "../components/Inputs/BaseInput";
import SuburbSearch from "../components/Misc/SuburbSearch";
import { useFormikContext } from "formik";
import { firestoreUtils, getAppUtils, getUserDetails, storageUtils } from "../components/Firebase/firebase";
import Nationalities from "../components/Misc/Nationalities.js";
import { genderPicks, getProfileCompletion, verificationMethodPicks } from "../utils/index.js";
import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import PhoneInput from "react-native-phone-number-input";
import ImagePIckerExample from "../components/Misc/ImagePIckerExample.jsx";
import { useHeaderHeight } from "@react-navigation/stack";

const LoadingIndicator = (props) => (
  <View style={[props.style, styles.indicator]}>
    <Spinner size="small" />
  </View>
);

const PhoneInputWithFlag = ({ name = "mobileNumber" }) => {
  const { values, errors, setFieldValue, setFieldError, touched } = useFormikContext();
  const phoneInput = React.useRef(null);

  const [value, setValue] = React.useState(values[name] || "");
  const [callingCode] = React.useState(!values["mobileCallingCode"] || !values[name] ? "233" : values["mobileCallingCode"]);
  const [countryCode] = React.useState(!values["mobileCountryCode"] || !values[name] ? "GH" : values["mobileCountryCode"]);
  const [formattedValue, setFormattedValue] = React.useState(value);
  const [isValid, setIsValid] = React.useState(true);
  //   console.log({ isValid });

  const formattedMobileNumber = value.startsWith(callingCode) ? value.slice(callingCode.length) : value;
  // console.log(values[name]);
  // console.log(value);
  // console.log({ formattedMobileNumber });

  React.useEffect(() => {
    setFormattedValue(value);
    const checkValid = phoneInput.current?.isValidNumber(value);
    if (!checkValid) setFieldError(name, "Please enter a valid phone number");

    setIsValid(checkValid ? checkValid : false);
  }, [value]);

  React.useEffect(() => {
    const currentCallingCode = phoneInput.current?.getCallingCode();
    const currentCountryCode = phoneInput.current?.getCountryCode();
    const { formattedNumber } = phoneInput.current?.getNumberAfterPossiblyEliminatingZero();
    const getFormattedNumber = formattedNumber.startsWith("+") ? formattedNumber : formattedValue;
    setFieldValue(name, getFormattedNumber && getFormattedNumber[0] === "+" ? getFormattedNumber.slice(1) : getFormattedNumber);
    // console.log({ getFormattedNumber });

    setFieldValue("mobileCallingCode", currentCallingCode || callingCode);
    setFieldValue("mobileCountryCode", currentCountryCode || countryCode);
  }, [formattedValue]);

  // console.log(values);
  // console.log(errors);

  return (
    <>
      <PhoneInput
        ref={phoneInput}
        containerStyle={[
          { borderColor: "#eee", borderWidth: 1, borderRadius: 5, height: 45, width: "100%", fontFamily: "Signika_400Regular" },
        ]}
        defaultValue={formattedMobileNumber}
        defaultCode={countryCode}
        layout="first"
        onChangeText={(text) => {
          setValue(text);
        }}
        onChangeFormattedText={(text) => {
          setFormattedValue(text);
        }}
        keyboardType="phone-pad"
        textContentType="telephoneNumber"
        codeTextStyle={[{ height: 20, fontFamily: "Signika_400Regular" }]}
        textInputStyle={[{ height: 20, fontFamily: "Signika_400Regular" }]}
        textContainerStyle={{ backgroundColor: "#fff", fontFamily: "Signika_400Regular" }}
      />

      {!isValid && <FormErrorMessage error={errors[name]} visible={true} />}
    </>
  );
};

const VerificationMethods = ({ name = "verificationMethod" }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  //   console.log(values[name]);
  const found = verificationMethodPicks.find((verificationMethodPick) => verificationMethodPick?.value === values[name]);
  const indexOf = verificationMethodPicks.indexOf(found);
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(indexOf));

  const displayValue = verificationMethodPicks[selectedIndex.row]?.label || "Select Verification Method";

  return (
    <View style={{ borderColor: "#eee", borderWidth: 1, borderRadius: 9 }}>
      <Menu
        selectedIndex={selectedIndex}
        value={displayValue}
        onSelect={(index) => {
          setFieldValue(name, verificationMethodPicks[index?.row]?.value);
          setSelectedIndex(index);
        }}
      >
        {/* <MenuGroup title="Select Category"> */}
        <MenuGroup title={displayValue}>
          {verificationMethodPicks?.map((verificationMethodPick) => {
            return <MenuItem key={verificationMethodPick?.label} title={verificationMethodPick?.label} />;
          })}
        </MenuGroup>
      </Menu>
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
    </View>
  );
};

const Gender = ({ name = "gender" }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  //   console.log(values[name]);
  const found = genderPicks.find((genderPick) => genderPick?.value === values[name]);
  const indexOf = genderPicks.indexOf(found);
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(indexOf));

  const displayValue = genderPicks[selectedIndex.row]?.label || "Select Gender";

  return (
    <View style={{ borderColor: "#eee", borderWidth: 1, borderRadius: 9 }}>
      <Menu
        selectedIndex={selectedIndex}
        value={displayValue}
        onSelect={(index) => {
          setFieldValue(name, genderPicks[index?.row]?.value);
          setSelectedIndex(index);
        }}
      >
        <MenuGroup title={displayValue}>
          {genderPicks?.map((genderPick) => {
            return <MenuItem key={genderPick?.label} title={genderPick?.label} />;
          })}
        </MenuGroup>
      </Menu>
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
    </View>
  );
};

const LocationComponent = ({ name = "suburb" }) => {
  const { width, height } = Dimensions.get("window");
  const { values, setFieldValue, touched, errors } = useFormikContext();
  //   console.log(values[name]);
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <Button onPress={() => setVisible(true)}>
        <Text
          appearance="hint"
          style={{
            fontSize: 1,
          }}
        >
          {values[name]}
        </Text>
      </Button>
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
      <Modal visible={visible} backdropStyle={styles.backdrop} onBackdropPress={() => setVisible(false)}>
        <View
          disabled={true}
          style={{
            flex: 1,
            minHeight: height - 400,
            maxWidth: width - 20,
            width: width,
            backgroundColor: "#eee",
            overflow: "hidden",
            padding: 15,
          }}
        >
          <SuburbSearch name={name} setFieldValue={setFieldValue} setVisible={setVisible} />
        </View>
      </Modal>
    </>
  );
};

const NationalityComponent = ({ name = "nationality", nationalities }) => {
  const { width, height } = Dimensions.get("window");
  const { values, setFieldValue, touched, errors } = useFormikContext();
  //   console.log(values[name]);
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <Button onPress={() => setVisible(true)}>
        <Text
          appearance="hint"
          style={{
            fontSize: 1,
          }}
        >
          {values[name]}
        </Text>
      </Button>
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
      <Modal visible={visible} backdropStyle={styles.backdrop} onBackdropPress={() => setVisible(false)}>
        <View
          showsVerticalScrollIndicator={false}
          disabled={true}
          style={{
            flex: 1,
            maxHeight: height - 400,
            maxWidth: width - 20,
            height: height,
            width: width,
            backgroundColor: "#eee",
            overflow: "hidden",
            padding: 15,
            marginVertical: 100,
            // borderColor: "red",
            // borderWidth: 1,
          }}
        >
          <Nationalities name={name} data={nationalities} setFieldValue={setFieldValue} setVisible={setVisible} />
        </View>
      </Modal>
    </>
  );
};

const DateOfBirth = ({ name = "dateOfBirth" }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext();

  const [date, setDate] = React.useState(values[name] ? new Date(values[name]?.toDate()) : "");
  const [dateToShow, setDateToShow] = React.useState(values[name] ? new Date(values[name]?.toDate()) : "");

  // console.log(values[name]);

  React.useEffect(() => {
    date && setFieldValue(`dateOfBirth`, firestoreUtils.firestore.Timestamp.fromDate(date));
    setDateToShow(date);
  }, [date]);

  return (
    <View>
      <Datepicker size="large" date={dateToShow} min={new Date(50, 1)} max={new Date()} onSelect={(nextDate) => setDate(nextDate)} />
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
    </View>
  );
};

const EditProfileScreen = ({ userDetails, navigation }) => {
  //   console.log(userDetails);
  const renderBackAction = () => <TopNavigationAction icon={ArrowIosBackIcon} onPress={navigation.goBack} />;

  const [accountDefaults, setAccountDefaults] = React.useState({});
  const [nationalities, setNationalities] = React.useState([]);
  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);

  React.useEffect(() => {
    let observer1;

    const query = getAppUtils();
    const account_defaults = query.doc(`account_defaults`);

    (async () => {
      const countriesDoc = await query.doc(`countries`).get();
      const countriesData = countriesDoc.data();
      const nationalities = countriesData.countries;
      setNationalities(nationalities);
      //   setNationalities(nationalities.slice(0, 50));
    })();

    observer1 = account_defaults.onSnapshot(
      (doc) => {
        setAccountDefaults(doc.data());
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer1 && observer1();
    };
  }, []);

  const formatImage = async (image, userID, verification, setProcessing) => {
    try {
      if (image.includes("firebasestorage.googleapis.com" || !image)) {
        return;
      }
      setProcessing(true);
      var storageRef = storageUtils.storage().ref();
      var metadata = {
        contentType: "image/jpeg",
      };

      const response = await fetch(image);
      const blob = await response.blob();

      const dbPath = verification === true ? `verificationImage` : `photoURL`;
      const storagePath = verification === true ? `users/images/verification` : `users/images`;
      var uploadTask = storageRef.child(`${storagePath}/${userID}.jpg`).put(blob, metadata);

      uploadTask.on(
        "state_changed",
        () => {},
        (err) => {
          setProcessing(false);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            setProcessing(true);
            await firestoreUtils
              .firestore()
              .collection(`users`)
              .doc(userID)
              .update({
                [dbPath]: downloadURL,
              });
            setProcessing(false);
          });
        }
      );

      return await uploadTask.snapshot.ref.getDownloadURL();
    } catch (error) {
      console.log(error);
    }
  };

  const editProfile = async (values, { resetForm }) => {
    try {
      // console.log({ values });
      setProcessing(true);
      values.firstName = values.firstName.trim();
      values.lastName = values.lastName.trim();

      const {
        userAuthorization: { asClient, asTradie },
      } = getProfileCompletion(values);

      await formatImage(values?.photoURL, values?.userID, false, setProcessing);
      await formatImage(values?.verificationImage, values?.userID, true, setProcessing);

      // const doc = await firestoreUtils.firestore().collection("users").doc(values?.userID).get();
      // const user = doc.data();

      let newProfile = { ...userDetails, ...values, isClient: asClient, isTradie: asTradie };
      const { completionRate } = getProfileCompletion(newProfile);

      newProfile = { ...accountDefaults, ...newProfile };

      delete newProfile.photoURL;
      delete newProfile.verificationImage;

      await firestoreUtils
        .firestore()
        .collection(`users`)
        .doc(newProfile?.userID)
        .update({
          ...newProfile,
          profileCompletion: completionRate,
        });

      setProcessing(false);
      navigation.goBack();
    } catch (error) {
      console.log(error);
      setProcessing(false);
    }
  };

  // console.log({ accountDefaults, userDetails });

  return (
    <KeyboardAvoidingView style={[styles.container]}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false} keyboardShouldPersistTaps="always">
        <Form
          initialValues={{
            ...accountDefaults,
            ...userDetails,
          }}
          validationSchema={editProfileSchema}
          onSubmit={async (values, formikBag) => {
            setProcessing(true);
            const newValues = { ...values };
            delete newValues?.promos;
            // console.log(newValues);
            await editProfile(newValues, formikBag);
            setProcessing(false);
          }}
        >
          <BaseText text="Profile Image" category="label" style={{ textAlign: "center", width: "100%" }} />
          <ImagePIckerExample name="photoURL" />

          <BaseText text="First Name" category="label" />
          <WithFormikInput name="firstName" placeholder="Enter your first name" autoCapitalize="none" autoCorrect={false} />

          <BaseText text="Last Name" category="label" />
          <WithFormikInput name="lastName" placeholder="Enter your last name" autoCapitalize="none" autoCorrect={false} />

          <BaseText text="Email" category="label" />
          <WithFormikInput name="email" placeholder="Enter your email" autoCapitalize="none" autoCorrect={false} />

          <BaseText text="Mobile Number" category="label" />
          <PhoneInputWithFlag name="mobileNumber" />

          <BaseText text="Gender" category="label" />
          <Gender />

          <BaseText text="Bio" category="label" />
          <WithFormikInput
            name="bio"
            placeholder="Enter some details about yourself"
            autoCapitalize="none"
            multiline={true}
            textStyle={{ minHeight: 100 }}
          />

          <BaseText text="Date of Birth" category="label" />
          <DateOfBirth />

          <BaseText text="Address" category="label" />
          <WithFormikInput name="address" placeholder="Enter your address" autoCapitalize="none" autoCorrect={false} />

          <BaseText text="Suburb" category="label" />
          <LocationComponent />

          <BaseText text="Nationality" category="label" />
          <NationalityComponent nationalities={nationalities} />

          <BaseText text="Verification ID Number" category="label" />
          <WithFormikInput name="verificationIDNumber" placeholder="Enter your ID number" autoCapitalize="none" autoCorrect={false} />

          <BaseText text="Verification Method" category="label" />
          <VerificationMethods />

          <BaseText text="Verification Image" category="label" style={{ textAlign: "center", width: "100%" }} />
          <ImagePIckerExample name="verificationImage" />

          <View style={{ marginTop: 10 }}>
            <WithFormikButton
              disabled={processing}
              text={"Save"}
              accessoryLeft={processing ? LoadingIndicator : null}
              appearance={processing ? "outline" : "filled"}
            />
          </View>
          {<FormErrorMessage error={loginError} visible={true} />}
        </Form>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default EditProfileScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
  indicator: {
    justifyContent: "center",
    alignItems: "center",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  verificationMethodContainer: {
    minHeight: 128,
  },

  genderContainer: {
    minHeight: 100,
  },
});
