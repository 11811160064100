import React from "react";
import { StyleSheet, View, FlatList, ScrollView } from "react-native";
import BaseButton from "../components/Buttons/BaseButton";
import { getAllInvoices, getAllStoreProducts, getAllStores, getAllUserStores } from "../components/Firebase/firebase";
import { Button, Card, Layout, Text, Avatar } from "@ui-kitten/components";
import { filter, orderBy } from "lodash";

const RenderItem = ({ item, navigation }) => {
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    let observer;

    const query = getAllStoreProducts(item?.storeID);

    observer = query?.onSnapshot(
      (querySnapshot) => {
        // console.log(`Received query snapshot of size ${querySnapshot.size}`);
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });

        setProducts(docs);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return () => {
      observer && observer();
    };
  }, []);

  return (
    <Card
      style={{ ...styles.card }}
      onPress={() => {
        navigation.navigate(`ExtraNav`, { screen: `Store Details`, params: { title: `${item?.storeName}`, storeID: item?.storeID } });
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <View style={{ marginRight: 10 }}>
          <Avatar source={{ uri: item?.storeLogoURL }} size="giant" />
        </View>
        <View style={{ flex: 1 }}>
          <Text category="h6">{item?.storeName}</Text>
          <View style={{ marginBottom: 10 }}>
            <Text category="label" style={{}}>
              {item?.storeDetails}
            </Text>
          </View>
          <Text category="label">Contact: {item?.storePhoneNumber || "N/A"}</Text>
          <Text category="label">Whatsapp: {item?.storeWhatsappNumber || "N/A"}</Text>
          <Text category="label">Location: {item?.storeLocation || "N/A"}</Text>
          <View style={{ marginTop: 10, flexDirection: "row", justifyContent: "flex-end" }}>
            <Text category="label" style={{ fontWeight: "bold", fontFamily: "Signika_700Bold" }}>
              {`${products?.length || 0} product(s) listed`}
            </Text>
          </View>
        </View>
      </View>
    </Card>
  );
};

const ShowStores = ({ userDetails, navigation, route }) => {
  const [docs, setDocs] = React.useState([]);

  React.useEffect(() => {
    let observer;

    if (userDetails?.linkedStores && userDetails?.linkedStores?.length > 0) {
      const query = getAllUserStores(userDetails?.linkedStores);

      observer = query?.onSnapshot(
        (querySnapshot) => {
          // console.log(`Received query snapshot of size ${querySnapshot.size}`);
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push(doc.data());
          });

          const ordered = orderBy(docs, ["productAdded"], ["desc"]);
          // console.log(ordered);
          setDocs(ordered);
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    }

    return () => {
      observer && observer();
    };
  }, [userDetails?.linkedStores]);

  // console.log({ docs });

  return (
    <View
      style={{
        paddingHorizontal: 10,
        paddingTop: 10,
        height: "100%",
      }}
    >
      <BaseButton
        text="Create New Store"
        onPress={() => {
          navigation.navigate(`Create Store`);
        }}
      />

      <FlatList
        style={styles.container}
        ListEmptyComponent={
          <View style={{ flex: 1, justifyContent: "center", alignItems: "center", height: "100%" }}>
            <Text category="h6" style={{ textAlign: "center" }}>
              No Stores Listed
            </Text>
          </View>
        }
        style={{
          height: "100%",
        }}
        showsVerticalScrollIndicator={false}
        data={docs}
        renderItem={({ item }) => <RenderItem item={item} navigation={navigation} />}
        keyExtractor={(item, index) => item.storeID + index}
      />
    </View>
  );
};

export default ShowStores;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 10,
  },
  card: {
    flex: 1,
    marginVertical: 3,
  },
  footerControl: {
    marginHorizontal: 2,
  },
});
